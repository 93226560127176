import React, { forwardRef, useEffect, useRef, useState } from 'react';
import Tribute from 'tributejs';
import 'tributejs/dist/tribute.css';

const DELAY = 300;

const TaskTitleInput = forwardRef((props, ref) => {
	const {
		item,
		prevItemId,
		prevItemParentId,
		title,
		handleValueChange,
		handleInputFocus,
		checkAddKey,
		selectedItems,
		getAllLeafTasksEstimates,
		searchInput,
		usersWithAccessToWorksheet,
		fetchingUsers,
		setAssginee,
		isTaskNotAssigned
	} = props;

	const lastClickTime = useRef(0);
	const inputElement = document.getElementById(`task-title-input-${item._id}`);

	const [isInputExpanded, setIsInputExpanded] = useState(false);

	const handleAssignTask = event => {
		let _assignee = event.detail?.item?.original;
		const newItem = { ...item, title: event.target.value };
		setAssginee(newItem, _assignee);
	};

	useEffect(() => {
		let tributeInstance = null;

		if (inputElement && !fetchingUsers) {
			tributeInstance = new Tribute({
				trigger: '@',
				lookup: 'name',
				values: usersWithAccessToWorksheet,
				selectTemplate: function (value) {
					const _assignee = value?.original;
					return `@${_assignee?.name || ''}`;
				}
			});

			tributeInstance.attach(inputElement);

			inputElement.addEventListener('tribute-replaced', handleAssignTask);
		}

		return () => {
			if (inputElement) {
				inputElement?.removeEventListener('tribute-replaced', handleAssignTask);
				tributeInstance?.detach(inputElement);
			}
		};
	}, [fetchingUsers, usersWithAccessToWorksheet.length, inputElement]);

	const handleToggleInput = e => {
		e.preventDefault();
		setIsInputExpanded(prev => !prev);
	};

	const handleToggleInputInMobile = e => {
		e.preventDefault();
		const currentTime = new Date().getTime();
		const timeSinceLastClick = currentTime - lastClickTime.current;

		if (timeSinceLastClick < DELAY) {
			setIsInputExpanded(prev => !prev);
			e.preventDefault();
		}

		lastClickTime.current = currentTime;
	};

	return (
		<div>
			<InputBox
				ref={ref}
				id={`task-title-input-${item._id}`}
				isExpanded={isInputExpanded}
				placeholder={item.seq === 1 ? 'Your first goal, objective, or task' : ''}
				type='text'
				value={title}
				// inputMode='email'
				tabIndex={0}
				onChange={handleValueChange}
				className='unselectable-text'
				onFocus={e => {
					e.target.setAttribute('autocomplete', 'off');
					handleInputFocus(e);
				}}
				onKeyDown={e => {
					const start = performance.now();
					let temp = { ...item };
					temp.title = title;
					checkAddKey(e, temp, prevItemId, prevItemParentId);
					const end = performance.now();
					console.log(`onKeyDown took ${end - start} milliseconds`);
				}}
				// onDoubleClick={handleToggleInput} // Disabled
				onTouchStart={handleToggleInputInMobile}
				htmlFor={item._id}
				style={{
					background: selectedItems.includes(item._id) ? '#0562D2' : 'transparent',
					color: isTaskNotAssigned ? 'red' : '#fff',
					border: 0,
					fontFamily: 'Poppins',
					fontSize: '13px',
					width: '100%',
					fontWeight: '500',
					fontStyle: 'normal',
					opacity:
						item.estimate === 0 &&
						(getAllLeafTasksEstimates().reduce((a, b) => {
							return a + b;
						}, 0) === 0
							? '60%'
							: '100%')
				}}
				disabled={searchInput !== ''}
			/>
		</div>
	);
});

const InputBox = forwardRef((props, ref) => {
	const { isExpanded, style, ...rest } = props;
	return (
		<>
			{isExpanded ? (
				<textarea ref={ref} rows={4} col={10} {...rest} style={{ ...style, resize: 'none' }} />
			) : (
				<input ref={ref} {...rest} style={style} />
			)}
		</>
	);
});

export default TaskTitleInput;

