import { Divider, makeStyles, SwipeableDrawer, Switch } from '@material-ui/core';
import ChatBubbleIcon from '@mui/icons-material/ChatBubble';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import DragHandleIcon from '@mui/icons-material/DragHandle';
import { Collapse } from '@mui/material';
import * as axiosBase from 'axios';
import { useEffect, useState } from 'react';
import { NavLink, useNavigate, useSearchParams } from 'react-router-dom';
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';
import toastr from 'toastr';
import 'toastr/build/toastr.min.css';
import { ReactComponent as PlusIcon } from '../../assets/icons/plus-icon.svg';
import styles from './../../screens/ChoppingInterface/ChoppingInterface.module.css';
import AccordionComponent from './components/AccordionComponent';

const accessToken = localStorage.getItem('access_token');
const user = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : {};
const axios = axiosBase.create({
	headers: {
		Accept: 'application/json',
		Authorization: `Bearer ${accessToken}`,
		'Access-Control-Allow-Origin': '*'
	}
});

toastr.options = {
	positionClass: 'toast-top-center',
	zIndex: 66666,
	hideDuration: 300,
	timeOut: 5000
};

const useStyles = makeStyles(theme => ({
	root: {
		'& .MuiSwitch-track': {
			background: '#D9D9D9'
		}
	}
}));
const WorksheetSortableList = SortableContainer(
	({
		worksheetList,
		getGroupNotificationCount,
		getWorksheetWithoutNotificationCount,
		onlyShowNewActivity,
		expandedGroupIndex,
		activeWorksheet,
		tasks,
		handleAccordionClick,
		getAllWorksheetsTasks,
		metadataByWorksheetId,
		WorksheetSortableItem
	}) => {
		const sortedList = worksheetList
			?.filter(entry => {
				const groupNotificationCount = getGroupNotificationCount(entry[1]);
				return (groupNotificationCount > 0 && onlyShowNewActivity) || !onlyShowNewActivity;
			})
			.sort((a, b) => {
				if (a[0] === 'others') {
					return +1;
				} else if (b[0] === 'others') {
					return -100;
				} else {
					return a[0] - b[0];
				}
			});
		return (
			<div
				className={`${styles.worksheetListContainer}`}
				style={{
					height: 'calc(100vh - 80px)'
				}}
			>
				<div>
					{sortedList.map((entry, index) => {
						const groupNotificationCount = getGroupNotificationCount(entry[1]);
						const hasWorksheetWithoutNotification = getWorksheetWithoutNotificationCount(entry[1]);
						const shouldShowAccordion =
							(groupNotificationCount > 0 && onlyShowNewActivity) || !onlyShowNewActivity;
						let activeGroupIndex = sortedList?.findIndex(entry => {
							const hasActiveWorkSheet = !!entry[1]?.find(
								worksheet => worksheet?._id === activeWorksheet
							);
							if (hasActiveWorkSheet) {
								return true;
							}
							return false;
						});
						if (expandedGroupIndex !== -1) {
							activeGroupIndex = expandedGroupIndex;
						}
						const isExpanded = activeGroupIndex === index;
						return (
							<div
								style={
									{
										// backgroundColor: onlyShowNewActivity ? '#222222' : '#333333',
										// marginTop: onlyShowNewActivity ? '-100px' : '0px',
										// transition: '0.3s all ease-in-out'
									}
								}
							>
								<AccordionComponent
									isExpanded={isExpanded}
									key={`${entry[0]}-group-${index + 1}`}
									Collapse={Collapse}
									index={index}
									expandedGroupIndex={expandedGroupIndex}
									groupNotificationCount={groupNotificationCount}
									entry={entry}
									activeWorksheet={activeWorksheet}
									handleAccordionClick={handleAccordionClick}
									onlyShowNewActivity={onlyShowNewActivity}
									tasks={tasks}
									getAllWorksheetsTasks={getAllWorksheetsTasks}
									metadataByWorksheetId={metadataByWorksheetId}
									WorksheetSortableItem={WorksheetSortableItem}
								/>
							</div>
						);
					})}
				</div>
			</div>
		);
	}
);

const WorksheetSidebar = ({
	activeWorksheet,
	notificationCount,
	sidebarActiveStatus,
	setSidebarActive,
	createNewWorksheet,
	handleWorksheetChange,
	worksheetListTemp,
	updateSearchedTasks = () => {},
	setSearchedTasksLoading,
	setShouldScrollToNotification,
	scrollToFirstNotification,
	metadataByWorksheetId = {},
	changeTabs = () => {}
}) => {
	const [worksheetList, setWorksheetList] = useState([]);
	const [escalationTaskCount, setEscalationTaskCount] = useState(0);
	const [assignedTaskCount, setAssignedTaskCount] = useState(0);
	const [commentCounts, setCommentCounts] = useState({});
	const [tasks, setTasks] = useState([]);
	const [searchQuery, setSearchQuery] = useState('');
	const [hasSearched, setHasSearched] = useState(false);
	const [worksheetListByClient, setWorksheetListByClient] = useState([]);
	const [expandedGroupIndex, setExpandedGroupIndex] = useState(-1);
	const [onlyShowNewActivity, setOnlyShowNewActivity] = useState(false);
	const [params, setParams] = useSearchParams();
	const navigate = useNavigate();

	console.log('activeWorksheet', activeWorksheet, worksheetList);

	const group = params.get('group');
	const showNewActivitiesOnly = params.get('new_activity');

	const groupBy = (xs, key) => {
		return xs.reduce(function (rv, x) {
			if (!x[key]) (rv['others'] = rv['others'] || []).push(x);
			else (rv[x[key]] = rv[x[key]] || []).push(x);
			return rv;
		}, {});
	};

	useEffect(() => {
		if (showNewActivitiesOnly) {
			setOnlyShowNewActivity(true);
		} else {
			setOnlyShowNewActivity(false);
		}
	}, [showNewActivitiesOnly]);

	useEffect(() => {
		let groupedWorksheets = groupBy(worksheetList, 'client_name');
		const worksheetsEntries = Object.entries(groupedWorksheets);
		setWorksheetListByClient(worksheetsEntries);
	}, [worksheetList]);

	useEffect(() => {
		if (group) {
			worksheetListByClient?.forEach((entry, index) => {
				if (entry[0] === group) setExpandedGroupIndex(index);
			});
		}
	}, [worksheetListByClient, group]);

	useEffect(() => {
		getWorksheetList();
	}, []);

	const getWorksheetList = () => {
		const url = `${process.env.REACT_APP_LARAVEL_API_URL}/worksheet`;
		axios
			.get(url)
			.then(data => {
				setEscalationTaskCount(data.data.escalated_tasks);
				setAssignedTaskCount(data.data.assigned_tasks);
				setWorksheetList(data.data.worksheets);
			})
			.catch(e => console.log(e));
	};

	const handleAccordionClick = index => {
		setExpandedGroupIndex(prevIndex => (prevIndex === index ? -1 : index));
	};

	const handleWorksheetClick = (e, worksheet) => {
		changeTabs('mainTasks');
		if (e.ctrlKey) {
			window.open(`/chopping-interface/${worksheet?._id}`, '_blank');
			return;
		}
		e.preventDefault();
		handleWorksheetChange(e, worksheet._id);
	};

	const getGroupNotificationCount = worksheets => {
		let count = 0;
		worksheets?.forEach(worksheet => {
			count += metadataByWorksheetId[worksheet._id]?.tasks_with_new_comments;
		});
		return count;
	};

	const getWorksheetWithoutNotificationCount = worksheets => {
		let count = 0;
		worksheets?.forEach(worksheet => {
			if (metadataByWorksheetId[worksheet._id]?.tasks_with_new_comments === 0) count++;
		});
		return count;
	};

	// useEffect(() => {
	// 	if (!hasSearched && worksheetList && searchQuery) {
	// 		getAllWorksheetsTasks();
	// 		setHasSearched(true);
	// 	}
	// }, [searchQuery, worksheetList, hasSearched]);

	useEffect(() => {
		if (!hasSearched && worksheetList.length && searchQuery) {
			getAllWorksheetsTasks();
			setHasSearched(true);
		}
	}, [searchQuery, worksheetList.length, hasSearched]);

	const getWorksheetsTasksList = async worksheets => {
		try {
			const taskData = await Promise.all(
				worksheets.map(async worksheet => {
					const url = `${process.env.REACT_APP_LARAVEL_API_URL}/task?worksheet_id=` + worksheet._id;

					const { data } = await axios.get(url);
					return { worksheetTitle: worksheet.title, tasks: data.tasks };
				})
			);
			setTasks(taskData);
			return taskData;
		} catch (error) {
			console.error('Failed to get tasks:', error);
		}
	};

	const getAllWorksheetsTasks = async () => {
		try {
			setSearchedTasksLoading(true);
			const taskData = await Promise.all(
				worksheetList.map(async worksheet => {
					const url = `${process.env.REACT_APP_LARAVEL_API_URL}/task?worksheet_id=` + worksheet._id;

					const { data } = await axios.get(url);
					return { worksheetId: worksheet._id, tasks: data.tasks };
				})
			);
			setSearchedTasksLoading(false);
			setTasks(taskData);
			return taskData;
		} catch (error) {
			console.error('Failed to get tasks:', error);
		}
	};

	const handleToggleNewActivity = () => {
		const url = new URL(window.location);

		if (onlyShowNewActivity) {
			setOnlyShowNewActivity(false);
			url.searchParams.delete('new_activity');
		} else {
			setOnlyShowNewActivity(true);
			url.searchParams.set('new_activity', true);
		}

		window.history.pushState({}, '', url);
	};

	const searchTasks = () => {
		const matchingTaskIdsAndTheirParentIds = new Set();
		tasks.forEach(worksheet => {
			worksheet.tasks.forEach(task => {
				if (
					(task.title && task.title.toLowerCase().includes(searchQuery.toLowerCase())) ||
					(task.description && task.description.toLowerCase().includes(searchQuery.toLowerCase()))
				) {
					matchingTaskIdsAndTheirParentIds.add(task._id);
					matchingTaskIdsAndTheirParentIds.add(task.parent_id);
				}
			});
		});
		return tasks
			.map(worksheet => ({
				...worksheet,
				tasks: worksheet.tasks.filter(task => matchingTaskIdsAndTheirParentIds.has(task._id))
			}))
			.filter(worksheet => worksheet.tasks.length > 0);
	};

	useEffect(() => {
		(async () => {
			const searchResults = searchTasks(searchQuery);
			updateSearchedTasks(searchResults, searchQuery.length);
		})();
	}, [searchQuery, tasks]);

	const getNotificationCount = () => {
		const url = `${process.env.REACT_APP_LARAVEL_API_URL}/notification/count-comments/unread`;
		axios
			.get(url)
			.then(data => {
				setCommentCounts(data.data.data);
			})
			.catch(e => console.log(e));
	};
	useEffect(() => {
		if (worksheetListTemp) {
			setWorksheetList(worksheetListTemp);
		}
	}, [worksheetListTemp]);

	// deprecated on 9th nov 2023
	// const handleKnowledgebaseRouting = (e, entry) => {
	// 	console.log(entry);
	// 	let find = entry[1]?.find((item) => item?.type === 'knowledgebase')
	// 	if (find) {
	// 		handleWorksheetChange(e, find?._id)
	// 	} else {
	// 		return '/chopping-interface'
	// 	}

	// }

	useEffect(() => {
		getNotificationCount();
	}, []);
	const DragHandle = SortableHandle(() => <DragHandleIcon />);

	const WorksheetSortableItem = SortableElement(({ worksheet, sheetTasks }) => {
		return (
			<div tabIndex={0} style={{ width: '100%' }}>
				<div
					className='box'
					onClick={e => handleWorksheetClick(e, worksheet)}
					style={{ textDecoration: 'none', cursor: 'pointer' }}
				>
					<div
						className={`${styles.worksheetCard} ${
							worksheet._id === activeWorksheet ? styles.worksheetCardActive : ''
						} d-flex`}
					>
						<div
							className={`${styles.sheetName} ${
								worksheet._id === activeWorksheet ? styles.sheetActive : styles.notActive
							}`}
							style={{ width: '100%' }}
						>
							{worksheet.title ? worksheet.title : worksheet.title_first}
						</div>
						{/* //Show ChatBubble icon when there are some new comments,
						If there are no new comments and all task done show green check */}
						{metadataByWorksheetId[worksheet._id]?.tasks_with_new_comments > 0 ? (
							<div
								className='d-flex align-items-center ml-3'
								onClick={e => {
									scrollToFirstNotification();
									setShouldScrollToNotification(true);
									handleWorksheetClick(e, worksheet);
								}}
							>
								<ChatBubbleIcon style={{ color: '#5FB924', cursor: 'pointer' }} />
								<p className='text-small m-0 ml-1' style={{ color: '#5FB924' }}>
									{metadataByWorksheetId[worksheet._id]?.tasks_with_new_comments}
								</p>
							</div>
						) : metadataByWorksheetId[worksheet._id]?.tasks_with_new_comments === 0 &&
						  metadataByWorksheetId[worksheet._id]?.completed_percentage === 100 ? (
							<div
								className='d-flex align-items-center ml-3'
								onClick={e => {
									handleWorksheetClick(e, worksheet);
								}}
							>
								<CheckCircleIcon style={{ color: '#5FB924', cursor: 'pointer' }} />
							</div>
						) : null}
						{/*		<LinearProgress
						variant='determinate'
						value={
							// worksheet._id === activeWorksheet
							// 	? (100 * completedHours) / (totalHours === 0 ? 1 : totalHours)
							// 	: parseFloat(worksheet.completed_percentage)
							worksheet.completed_percentage
						}
						sx={{
							color: 'secondary'
						}}
					/>
					*/}
						{/* <div className='row mt-3 mb-0 mx-0'>
						<div className='col-6 d-flex align-items-center p-0'>
							<p className={`${styles.sheetCompletionPercentage} `}>
								Pending: {worksheet.pending_hours}
							</p>
						</div>
						<div className='col-6 d-flex align-items-center p-0'>
							<p className={`${styles.sheetCompletionPercentage} `}>
								Total: {worksheet.total_hours}
							</p>
						</div>
					</div> */}
						{/* {user && user._id === worksheet.user_id && (
						<div className='row mt-3 mb-0 mx-0'>
							<div className='col-2 p-0'>
								<div className='d-flex align-items-end'>
									<button
										type='button'
										style={{ background: 'transparent', outline: 'none', border: 'none' }}
										href=''
										onClick={e => {
											e.stopPropagation();
											e.preventDefault();
											deleteWorksheet(worksheet._id);
										}}
									>
										<MdDelete style={{ fontSize: '15px', color: 'white' }} />
									</button>
								</div>
							</div>
						</div>
					)} */}
						{/* <div className='row ' style={{ margin: '10px 0 5px 0' }}>
						{worksheet.tasks_without_estimates !== 0 && (
							<div className={`col-2 p-0 d-flex justify-content-center`}>
								<div className={`${styles.taskCount} ${styles.taskCountWithoutEstimate}`}>
									{worksheet.tasks_without_estimates}
								</div>
							</div>
						)}

						{worksheet.hard_tasks !== 0 && (
							<div className={`col-2 p-0 d-flex justify-content-center`}>
								<div className={`${styles.taskCount} ${styles.taskCountHard}`}>
									{worksheet.hard_tasks}
								</div>
							</div>
						)}

						{worksheet.medium_tasks !== 0 && (
							<div className={`col-2 p-0 d-flex justify-content-center`}>
								<div className={`${styles.taskCount} ${styles.taskCountMedium}`}>
									{worksheet.medium_tasks}
								</div>
							</div>
						)}

						{worksheet.easy_tasks !== 0 && (
							<div className={`col-2 p-0 d-flex justify-content-center`}>
								<div className={`${styles.taskCount} ${styles.taskCountEasy}`}>
									{worksheet.easy_tasks}
								</div>
							</div>
						)}
					</div> */}
					</div>
				</div>
			</div>
		);
	});

	const handleWorksheetSorting = ({ oldIndex, newIndex }) => {
		var payload = {};
		var old_worksheet_list = [...worksheetList];

		const worksheet = [...worksheetList].find(worksheet => worksheet.seq === oldIndex);
		worksheet.seq = newIndex;
		const taskIndex = [...worksheetList].indexOf(worksheet);
		let newWorksheetList = [...worksheetList];
		newWorksheetList.splice(taskIndex, 1);
		if (newIndex < oldIndex) {
			newWorksheetList.splice(newIndex, 0, worksheet);
		} else {
			newWorksheetList.splice(newIndex, 0, worksheet);
		}
		newWorksheetList = newWorksheetList.map((item, index) => {
			payload[item._id] = index;
			item.seq = index;
			return item;
		});
		setWorksheetList(newWorksheetList);
		updateWorksheetSequence(payload, old_worksheet_list);
	};

	const updateWorksheetSequence = (seq_payload, old_worksheet_list) => {
		if (Object.keys(seq_payload).length !== 0) {
			axios
				.post(`${process.env.REACT_APP_LARAVEL_API_URL}/worksheet/update-seq`, {
					data: seq_payload
				})
				.then(res => {
					if (res.data.success === true) {
					} else {
						toastr.clear();
						setWorksheetList(old_worksheet_list.sort((a, b) => a.seq - b.seq));
						setTimeout(
							() =>
								toastr.error(
									`Something went wrong while updating sequence of worksheet. Please try again later`
								),
							300
						);
					}
				})
				.catch(error => {
					console.error(error);
					toastr.clear();
					setTimeout(
						() =>
							toastr.error(
								`Something went wrong while updating sequence of worksheet. Please try again later`
							),
						300
					);
					setWorksheetList(old_worksheet_list.sort((a, b) => a.seq - b.seq));
				});
		}
	};
	const SidebarActions = () => {
		const classes = useStyles();
		return (
			<>
				<div
					className={`${styles.worksheetInstructionContainer}`}
					style={{ transition: 'background 0.3s ease' }}
				>
					<NavLink
						to={`/tasks/assigned/${user._id}`}
						// style={{ color: 'white', textDecoration: 'none', padding: '0', cursor: 'pointer',padding:'8px 16px' }}
						className={styles.instructions}
					>
						{/* <div className={`my-4 text-normal d-flex align-items-center`}>
						<div className='' style={{ width:'17px', marginRight: '10px' }}><RedBellIcon style={{ width: '100%' }}/></div>
						<div>
							<NavLink to={'/notifications'} style={{ color: "white" }}>{notificationCount} Notifications</NavLink>
						</div>
					</div>
					<div className={`my-4 text-normal d-flex align-items-center`}>
						<div className='d-flex align-items-center' style={{ width:'20px', marginRight: '10px' }}><UpArrowOrange style={{ width: '100%' }}/></div>
						<NavLink to={'/task/escalated'} style={{ color: "white" }}>{escalationTaskCount} Escalations</NavLink>
					</div> */}
						<div
							className={``}
							// style={{ textAlign: 'center' }}
						>
							{/* <div className='d-flex align-items-center' style={{ width:'20px', marginRight: '10px' }}></div>  */}
							{/* <UpArrowOrange style={{ width: '100%' }}/> */}
							My Tasks
						</div>

						<div>{assignedTaskCount}</div>
					</NavLink>

					<div
						className={`${styles.instructions}`}
						onClick={e => {
							e.preventDefault();
							// setActiveWorksheet(null);
							createNewWorksheet();
						}}
					>
						<div className='p-0'>
							<button className={`${styles.worksheetCountButton} pt-0`} type='button'>
								{worksheetList?.length} Worksheets
							</button>
						</div>

						{createNewWorksheet && (
							<div
								className=''
								// style={{ marginTop: '6px' }}
							>
								<a href=''>
									<PlusIcon />
								</a>
							</div>
						)}
					</div>

					<div
						onClick={handleToggleNewActivity}
						className={styles.instructions}
						id='no-hover'
						style={{ padding: '8px 16px' }}
					>
						<div>New Activity</div>

						<div className='d-flex justify-center' style={{ height: '27px' }}>
							<Switch
								// className='mx-auto'
								className={classes.root}
								checked={onlyShowNewActivity}
								onChange={handleToggleNewActivity}
								inputProps={{ 'aria-label': 'controlled' }}
								// style={{ padding: "0", marginTop: "6px" }}
							/>
						</div>
					</div>
				</div>
			</>
		);
	};

	return (
		<>
			{window.innerWidth <= 600 && (
				<SwipeableDrawer
					open={sidebarActiveStatus}
					onClose={() => setSidebarActive(false)}
					anchor='bottom'
					PaperProps={{
						style: { height: '100vh', background: '#111' }
					}}
				>
					{/* <NoticeBox /> */}

					{/* <div className={`mt-4 text-white ${styles.worksheetListContainer}`} style={{overflowY: 'scroll'}}> */}
					<WorksheetSortableList
						worksheetList={worksheetListByClient}
						getGroupNotificationCount={getGroupNotificationCount}
						getWorksheetWithoutNotificationCount={getWorksheetWithoutNotificationCount}
						onlyShowNewActivity={onlyShowNewActivity}
						expandedGroupIndex={expandedGroupIndex}
						activeWorksheet={activeWorksheet}
						tasks={tasks}
						handleAccordionClick={handleAccordionClick}
						getAllWorksheetsTasks={getAllWorksheetsTasks}
						WorksheetSortableItem={WorksheetSortableItem}
						onSortEnd={handleWorksheetSorting}
						metadataByWorksheetId={metadataByWorksheetId}
						useDragHandle
					/>
					{/* </div> */}
					<div className='d-none justify-content-between' style={{ padding: '15px 10px 3px' }}>
						<div className={`${styles.worksheetInstructionContainer} d-none p-0`}>
							{/* <div className={`my-4 text-normal d-flex align-items-center`}>
						<div className='' style={{ width:'17px', marginRight: '10px' }}><RedBellIcon style={{ width: '100%' }}/></div>
						<div>
							<NavLink to={'/notifications'} style={{ color: "white" }}>{notificationCount} Notifications</NavLink>
						</div>
					</div>
					<div className={`my-4 text-normal d-flex align-items-center`}>
						<div className='d-flex align-items-center' style={{ width:'20px', marginRight: '10px' }}><UpArrowOrange style={{ width: '100%' }}/></div>
						<NavLink to={'/task/escalated'} style={{ color: "white" }}>{escalationTaskCount} Escalations</NavLink>
					</div> */}
							{/* <NavLink
								to={`/tasks/assigned/${user._id}`}
								style={{ color: 'white', textDecoration: 'none' }}
							>
								<div className={`text-normal m-0 d-flex align-items-center`}>
								
									<p>My Tasks</p>
								</div>
								<div style={{ textAlign: 'center', marginTop: '8px', color: 'white' }}>
									{assignedTaskCount}
								</div>
							</NavLink> */}
						</div>

						{/* <div>
							<div className='mx-0'>
								<div className='p-0'>
									<button className={`${styles.worksheetCountButton} pt-0`} type='button'>
										{worksheetList?.length} Worksheets
									</button>
								</div>
								{createNewWorksheet && (
									<div
										className='d-flex align-items-center justify-content-center p-0 mx-auto'
										style={{ marginTop: '6px' }}
									>
										<a
											onClick={e => {
												e.preventDefault();
												// setActiveWorksheet(null);
												createNewWorksheet();
											}}
											href=''
										>
											<PlusIcon />
										</a>
									</div>
								)}
							</div>
						</div> */}

						{/* <div>
							<div className='text-normal p-0 m-0'>New Activity</div>
							<div className='d-flex justify-center'>
								<Switch
									className='mx-auto'
									checked={onlyShowNewActivity}
									onChange={handleToggleNewActivity}
									inputProps={{ 'aria-label': 'controlled' }}
								/>
							</div>
						</div> */}
					</div>
					<SidebarActions />
				</SwipeableDrawer>
			)}

			{/* <div> */}
			{/* <NoticeBox /> */}

			{/* <Divider style={{ background: '#565656' }} /> */}

			{/* <div className="mt-3">
				<input
					type='text'
					placeholder='Global Search'
					style={{
						background: 'transparent',
						color: 'white',
						border: 0,
						width: '100%',
						opacity: '100%',
						marginBottom: '0px',
						padding: '3px 10px',
					}}
					className='text-normal'
					value={searchQuery}
					onChange={e => setSearchQuery(e.target.value)}
				/>
			</div> */}

			{/* <div > */}

			<WorksheetSortableList
				worksheetList={worksheetListByClient}
				getGroupNotificationCount={getGroupNotificationCount}
				getWorksheetWithoutNotificationCount={getWorksheetWithoutNotificationCount}
				onlyShowNewActivity={onlyShowNewActivity}
				expandedGroupIndex={expandedGroupIndex}
				activeWorksheet={activeWorksheet}
				tasks={tasks}
				handleAccordionClick={handleAccordionClick}
				getAllWorksheetsTasks={getAllWorksheetsTasks}
				WorksheetSortableItem={WorksheetSortableItem}
				onSortEnd={handleWorksheetSorting}
				metadataByWorksheetId={metadataByWorksheetId}
				useDragHandle
			/>
			{/* </div> */}
			{/* </div> */}
			<SidebarActions />
		</>
	);
};

export default WorksheetSidebar;

