import React from 'react';
import 'toastr/build/toastr.min.css';
import { Divider, makeStyles } from '@material-ui/core';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import { AiFillCaretUp } from 'react-icons/ai';
import ChatBubbleIcon from '@mui/icons-material/ChatBubble';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import style from './Accordion.css';
import AccordionTrigger from 'assets/icons/AccordionTrigger';
// import { FaBook } from 'react-icons/fa';
const useStyles = makeStyles((theme) => ({
	root:{
		'& .css-yw020d-MuiAccordionSummary-expandIconWrapper.Mui-expanded' :{
			transform: 'rotate(90deg)',
		},
		
	},
	summary: {
		display: 'flex',
		flexDirection: 'row-reverse',
		padding: "0px 10px",
		justifyContent: 'center',
		alignItems: 'center',
		'& .css-yw020d-MuiAccordionSummary-expandIconWrapper.Mui-expanded' :{
			transform: 'rotate(90deg)',
		},
		'& .css-1fx8m19.Mui-expanded':{
			transform :'rotate(90deg)'
		},
		'& .Mui-expnaded':{
			transform: 'rotate(90deg)',
		}


	},
	details: {
		display: 'flex',
		paddingLeft: '21px',
		flexDirection: 'column',
		alignItems: 'flex-start',
		alignSelf: 'stretch',
	},
}));

const AccordionComponent = ({
	isExpanded,
	index,
	expandedGroupIndex,
	groupNotificationCount,
	entry,
	tasks,
	activeWorksheet,
	handleAccordionClick,
	onlyShowNewActivity,
	handleKnowledgebaseRouting,
	metadataByWorksheetId = {},
	WorksheetSortableItem
}) => {
	// deprecated on 9th nov 2023
	// const AccordionComponent = ({ index, expandedGroupIndex, groupNotificationCount, entry,
	// 	activeWorksheet, handleAccordionClick, onlyShowNewActivity, handleKnowledgebaseRouting,
	// 	WorksheetSortableItem }) => {

	const classes = useStyles();


	return (
		<div
			className='accordion-with-divider-container'
			style={{
				animationDuration: `${(index + 1) * 100}ms`
			}}
		>
			<Accordion id='accordion_container' expanded={isExpanded} key={index}>
				<AccordionSummary
					className={classes.summary}
					expandIcon={<AccordionTrigger />}
					onClick={() => handleAccordionClick(index)}
				// aria-controls="panel1a-content"
				// id="panel1a-header"

				//   aria-controls={`panel${index + 1}a-content`} id={`panel${index + 1}a-header`} onClick={() => handleAccordionClick(index)}
				// aria-controls={`panel${index + 1}a-content`} id={`panel${index + 1}a-header`}
				>
					<div className='group-name-container'>
						<div className='main-group-name'>

							<a
								className='group_name'
								href={`/chopping-interface/${activeWorksheet}?group=${entry[0]}`}
								target='_blank'
								rel='noreferrer'
								style={{ textDecoration: 'none' }}
							>
								<div className={isExpanded ? '' : 'not-active-group'}>{entry[0]}</div>
							</a>
						</div>
						{/* //Show ChatBubble icon when there are some new comments,
						If there are no new comments and all task done show green check */}
						<div className='d-flex'>
							{entry[1]?.some(
								worksheet => metadataByWorksheetId[worksheet._id]?.tasks_with_new_comments > 0
							) ? (
								<div className='d-flex align-items-center mr-1'>
									<ChatBubbleIcon style={{ color: '#5FB924', cursor: 'pointer' }} />
									<p className='text-small m-0 ml-1' style={{ color: '#5FB924' }}>
										{groupNotificationCount}
									</p>
								</div>
							) : null}
						</div>
					</div>
				</AccordionSummary>
				<AccordionDetails className={classes.details}>
					{entry[1]?.map((worksheet, index) => {
						if (worksheet?.type === 'knowledgebase') {
							return '';
						} else {
							return (
								// <Link key={index}  to={`/chopping-interface/${worksheet._id}`} style={{ textDecoration: 'none' }}>
								<>
									{((metadataByWorksheetId[worksheet._id]?.tasks_with_new_comments > 0 &&
										onlyShowNewActivity) ||
										!onlyShowNewActivity) && (
											<WorksheetSortableItem
												key={`item-${index}`}
												index={index}
												worksheet={worksheet}
												sheetTasks={tasks}
											/>
										)}
								</>
							);
						}
					})}
				</AccordionDetails>
			</Accordion>

			{/* <MuiAccordion id='accordion_container' disableGutters elevation={0} square expanded={expandedGroupIndex === index}>
				<AccordionSummary aria-controls={`panel${index + 1}d-content`} id={`panel${index + 1}d-header`} onClick={() => handleAccordionClick(index)} style={{ transitionDelay: "500ms" }}>
					<div className='d-flex justify-content-between w-100 align-items-center'>
						<a className='group_name' href={`/chopping-interface/${activeWorksheet}?group=${entry[0]}`} target='_blank' rel='noreferrer' style={{ textDecoration: 'none' }}>
							<div className='text-muted'>{entry[0]}</div>
						</a>
						<div className='d-flex'>

							{(groupNotificationCount > 0) &&
								(
									<div className="d-flex align-items-center mr-1">
										<ChatBubbleIcon
											style={{ color: '#5FB924', cursor: 'pointer' }}
										/>
										<p class="text-small m-0 ml-1" style={{ color: '#5FB924' }}>
											{groupNotificationCount}
										</p>
									</div>
								)
							}
							<div onClick={(e) => { handleKnowledgebaseRouting(e, entry) }} title='Knowledgebase'>

								<FaBook style={{ color: '#fff', marginRight: '5px', marginLeft: '5px' }} />
							</div>
							{index === expandedGroupIndex ? <AiFillCaretUp /> : <AiFillCaretDown />}
						</div>
					</div>
				</AccordionSummary>
				 <AccordionDetails className='p-0 m-0' style={{ height: "fit-content", transition: "height 1s ease-in-out" }}>
					{entry[1]?.map((worksheet, index) => {
						if (worksheet?.type === 'knowledgebase') {
							return ''

						} else {
							return (
								// <Link key={index}  to={`/chopping-interface/${worksheet._id}`} style={{ textDecoration: 'none' }}>
								<>
									{((worksheet?.new_comments > 0 && onlyShowNewActivity) || (!onlyShowNewActivity)) &&
										<WorksheetSortableItem key={`item-${index}`} index={index} worksheet={worksheet} />
									}
								</>
							);
						}
					})}
				</AccordionDetails>
			</MuiAccordion>  */}
			{/* <Divider style={{ background: 'rgb(255, 255, 255, 0.2)' }} /> */}
		</div>
	);
};

export default AccordionComponent;

