import { StylesContext } from '@material-ui/styles';
import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import AddEditModal from './AddEditModal';
import DeleteModal from './DeleteModal';

import styles from './Team.module.css';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import Autocomplete from '@mui/material/Autocomplete';

import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import API_URLS from './apiUrls';
import * as axiosBase from 'axios';
import {
	Avatar,
	Backdrop,
	Box,
	Button,
	CircularProgress,
	Grid,
	TextField,
	Typography
} from '@material-ui/core';

/*
const useStyles = makeStyles(theme => ({
	root: {
		backgroundColor: '',
		'& > *': {
			margin: theme.spacing(1),
			width: 200
		}
	}
}));
const helperTextStyles = makeStyles(theme => ({
	root: {
		margin: 4
	},
	error: {
		color: 'blue',
		'&.MuiFormHelperText-root.Mui-error': {
			color: 'blue'
		}
	}
}));
*/
const Team = () => {
	let [team, setTeam] = useState([]);
	const [loading, setLoading] = useState(false);

	const accessToken = localStorage.getItem('access_token');
	const axios = axiosBase.create({
		headers: {
			Accept: 'application/json',
			Authorization: `Bearer ${accessToken}`,
			'Access-Control-Allow-Origin': '*'
		}
	});
	//const classes = useStyles();

	//const helperTestClasses = helperTextStyles();

	// const localStorageData = localStorage.getItem('redwing_data');

	// const accessToken = localStorage.getItem('access_token');

	// add edit modal
	const [openModal, setOpenModal] = React.useState(false);
	const [modalOperation, setModalOperation] = useState('edit');
	const [modalHeaderText, setModalHeaderText] = useState('');
	const [modalSubTitle, setModalSubTitle] = useState('');
	const [user_id, setUser_id] = useState('');
	const [teamLeads,setTeamLeads]=useState([])

	const handleClickOpen = member => {
		 filterTeamLeadsFromUsers()

		if (modalOperation === 'edit') {
			setModalFormValues(member);
			setUser_id(member._id);
		}
		setOpenModal(true);
	};
	const commonHeaderValue = {
		Accept: 'application/json',
		Authorization: `Bearer ${accessToken}`,
		'Access-Control-Allow-Origin': '*'
	};

	const [modalFormValues, setModalFormValues] = useState({
		name: '',
		email: '',
		role: '',
		daily_hours_to_complete: 25 ,
		team_lead_id : ''
	});

	const [errorMessage, setErrorMessage] = useState({
		name: '',
		email: '',
		role: '',
		daily_hours_to_complete: '',
		team_lead_id : ''

	});

	const designationValues = [
		{ label: 'Team Lead', value: 'team_lead' },
		{ label: 'Senior Developer', value: 'senior_developer' },
		{ label: 'Junior Developer', value: 'junior_developer' },
		{ label: 'Full Time Inern', value: 'full_time_intern' },
		{ label: 'Part Time Intern', value: 'part_time_intern' },
		{ label: 'Contractor', value: 'contractor' },
		{ label: 'Owner', value: 'owner' },
		// { label: 'default', value: '' }
	];

	// delete modal
	const [openDeleteModal, setOpenDeleteModal] = useState(false);
	const handleDeleteClickOpen = user_id => {
		setUser_id(user_id);
		setOpenDeleteModal(true);
	};

	const handleModalChange = element => {
		console.log(element.value);
		setModalFormValues({ ...modalFormValues, [element.name]: element.value });
	};
	const handleModalSubmit = e => {
		e.preventDefault();
		setLoading(true);

		if (modalOperation == 'add') {
			axios
				.post(API_URLS.addTeamMember, modalFormValues)
				.then(data => {
					console.log({ data });
					localStorage.setItem('set_password_token', data.data.token);
					const token = localStorage.getItem('set_password_token');
					console.log({ token });
					setTeam([...team, modalFormValues]);
					setModalSubTitle(data.data.message);
					// setTimeout(() => {
					// 		window.location.reload();
					// }, 2000);
					setLoading(false);
					setModalFormValues(() => {
						return {
							name: '',
							email: '',
							role: '',
							daily_hours_to_complete: ''
						};
					});
					console.log(modalFormValues);
				})
				.catch(err => {
					console.log({ err });
					setErrorMessage(err.response.data.errors);
					setModalFormValues(() => {
						return {
							name: '',
							email: '',
							role: '',
							daily_hours_to_complete: ''
						};
					});
					setLoading(false);
				});
			console.log({ errorMessage });
		} else if (modalOperation == 'edit') {
			axios
				.put(API_URLS.editTeamMember(user_id), modalFormValues)
				.then(data => {
					console.log({ data });
					setModalSubTitle(data.data.message);
					// setTimeout(() => {
					// 		window.location.reload();
					// }, 2000);

					setTeam(preValues => {
						//		const otherMember = preValues.filter(member => {
						// 	return member._id != user_id;
						// });
						// return [...otherMember, modalFormValues];
						// edit a team member  without changing iits position
						let newList = [];
						for (let i = 0; i < preValues.length; i++) {
							if (preValues[i]._id !== user_id) {
								newList.push(preValues[i]);
							} else {
								newList.push(modalFormValues);
							}
						}
						console.log({newList});
						return newList;
					});
					setLoading(false);
				})
				.catch(err => {
					console.log({ err });
					setErrorMessage(err.response.data.errors);
					setLoading(false);
				});
		}

		//	console.log({ modalFormValues });
	};

	//console.log({ errorMessage });

	useEffect(() => {
		fetch(API_URLS.getTeamMembers, {
			method: 'GET',
			headers: commonHeaderValue
		})
			.then(responce => responce.json())
			.then(data => {
				console.log({ data });
				setTeam(data.users);
			});
	}, []);
	// console.log({ team });

	// modal content

	function filterTeamLeadsFromUsers ()  {
		if (team.length > 0) {
			const filteredTeamLeads = team.filter((user) => {
				if (user.role === "owner" || user.role === "team_lead") {
					return user
				}
			})
			console.log({filteredTeamLeads});
			setTeamLeads(filteredTeamLeads)
		}
	}


	const content = (
		<>
			{' '}
			<div>
				<TextField
					id={'name'}
					name={'name'}
					label={'Name'}
					variant='filled'
					value={modalFormValues?.name}
					helperText={errorMessage?.name}
					onChange={e => {
						handleModalChange(e.target);
					}}
					// FormHelperTextProps={{ classes: helperTestClasses }}
					error={errorMessage.name ? true : false}
				/>
			</div>
			<div>
				<TextField
					id={'email'}
					name={'email'}
					label={'Email'}
					variant='filled'
					value={modalFormValues?.email}
					helperText={errorMessage?.email}
					error={errorMessage.email ? true : false}
					onChange={e => {
						handleModalChange(e.target);
					}}
				/>
			</div>
			<div>
				<TextField
					id={'expectedWeeklyOutput'}
					name={'daily_hours_to_complete'}
					label={'Expected Daily Output'}
					variant='filled'
					type='number'
					value={modalFormValues?.daily_hours_to_complete}
					helperText={errorMessage?.daily_hours_to_complete}
					error={errorMessage.daily_hours_to_complete ? true : false}
					onChange={e => {
						handleModalChange(e.target);
					}}
				/>
			</div>
			<FormControl variant='filled' sx={{ width: '100%' }} error={errorMessage.role ? true : false}>
				<InputLabel id='demo-simple-select-helper-label'>Designation</InputLabel>
				<Select
					labelId='demo-simple-select-helper-label'
					id='role'
					value={modalFormValues.role}
					label='Designation'
					name='role'
					onChange={e => handleModalChange(e.target)}
				>
					{/* <MenuItem disabled value=''>
							<em>Default</em>
						</MenuItem> */}
					{designationValues.map(designation => {
						return (
							<MenuItem key={designation.value} value={designation.value}>
								{designation.label}
							</MenuItem>
						);
					})}
				</Select>
				<FormHelperText>{errorMessage?.role}</FormHelperText>

			</FormControl>

			<FormControl variant='filled' sx={{ width: '100%' }} error={errorMessage.team_lead_id ? true : false}>
				<InputLabel id='demo-simple-select-helper-label'>Team Lead</InputLabel>
				<Select
					labelId='demo-simple-select-helper-label'
					id='role'
					value={modalFormValues.team_lead_id}
					label='Team Lead'
					name='team_lead_id'
					onChange={e => handleModalChange(e.target)}
				>
					{/* <MenuItem disabled value=''>
							<em>Default</em>
						</MenuItem> */}
					{teamLeads.map(user => {
						return (
							<MenuItem key={user.name} value={user._id}>
								{user.name}
							</MenuItem>
						);
					})}
				</Select>
				<FormHelperText>{errorMessage?.team_lead_id}</FormHelperText>
			</FormControl>
			{/* <Autocomplete
					disablePortal
					id='combo-box-demo'
					options={designationValues}
					value={modalFormValues.role}
					name='role'
					onChange={e => handleModalChange(e.target)}
					renderInput={designationValues => (
						<TextField
							{...designationValues}
							label='Designation'
							onChange={e => handleModalChange(e.target)}
						/>
					)}
					helperText={errorMessage?.role}
					error={errorMessage.role ? true : false}
				>
					<FormHelperText>{errorMessage?.role}</FormHelperText>
				</Autocomplete> */}
		</>
	);

	return (
		<div style={{ width: '100%', color: 'white', padding: '20px 30px' }}>
			<Backdrop open={loading} style={{ zIndex: 10000 }}>
				<CircularProgress color='inherit' />
			</Backdrop>
			<table className='teamTable'>
				<thead>
					<tr>
						<th>First Name</th>
						<th>last Name</th>
						<th>Email</th>
						<th>Expected daily Output</th>
						<th>Worksheet Count</th>
					</tr>
				</thead>

				<tbody>
					{team.length > 0 &&
						team.map(member => {
							const firstName = member.name.split(' ')[0];
							const lastName = member.name.split(' ')[1];
							return (
								<tr key={member?._id}>
									<td>{firstName}</td>
									<td>{lastName}</td>
									<td>{member?.email}</td>
									<td>{member?.daily_hours_to_complete}</td>
									<td>{member?.worksheet_count > 0 ? member.worksheet_count : ''}</td>
									<td
										style={{ cursor: 'pointer' }}
										onClick={() => {
											setModalOperation('edit');
											setModalHeaderText('Edit Team Member');
											handleClickOpen(member);
										}}
									>
										Edit
									</td>
									<td
										style={{ cursor: 'pointer' }}
										onClick={() => {
											handleDeleteClickOpen(member?._id);
										}}
									>
										Delete
									</td>
								</tr>
							);
						})}
				</tbody>
			</table>
			<div
				style={{
					textAlign: 'center',
					cursor: 'pointer'
				}}
				onClick={() => {
					setModalOperation('add');
					setModalHeaderText('Add Team Member');
					handleClickOpen('');
				}}
				className={styles.addMember}
			>
				Add
			</div>
			<AddEditModal
				openModal={openModal}
				setOpenModal={setOpenModal}
				handleClickOpen={handleClickOpen}
				modalFormValues={modalFormValues}
				setModalFormValues={setModalFormValues}
				modalHeaderText={modalHeaderText}
				setModalSubTitle={setModalSubTitle}
				modalSubTitle={modalSubTitle}
				content={content}
				modalAgreeText={'Save'}
				modalCancelText={'Cancel'}
				setTeam={setTeam}
				modalOperation={modalOperation}
				setModalOperation={setModalOperation}
				handleModalSubmit={handleModalSubmit}
				user_id={user_id}
				setErrorMessage={setErrorMessage}
			/>
			<DeleteModal
				openDeleteModal={openDeleteModal}
				setOpenDeleteModal={setOpenDeleteModal}
				setTeam={setTeam}
				user_id={user_id}
			/>
			<AddEditModal
				openModal={openModal}
				setOpenModal={setOpenModal}
				handleClickOpen={handleClickOpen}
				modalFormValues={modalFormValues}
				setModalFormValues={setModalFormValues}
				modalHeaderText={modalHeaderText}
				setModalSubTitle={setModalSubTitle}
				modalSubTitle={modalSubTitle}
				content={content}
				modalAgreeText={'Save'}
				modalCancelText={'Cancel'}
				setTeam={setTeam}
				modalOperation={modalOperation}
				setModalOperation={setModalOperation}
				handleModalSubmit={handleModalSubmit}
				user_id={user_id}
				setErrorMessage={setErrorMessage}
			/>
			<DeleteModal
				openDeleteModal={openDeleteModal}
				setOpenDeleteModal={setOpenDeleteModal}
				setTeam={setTeam}
				user_id={user_id}
			/>
		</div>
	);
};

export default Team;
