import { ExpandLess, ExpandMore } from '@mui/icons-material';
import ChatBubbleIcon from '@mui/icons-material/ChatBubble';
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';
import CircleIcon from '@mui/icons-material/Circle';
import CloseIcon from '@mui/icons-material/Close';
import OpenInFullIcon from '@mui/icons-material/OpenInFull';
import SearchIcon from '@mui/icons-material/Search';
import Collapse from '@mui/material/Collapse';
import List from '@mui/material/List';
import * as axiosBase from 'axios';
import debounce from 'lodash/debounce';
import {
	createContext,
	createRef,
	Profiler,
	useCallback,
	useContext,
	useEffect,
	useRef,
	useState
} from 'react';
import { useSelector } from 'react-redux';
import Select from 'react-select';
import toastr from 'toastr';
import styles from './ChoppingInterface.module.css';
// import { MuiThemeProvider, createMuiTheme } from 'material-ui/styles';
import { makeStyles } from '@material-ui/core/styles';
import SettingsIcon from '@mui/icons-material/Settings';
import LinearProgress from '@mui/material/LinearProgress';
import { ObjectID } from 'bson';
import moment from 'moment';
import { Link, useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import 'toastr/build/toastr.min.css';

import {
	Backdrop,
	Box,
	Button,
	Checkbox,
	CircularProgress,
	FormControlLabel,
	Modal
} from '@material-ui/core';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import {
	Divider,
	ListItemButton,
	ListItemIcon,
	ListItemText,
	Menu,
	MenuItem,
	Typography
} from '@mui/material';
import { AiOutlinePlus, AiOutlineRight } from 'react-icons/ai';

import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import { Info, RightArrowCircle, ThreeDotsVertical } from 'assets/icons';
import CustomAvatar from 'components/CustomAvatar/CustomAvatar';
import Wysiwyg from 'components/wysiwyg';
import 'trix/dist/trix.css';

import FormatIndentDecreaseIcon from '@mui/icons-material/FormatIndentDecrease';
import FormatIndentIncreaseIcon from '@mui/icons-material/FormatIndentIncrease';
import LoadingButton from '@mui/lab/LoadingButton';
import TaskIcon from 'components/TaskIcon/TaskIcon';
import WorksheetSidebar from 'components/WorksheetSidebar/WorksheetSidebar';
import useCommentButtons from 'hooks/useCommentButtons';

// action menu icons
import { Helmet } from 'react-helmet';
import { FaTimes } from 'react-icons/fa';
import { useDispatch } from 'react-redux';
import { fetchPresetMessages } from 'reducers/presetMessages';
import { fetchPresetTasks } from 'reducers/presetTasks';
import { GlobalSearch } from 'screens/GlobalSearch/GlobalSearch';
import deleteIcon from '../../assets/icons/delete.svg';
import { calculateEstimate } from './utils';
// import { setSearchIndex } from '../../reducers/searchIndex';
import { Add, Remove } from '@material-ui/icons';
import { TaskDetailsPopup } from 'components/TaskDetailsPopup/TaskDetailsPopup';
import { IoMdRefresh } from 'react-icons/io';
import { getSearchData, setValueToIndexedDB } from '../GlobalSearch/indexedDB';

import CheckIcon from '@mui/icons-material/Check';
import {
	fetchTaskDetailsInBulk,
	fetchTasksWithNewComments,
	fetchWorkSheetList
} from 'api/choppingInterface.api';
import { isMobile } from 'react-device-detect';
import { ClipLoader } from 'react-spinners';
import { cacheWorksheetInRecentlyVisitedList } from 'utils/cacheUtils';
import { changeEstimateUtil } from 'utils/changeEstimation';
import TaskTitleInput from './TaskTitleInput';
import WorksheetActivity from 'screens/WorksheetActivity/WorksheetActivity';
import WorksheetCompletionList from 'screens/WorksheetCompletionList/WorksheetCompletionList';
import CommonHeader from 'components/CommonHeader/CommonHeader';
import TaskTreeIcon from './components/TaskTreeIcons/TaskTreeIcon';

const token = localStorage.getItem('red_wing_token');
const accessToken = localStorage.getItem('access_token');
const user = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : {};

const axios = axiosBase.create({
	headers: {
		Accept: 'application/json',
		Authorization: `Bearer ${accessToken}`,
		'Access-Control-Allow-Origin': '*'
	}
});

toastr.options = {
	positionClass: 'toast-top-center',
	zIndex: 66666,
	hideDuration: 300,
	timeOut: 5000
};

function useDebouncedCallback(callback, delay, updates, timeoutId) {
	// Define a memoized callback function
	return useCallback(
		newItem => {
			// Find the index of the new item in the updates array
			const index = updates.current.findIndex(item => item._id === newItem._id);

			// If the new item is not found, add it to the updates array
			if (index === -1) {
				updates.current = [...updates.current, newItem];
			} else {
				// If the new item is found, replace it in the updates array
				updates.current[index] = newItem;
			}

			// Clear any existing timeout for the current item
			if (timeoutId.current[newItem._id]) {
				clearTimeout(timeoutId.current[newItem._id]);
			}

			// Set a new timeout for the current item
			timeoutId.current[newItem._id] = setTimeout(() => {
				// Call the provided callback with the new item after the delay
				callback(newItem);

				// Remove the item from the updates array
				updates.current = updates.current.filter(item => item._id !== newItem._id);
			}, delay);
		},
		[callback, delay, updates, timeoutId]
	);
}

export const TaskListContext = createContext();

const UPDATING_TASK_DATA_STATUS = {
	IDLE: 'IDLE',
	LOADING: 'LOADING',
	COMPLETE: 'COMPLETE'
};

const onRenderCallback = (
	id, // the "id" prop of the Profiler tree that has just committed
	phase, // either "mount" (if the tree just mounted) or "update" (if it re-rendered)
	actualDuration, // time spent rendering the committed update
	baseDuration, // estimated time to render the entire subtree without memoization
	startTime, // when React began rendering this update
	commitTime, // when React committed this update
	interactions // the Set of interactions belonging to this update
) => {
	console.log(`Component: ${id}`);
	console.log(`Phase: ${phase}`);
	console.log(`Actual Duration: ${actualDuration}ms`);
	console.log(`Base Duration: ${baseDuration}ms`);
	console.log(`Start Time: ${startTime}ms`);
	console.log(`Commit Time: ${commitTime}ms`);
	console.log('---');
};

const ChoppingInterface = ({
	notificationCount,
	isBottomNavVisible,
	setBottomNavVisible,
	onToggleWorksheetSidebar,
	isWorksheetSidebarHidden
}) => {
	const location = useLocation();
	const [todo, setTodo] = useState([]);
	const [taskList, setTaskList] = useState([]);
	const [worksheetList, setWorksheetList] = useState([]);
	const [prefetchedTasksMap, setPrefetchedTasksMap] = useState({});
	const [tasksWithNewComments, setTasksWithNewComments] = useState({});
	const [allIds, setAllIds] = useState([]);
	const [activeWorksheet, setActiveWorksheet] = useState(null);
	const [stateSidebar, setStateSidebar] = useState(false);
	const [activeWorksheetData, setActiveWorksheetData] = useState([]);
	const [selectedItems, setSelectedItems] = useState([]);
	const [budget, setBudget] = useState(0);
	// const [userList, setUserList] = useState(null);
	const [activeTask, setActiveTask] = useState(null);
	const [openUser, setOpenUser] = useState(false);
	const [users, setUsers] = useState(null);
	const [assgineeList, setAssgineeList] = useState(null);
	const [totalHours, setTotalHours] = useState(0);
	const [tempHour, setTempHour] = useState(0);
	const [completedHours, setCompletedHours] = useState(0);
	const [completedToday, setCompletedToday] = useState(null);
	// const [hideChilds, setHideChilds] = useState([]);
	const [inputRef, setInputRef] = useState([]);
	const [withoutEstimates, setWithoutEstimates] = useState(null);
	const [hardTasks, setHardTasks] = useState(null);
	const [mediumTasks, setMediumTasks] = useState(null);
	const [easyTasks, setEasyTasks] = useState(null);
	const { worksheet_id } = useParams();
	const navigate = useNavigate();
	const [searchParams, setSearchParams] = useSearchParams();
	const [showEstimates, setShowEstimates] = useState(true);
	const [hideCompleted, setHideCompleted] = useState(false);
	const [fadeListType, setFadeListType] = useState('');
	const [worksheetTitle, setWorksheetTitle] = useState('');
	const [peopleModal, setPeopleModal] = useState(false);
	const [groupMenu, setGroupMenu] = useState(false);
	const [team, setTeam] = useState([]);
	const [worksheetAccessTeam, setWorksheetAccessTeam] = useState([]);
	const [addPeopleOption, setAddPeopleOption] = useState([]);
	const [addPeopleValues, setAddPeopleValues] = useState([]);
	const [errorMessage, setErrorMessage] = useState(null);
	const [commentCounts, setCommentCounts] = useState({});
	const [taskDesc, setTaskDesc] = useState('');
	const [selectedTask, setSelectedTask] = useState(null);
	const [prevTask, setPrevTask] = useState(null);
	const [grandParentTask, setGrandParentask] = useState(null);
	const [openTaskModal, setOpenTaskModal] = useState(false);
	const [openTaskDetailsModal, setOpenTaskDetailsModal] = useState(false);
	const [shouldScrollToComment, setShouldScrollToComment] = useState(false);
	const [taskComment, setTaskComment] = useState('');
	const [commentType, setCommentType] = useState('');
	const [openTaskCommentModal, setOpenTaskCommentModal] = useState(false);
	const [openMoveTaskModal, setOpenMoveTaskModal] = useState(false);
	const [moveToWorksheet, setMoveToWorksheet] = useState(null);
	const [newlyCreatedWorksheetId, setNewlyCreatedWorksheetId] = useState(null);
	const [loading, setLoading] = useState(true);
	const [isFetchingTasksWithNewComments, setIsFetchingTasksWithNewComments] = useState(false);
	const [btnLoading, setBtnLoading] = useState(false);
	const [searchInput, setSearchInput] = useState('');
	const presetTasks = useSelector(state => state.reducerTasks.data);
	const [tasks, setTasks] = useState([]);
	const [searchedTasks, setSearchedTasks] = useState([]);
	const [searchedTasksLoading, setSearchedTasksLoading] = useState(false);
	const [searchQueryLength, setSearchQueryLength] = useState(0);
	const [taskDrawerOpen, setTaskDrawerOpen] = useState(false);
	const [unreadsPresent, setUnreadsPresent] = useState(false);
	const [unreadId, setUnreadId] = useState(null);
	const titleRef = useRef();
	const headerRef = useRef();
	const [headerHeightSet, setHeaderHeightSet] = useState(false);
	const [arrowsVisible, setArrowsVisible] = useState(false);
	const [upArrowCLickable, setUpArrowCLickable] = useState(false);
	const [downArrowClickable, setDownArrowClickable] = useState(true);
	const dataFetched = useRef(false);
	const [showDescBox, setShowDescBox] = useState(false);
	const [hyperlinkedComment, setHyperlinkedComment] = useState('');
	const [worksheetGroups, setWorksheetGroups] = useState([]);
	const [updatingTaskDataStatus, setUpdatingTaskDataStatus] = useState({
		status: UPDATING_TASK_DATA_STATUS.IDLE,
		taskId: null
	});

	const [openSpotlightModal, setOpenSpotlightModal] = useState(false);
	const [openTeamsNotificationClear, setOpenTeamsNotificationClear] = useState(false);
	const [shouldScrollToNotification, setShouldScrollToNotification] = useState(false);
	const dispatch = useDispatch();

	//footer setting menu
	const [openSettingsMenu, setOpenSettingsMenu] = useState(true);
	const [anchorEl, setAnchorEl] = useState(null);
	const [groupAnchor, setGroupAnchor] = useState(null);
	const [loadingGroups, setLoadingGroups] = useState(false);
	const [userPopupBelow, setUserPopupBelow] = useState(false);
	const [showSearchBar, setShowSearchBar] = useState(false);
	const open = Boolean(anchorEl);
	const [settingsdrawerOpen, setSettingsDrawerOpen] = useState();
	const [isOpenNewGroup, setIsOpenNewGroup] = useState(false);
	const [newGroupName, setNewGroupName] = useState('');
	// const searchData = useSelector(state => state.searchIndexReducer.documents);
	const [groupDrawerOpen, setGroupDrawerOpen] = useState();
	const [searchData, setSearchData] = useState([]);
	const [newCommentCount, setNewCommentCount] = useState(0);
	const [fetchingUsers, setFetchingUsers] = useState(true);
	const [metadataByWorksheetId, setMetaDataByWorksheetId] = useState({});

	const handleSettingsIconClick = event => {
		if (window.innerWidth <= 600) {
			setSettingsDrawerOpen(true);
			return;
		}
		setOpenSettingsMenu(!openSettingsMenu);
		setAnchorEl(event.currentTarget);
		setGroupAnchor(event.currentTarget);
	};
	const handleSettingsMenuClose = () => {
		setAnchorEl(null);
	};

	const handleSettingsDrawerClose = () => {
		setSettingsDrawerOpen(false);
	};

	const handleOpenTaskModal = task => {
		setSelectedTask(task);
		setTaskDesc(task?.description);
		setOpenTaskModal(true);
	};
	const handleCloseTaskModal = () => setOpenTaskModal(false);

	const handleOpenTaskDetailsModal = task => {
		setSelectedTask(task);
		setOpenTaskDetailsModal(true);
		getUnreadNotifications(task, { showLoader: false });
	};
	const handleCloseTaskDetailsModal = () => {
		setOpenTaskDetailsModal(false);
		setShouldScrollToComment(false);
		if (unreadsPresent || unreadId) {
			setUnreadsPresent(false);
			setUnreadId(null);
		}
		if (showDescBox) {
			setShowDescBox(false);
		}
		if (hyperlinkedComment) {
			setHyperlinkedComment('');
		}
		if (selectedTask) {
			let temp_task = taskList.find(task => task._id === selectedTask._id);
			setTasksWithNewComments(prev => {
				prev[temp_task._id] = false;
				return prev;
			});
			updateTaskFromTaskDetailsModal(temp_task);
		}
		updateWorksheetList();
	};

	const handleOpenTaskCommentModal = (task, comment_type) => {
		setCommentType(comment_type);
		setSelectedTask(task);
		setOpenTaskCommentModal(true);
	};
	const handleCloseTaskCommentModal = () => setOpenTaskCommentModal(false);

	const handleOpenMoveTaskModal = task => {
		setSelectedTask(task);
		setOpenMoveTaskModal(true);
	};

	const handleOpenTaskDrawer = task => {
		setSelectedTask(task.item);
		setPrevTask(task.prevItemId);
		setGrandParentask(task.prevItemParentId);
		// console.log(task)
		setTaskDrawerOpen(true);
	};

	const handleCloseTaskDrawer = () => {
		setTaskDrawerOpen(false);
	};

	const handleCloseAsUnread = () => {
		setOpenTaskDetailsModal(false);
		markNotificationUnread();
	};

	const handleUpdateSelectedTask = items => {};

	const getUnreadNotifications = (task, config = {}) => {
		const { showLoader = true } = config;
		if (showLoader) {
			setLoading(true);
		}
		const url = `${process.env.REACT_APP_LARAVEL_API_URL}/notification?type=unread`;
		axios
			.get(url)
			.then(data => {
				if (data.data.data.length) {
					data.data.data.forEach(not => {
						if (not.data?.task?._id === task?._id && not.read_at === null) {
							setUnreadsPresent(true);
							setUnreadId(not._id);
						}
					});
				}
			})
			.catch(e => console.log(e))
			.finally(e => {
				setLoading(false);
			});
	};

	const markNotificationUnread = () => {
		// updateWorksheetList(commentCounts,true);
		const url = `${process.env.REACT_APP_LARAVEL_API_URL}/notification/${unreadId}/unread`;
		axios
			.post(url)
			.then(data => {
				// const task = selectedTask;
				// updateTaskFromTaskDetailsModal(task);
				// getWorksheetList(true);
			})
			.catch(e => {
				console.log(e);
				setUnreadsPresent(false);
				setUnreadId(null);
			})
			.finally(e => {
				setLoading(false);
				setUnreadsPresent(false);
				setUnreadId(null);
			});
	};
	const saveForLater = (notificationId, type) => {
		setLoading(true);
		const url = `${process.env.REACT_APP_LARAVEL_API_URL}/notification/${unreadId}/save-for-later`;
		axios
			.post(url, {
				status: true
			})
			.then(data => {
				console.log(data.data);
				toastr.success('saved for later');
			})
			.catch(e => console.log(e))
			.finally(e => {
				// setLoading(false);
			});
	};

	const handleCommentSubmit = (comment_temp = false) => {
		let comment = {
			todo_id: selectedTask._id,
			content: comment_temp ? comment_temp : taskComment,
			type: commentType
		};
		addComment(comment);
		handleCloseTaskCommentModal();
	};

	const handleDescriptionSubmit = () => {
		let task = {
			...selectedTask,
			todo_id: selectedTask._id,
			value: selectedTask.title,
			description: taskDesc
		};
		updateTaskOnBackend(task);
		handleCloseTaskModal();
	};

	const handleUpdateTaskWithPresetTask = (item, preset) => {
		// Prompt the user for confirmation before proceeding
		if (
			!window.confirm(
				'If you add a preset template to this task, the current title and description will be replaced with the content of the template. Are you sure you want to proceed with this action?'
			)
		) {
			// If the user cancels, return without making any changes
			return;
		}

		// update the task with the preset template
		item.value = preset.title;
		item.title = preset.title;
		item.description = preset.description;

		// Update tasks in the state
		setTasks(prev => prev.map(task => (task._id === item._id ? item : task)));

		// Update tasks in the state
		setTaskList(prev => prev.map(task => (task._id === item._id ? item : task)));

		// Update item properties directly
		item.todo_id = item._id;
		item.value = item.title;
		item.title = item.title;
		item.description = item.description;

		// Call the updateTaskApi function with the updated item
		updateTaskApi(item);
	};

	const getWorksheetGroups = () => {
		setLoadingGroups(true);
		const url = `${process.env.REACT_APP_LARAVEL_API_URL}/client`;
		axios
			.get(url)
			.then(data => {
				setWorksheetGroups(data.data.data);
			})
			.catch(e => console.log(e))
			.finally(e => {
				setLoadingGroups(false);
			});
	};

	const handleOpenGroups = e => {
		e.preventDefault();
		if (window.innerWidth >= 600) {
			handleSettingsMenuClose();
			setGroupMenu(true);
		} else {
			handleSettingsDrawerClose();
			setGroupDrawerOpen(true);
		}
		getWorksheetGroups();
	};

	const handleCloseGroupMenu = () => {
		setGroupMenu(false);
		setGroupAnchor(null);
	};

	const addToGroup = name => {
		const url = `${process.env.REACT_APP_LARAVEL_API_URL}/worksheet/${activeWorksheet}/client`;
		axios
			.post(url, {
				client_name: name
			})
			.then(data => {
				if (window.innerWidth >= 600) handleCloseGroupMenu();
				else setGroupDrawerOpen(false);
				getWorksheetList(true);
				toastr.success('Worksheet Added to group');
			})
			.catch(e => {
				toastr.error('Something went wrong');
			});
	};

	useEffect(() => {
		if (activeWorksheet && worksheetList.length > 0) {
			var worksheet_temp = [...worksheetList];
			var worksheet_index = worksheet_temp.findIndex(function (value) {
				return value._id == activeWorksheet;
			});

			if (worksheet_index >= 0) {
				setWorksheetTitle(
					worksheet_temp[worksheet_index].title ? worksheet_temp[worksheet_index].title : ''
				);
				setActiveWorksheetData(
					worksheet_temp[worksheet_index] ? worksheet_temp[worksheet_index] : []
				);
			}
		}
	}, [activeWorksheet, worksheetList]);

	const updateWorksheetList = () => {
		setMetaDataByWorksheetId(prev => {
			if (prev[activeWorksheet]) {
				prev[activeWorksheet].tasks_with_new_comments -= 1;
			}
			return prev;
		});
	};

	useEffect(() => {
		// Function to handle keydown events
		const moveFocus = e => {
			if (e.key === 'ArrowUp') {
				//console.log(activeIndex);
			}
			if (e.key === 'ArrowDown') {
				// console.log(activeIndex);
			} else if (e.ctrlKey && e.key >= 1 && e.key <= 9) {
				// Collapse task tree to a certain level
				e.preventDefault();
				var temp = [...taskList];
				const tasks = getTasksOfLevel(e.key);
				let task_ids = [];

				// Iterate through tasks of the specified level
				tasks.forEach(item => {
					let newItem = {
						...item,
						todo_id: item._id,
						value: item.title,
						hide_children: true
					};

					// Find the index of the task in the temporary list
					var index = temp.findIndex(function (value) {
						return value._id == item._id;
					});
					temp[index] = newItem;

					// updateTask(newItem);

					task_ids.push(item._id);
				});

				// Collapse the tasks with the specified task IDs
				if (task_ids.length) {
					collapseTasks(task_ids, true);
				}

				// Expand upper levels
				task_ids = [];
				for (let i = 1; i < e.key; i++) {
					const tasks = getTasksOfLevel(i);
					tasks.forEach(item => {
						let newItem = {
							...item,
							todo_id: item._id,
							value: item.title,
							hide_children: false
						};

						// Find the index of the task in the temporary list
						var index = temp.findIndex(function (value) {
							return value._id == item._id;
						});
						temp[index] = newItem;

						// updateTask(newItem);

						task_ids.push(item._id);
					});
				}

				// Expand the tasks with the specified task IDs
				if (task_ids.length) {
					collapseTasks(task_ids, false);
				}

				// Update the task list with the modified temporary list
				setTaskList(temp);
			}
		};

		// Add event listener for keydown events
		document.addEventListener('keydown', moveFocus);

		// Clean up the event listener when the component unmounts
		return () => {
			document.removeEventListener('keydown', moveFocus);
		};
	}, [taskList]);

	const getTasksOfLevel = level => {
		// Filter the taskList to get the tasks at the top level (level 1)
		let tasks = taskList.filter(item => {
			return item.parent_id === 0 || item.parent_id === '' || item.parent_id === null;
		});

		// If the requested level is 1, return the tasks at the top level
		if (level == 1) {
			return tasks;
		}

		// Iterate from level 2 up to the requested level
		for (let i = 2; i <= level; i++) {
			// Filter the taskList to get the tasks that have a parent in the previous level
			tasks = taskList.filter(item => {
				return tasks.find(parent => parent._id === item.parent_id);
			});
		}

		// Return the tasks at the requested level
		return tasks;
	};

	const fetchSearchData = async () => {
		const data = await getSearchData();
		setSearchData(data);
	};

	useEffect(() => {
		getUserList();
		fetchSearchData();
	}, []);

	useEffect(() => {
		let worksheet_access_ids = worksheetAccessTeam.map(r => r._id);
		let filtered_options = team.filter(user => {
			if (worksheet_access_ids.includes(user._id)) {
				return false;
			}
			return true;
		});

		let options = [];
		filtered_options.forEach(user => {
			options.push({
				value: user._id,
				label: user.name
			});
		});
		options.push({
			value: null,
			label: 'Add Team Member'
		});
		setAddPeopleOption(options);
	}, [team, worksheetAccessTeam]);

	// useEffect(()=>{
	// 	console.log(clickedId)
	// 	if(clickedId !==''){
	// 		setBottomNavVisible(false);
	// 	}else{
	// 		setBottomNavVisible(true);
	// 	}
	// },[clickedId])

	//getting users list for assignee
	const getUserList = () => {
		const url = `${process.env.REACT_APP_LARAVEL_API_URL}/users`;
		setFetchingUsers(true);
		axios
			.get(url)
			.then(data => {
				// setUserList(data.users);
				setFetchingUsers(false);
				setUsers(data.data.users);
			})
			.catch(e => {
				setFetchingUsers(false);
			});
	};

	useEffect(() => {
		if (users && tasks) {
			let arr = [];
			users?.map(user => {
				let name = user.name;
				let avatar = user.avatar;
				let workload = 0;
				let task_count = 0;
				tasks.forEach(task => {
					if (user._id === task.assginee) {
						workload += task.estimate;
						task_count += 1;
					}
				});
				arr.push({ name, avatar, workload, task_count });
			});
			setAssgineeList(arr);
		}
	}, [users, tasks]);

	// Declare the AbortController
	const abortControllerRef = useRef(new AbortController());

	const getTodoList = async (worksheet_id, options = {}) => {
		const { refresh = false } = options;
		// Clear any previous error message
		setErrorMessage(null);
		// Set loading state to true
		setLoading(true);

		// Cancel any ongoing request
		cancelRequest();

		try {
			const url = `${process.env.REACT_APP_LARAVEL_API_URL}/task?worksheet_id=${worksheet_id}&refresh=${refresh}`;

			// Add any additional headers as needed

			const signal = abortControllerRef.current.signal;
			const options = {
				signal: signal
			};

			const response = await axios.get(url, options);
			dataFetched.current = false;

			// Check if the response indicates success
			if (response.status === 200) {
				const data = response.data;

				setTasks(data.tasks);
				setTaskList(data.tasks);
				setLoading(false);

				if (shouldScrollToNotification) {
					setTimeout(() => scrollToFirstNotification(), 500);
					setShouldScrollToNotification(false);
				}

				// Perform additional actions if there is a specific item_id in searchParams
				if (searchParams.get('item_id')) {
					const task = data.tasks.find(item => item._id == searchParams.get('item_id'));
					console.log(task, 'task-from-data');
					if (task) {
						if (searchParams.get('comment_id')) {
							setHyperlinkedComment(searchParams.get('comment_id'));
							setTimeout(() => {
								handleOpenTaskDetailsModal(task);
							}, 100);
						}
						updateInputRef(data.tasks, searchParams.get('item_id'));
					}

					searchParams.delete('item_id');
					searchParams.delete('comment_id');
					setSearchParams(searchParams);
				} else {
					updateInputRef(data.tasks);
				}
			} else {
				// Set the error message if the response indicates an error
				const errorData = response.data;
				setErrorMessage(errorData.message);
				dataFetched.current = true;
			}
		} catch (error) {
			if (error.name === 'AbortError') {
				// Request was aborted, do nothing or handle it as needed
			} else {
				// Handle other errors
				console.log(error);
				setErrorMessage('An error occurred while fetching data.');
			}
		} finally {
			// Set loading state to false when the request is completed
			dataFetched.current = true;
			setLoading(false);
			console.log('data fetched set to true');
		}
	};

	const getTasksWithNewComments = async (worksheet_id, options = {}) => {
		setIsFetchingTasksWithNewComments(true);
		try {
			const response = await fetchTasksWithNewComments({
				worksheet_id,
				...options
			});

			const responseData = response.data;

			const notificationCheckByTaskIDs = responseData.data ?? {};
			const idsForTasksWithNewComments = Object.entries(notificationCheckByTaskIDs)
				.filter(entry => !!entry[1])
				.map(entry => entry[0]);

			if (idsForTasksWithNewComments.length) {
				const bulkResponse = await fetchTaskDetailsInBulk(idsForTasksWithNewComments);
				const { data: taskDetailsWithNewComments } = bulkResponse.data;
				const idToTaskMap = {};
				taskDetailsWithNewComments.forEach(task => {
					idToTaskMap[task._id] = task;
				});
				setPrefetchedTasksMap(idToTaskMap);
			}

			setTasksWithNewComments(notificationCheckByTaskIDs);
		} catch (error) {
			if (error.name === 'AbortError') {
				// Request was aborted, do nothing or handle it as needed
			} else {
				// Handle other errors
				console.log(error);
				setErrorMessage('An error occurred while fetching data.');
			}
		} finally {
			setIsFetchingTasksWithNewComments(false);
		}
	};

	// Function to cancel the request
	const cancelRequest = () => {
		abortControllerRef.current.abort();
		abortControllerRef.current = new AbortController();
	};

	const getTeam = () => {
		const url = `${process.env.REACT_APP_LARAVEL_API_URL}/team`;
		axios
			.get(url)
			.then(res => {
				if (res.data.success === true) {
					setTeam(res.data.users);
					// setWorksheetAccessTeam(res.data.users);
				}
			})
			.catch(error => {
				console.error(error);
			});
	};

	const getWorksheetList = async (worksheetIsAlreadySelected, options = {}) => {
		const { refresh = false } = options;
		try {
			const data = await fetchWorkSheetList({ refresh });
			setWorksheetList(data.data.worksheets);
			if (worksheet_id === undefined && !worksheetIsAlreadySelected) {
				setActiveWorksheet(data.data.worksheets[0]._id);
			}
			setAllIds(data.data.all_todos_id);
		} catch (error) {
			console.log(error);
		}
	};

	const getAllWorksheetsMetadata = () => {
		const url = `${process.env.REACT_APP_LARAVEL_API_URL}/worksheet/get-metadata-all`;
		axios
			.get(url)
			.then(res => {
				setMetaDataByWorksheetId(res.data.data);
			})
			.catch(e => console.log(e));
	};

	const refreshWorksheetData = async () => {
		try {
			setLoading(true);
			await getWorksheetList(true, { refresh: true });
			await getTodoList(worksheet_id, { refresh: true });
			await getTasksWithNewComments(worksheet_id, { refresh: true });
			setLoading(false);
		} catch (error) {
			console.log(error);
			setLoading(false);
		}
	};

	const createNewWorksheet = () => {
		// Check if a newly created worksheet already exists
		if (!newlyCreatedWorksheetId) {
			// Generate unique IDs for the worksheet and task
			let temp_worksheet_id = generateObjectId();
			let task_id = generateObjectId();

			// Set the newly created worksheet ID
			setNewlyCreatedWorksheetId(temp_worksheet_id);

			// Determine the sequence number for the new worksheet
			let seq = [...worksheetList].length;

			// Update the worksheetList state with the new worksheet
			setWorksheetList(prev => [
				...prev,
				{
					_id: temp_worksheet_id,
					title: '',
					user_id: user._id,
					seq: seq,
					access: [
						{
							user: user
						}
					]
				}
			]);

			// Save the current active worksheet ID
			let prevActiveWorkSheet = activeWorksheet;

			// Set the newly created worksheet as the active worksheet
			setActiveWorksheet(temp_worksheet_id);

			// Create a new task object
			let newTask = {
				parent_id: '',
				value: '',
				title: '',
				estimate: 0,
				assginee: 0,
				completed: false,
				description: '',
				seq: 1,
				worksheet_id: temp_worksheet_id,
				_id: task_id
			};

			// Set the tasks and taskList states with the new task
			setTasks([newTask]);
			setTaskList([newTask]);

			// Update the input references with the new task
			updateInputRef([newTask], task_id);

			// Update the URL to reflect the newly created worksheet
			window.history.pushState('', '', `/chopping-interface/${temp_worksheet_id}`);

			// Send a POST request to create the worksheet on the backend
			const url = `${process.env.REACT_APP_LARAVEL_API_URL}/worksheet`;
			axios
				.post(url, {
					id: temp_worksheet_id,
					task_id: task_id,
					seq: seq
				})
				.then(data => {
					// Check if the worksheet creation was successful
					if (data.data.success === true) {
						// Clear the newly created worksheet ID
						setNewlyCreatedWorksheetId(null);

						// Display a success message
						setTimeout(() => toastr.success(`Worksheet created successfully.`), 300);
					} else {
						console.log(data.data);
						// Display an error message if the worksheet creation failed
						setTimeout(() => toastr.error(`Something went wrong while creating a worksheet.`), 300);
					}
				})
				.catch(e => {
					if (e.response.status === 422) {
						alert('Something went wrong while creating sheet.');
						console.log(e);
						// Reset the tasks, taskList, and worksheetList states
						setTasks([]);
						setTaskList([]);
						setWorksheetList(prev => prev.filter(r => r._id !== temp_worksheet_id));
						setActiveWorksheet(prevActiveWorkSheet);
						setNewlyCreatedWorksheetId(null);
						// Update the URL to reflect the previous active worksheet
						window.history.pushState('', '', `/chopping-interface/${prevActiveWorkSheet}`);
					}
				});
		}
	};

	///console.log({worksheetList});

	const deleteWorksheet = worksheet_id => {
		// Prompt the user for confirmation before deleting the worksheet
		if (!window.confirm('Are you sure you want to delete this worksheet?')) {
			return 0;
		}

		// Remove the worksheet from the worksheetList state
		setWorksheetList(prev => {
			return prev.filter(r => r._id !== worksheet_id);
		});

		// Send a DELETE request to delete the worksheet on the backend
		axios
			.delete(`${process.env.REACT_APP_LARAVEL_API_URL}/worksheet/${activeWorksheet}`, {
				// headers: {
				// 	Authorization: `Bearer ${token}`,
				// 	'Access-Control-Allow-Origin': '*'
				// }
			})
			.then(res => {
				// Check if the worksheet deletion was successful
				if (res.data.success === true) {
					// Check if the deleted worksheet was the active worksheet
					if (worksheet_id === activeWorksheet) {
						// Set the active worksheet to the first worksheet in the worksheetList
						setActiveWorksheet(worksheetList[0]._id);
						setTaskList([]);
						setTasks([]);
						// Update the URL to reflect the new active worksheet
						window.history.pushState('', '', `/chopping-interface/${worksheetList[0]._id}`);
					}
					setTimeout(() => toastr.success(`Worksheet deleted successfully.`), 300);
				} else {
					console.log(res.data);
					// Refresh the worksheetList if the deletion failed
					getWorksheetList();
					setTimeout(
						() => toastr.success(`Something went wrong while deleting the worksheet.`),
						300
					);
				}
			})
			.catch(error => {
				console.error(error);
			});
	};

	useEffect(() => {
		getWorksheetList();
		getAllWorksheetsMetadata();
		getTeam();
	}, []);

	useEffect(() => {
		let searchDataCopy = searchData ? [...searchData] : [];
		if (searchDataCopy && searchDataCopy.length > 0) {
			tasks?.forEach(task => {
				let index = -1;
				searchDataCopy?.forEach((doc, i) => {
					if (doc?._id === task?._id) {
						index = i;
					}
				});
				if (index === -1) searchDataCopy?.push(task);
				else {
					searchDataCopy[index] = task;
				}
			});
			setValueToIndexedDB(searchDataCopy);
		}
	}, [tasks, searchData]);

	useEffect(() => {
		if (activeWorksheet) {
			let worksheet = [...worksheetList].filter(sheet => {
				if (sheet._id === activeWorksheet) {
					return true;
				}
				return false;
			})[0];
			if (worksheet) {
				let users = worksheet.access.map(a => {
					return a.user;
				});
				users = users.filter(_user => !!_user);
				setWorksheetAccessTeam(users);
			}
		}
	}, [activeWorksheet, worksheetList]);

	const handleWorksheetChange = (e, worksheet_id) => {
		e.preventDefault();
		setSearchQueryLength(0);

		if (worksheet_id !== activeWorksheet) {
			setTasks([]);
			setTaskList([]);
			setActiveWorksheet(worksheet_id);
			window.history.pushState('', '', `/chopping-interface/${worksheet_id}`);
		}
	};

	useEffect(() => {
		const init = async () => {
			if (activeWorksheet !== null && activeWorksheet !== newlyCreatedWorksheetId) {
				const promises = [];
				cancelRequest();
				setDownArrowClickable(true);
				await getTodoList(activeWorksheet);
				await getTasksWithNewComments(activeWorksheet);
			}
		};
		init();
	}, [activeWorksheet]);

	useEffect(() => {
		const handleKeyDown = event => {
			if (event.ctrlKey || event.metaKey) {
				if (event.shiftKey && event.keyCode === 84) {
					autoCreateTaskAtTheBottom(event);
				}
				if (event.key === 'j' || event.keyCode === 74) {
					// Call your function here
					event.preventDefault();
					setOpenSpotlightModal(true);
				}
			}
		};

		document.addEventListener('keydown', handleKeyDown);
		return () => {
			document.removeEventListener('keydown', handleKeyDown);
		};
	}, [taskList.length, inputRef]);

	useEffect(() => {
		if (worksheet_id !== undefined) {
			setActiveWorksheet(worksheet_id);
		}
	}, [worksheet_id]);

	const autoCreateTaskAtTheBottom = async event => {
		event.preventDefault();
		scrollToBottom();
		try {
			let textToBePasted;
			if (isMobile) {
				textToBePasted = '';
			} else {
				textToBePasted = (await navigator?.clipboard?.readText()) || '';
			}
			var newTitle = textToBePasted;

			const lastItem = taskList.at(-1);

			setTimeout(async () => {
				await addTodo({ item: lastItem, newTitle, spliTitle: false });
				scrollToBottom();
			}, 1000);
		} catch (error) {
			toastr.error(
				`Something went wrong while creating new task. Please try again later. ` + error
			);
		}
	};

	const focusInput = (item_id, htmlInputRef, position = 0) => {
		// Set up an interval to repeatedly check if the HTML input element is available
		var interval = setInterval(() => {
			if (htmlInputRef[item_id].current) {
				// Set the selection start and end position of the input element
				htmlInputRef[item_id].current.selectionStart = htmlInputRef[item_id].current.selectionEnd =
					position;
				// Set focus on the input element
				htmlInputRef[item_id].current.focus();
				// Clear the interval since the input element is now available
				clearInterval(interval);
			}
		}, 10);
	};

	const updateInputRef = (items, item_id = null) => {
		const start = performance.now();
		// Create an array to store the input refs
		var refs = [];

		// Iterate over the items and create a ref for each item
		items.forEach(item => {
			refs[item._id] = createRef();
		});

		// If an item_id is provided, focus the input element for that item
		if (item_id) {
			focusInput(item_id, refs);
		}

		// Set the input refs in the state
		setInputRef(refs);
		const end = performance.now();
		console.log(`updateInputRef took ${end - start} milliseconds`);
	};

	const collapseTasks = (todo_ids, collapse) => {
		axios
			.post(`${process.env.REACT_APP_LARAVEL_API_URL}/task/collapse`, {
				collapse,
				todo_ids
			})
			.then(res => {
				if (res.data.success === true) {
				} else {
					console.log(res.data);
				}
			})
			.catch(error => {
				console.error(error);
			});
	};

	const updateParentId = payload => {
		axios
			.post(`${process.env.REACT_APP_LARAVEL_API_URL}/task/update-parent`, payload, {
				// headers: {
				// 	Authorization: `Bearer ${token}`,
				// 	'Access-Control-Allow-Origin': '*'
				// }
			})
			.then(res => {
				if (res.data.success === true) {
				} else {
					console.log(res.data);
				}
			})
			.catch(error => {
				console.error(error);
			});
	};

	const generateObjectId = () => {
		const id = new ObjectID();
		const objectid = id.toString();

		if ([...allIds].includes(objectid)) {
			return generateObjectId();
		}

		return objectid;
	};

	const addTodo = async ({ item, newTitle = '', spliTitle = true }) => {
		const start = performance.now();
		let _newTitle = newTitle;
		let oldTitle = item.title;

		if (spliTitle) {
			let position = inputRef[item._id].current.selectionStart;
			_newTitle = item.title.slice(position, item.title.length);
			oldTitle = item.title.slice(0, position);
		}

		let newItem = {
			...item,
			todo_id: item._id,
			value: oldTitle,
			title: oldTitle,
			hide_children: false
		};

		var temp = [...taskList];
		var index = temp.findIndex(function (value) {
			return value._id == newItem._id;
		});
		temp[index] = newItem;

		updateTaskOnBackend(newItem);
		let assginee = [...taskList].find(value => value._id === item.parent_id)?.assginee || 0;

		let newobj = {
			parent_id: item.parent_id,
			value: _newTitle,
			title: _newTitle,
			estimate: 0,
			assginee,
			completed: false,
			seq: item.seq + 1,
			worksheet_id: activeWorksheet,
			_id: generateObjectId()
		};

		setAllIds(prev => [...prev, newobj._id]);

		temp.push(newobj);

		temp.forEach(function (todo, index) {
			if (todo.parent_id === item._id) {
				temp[index].parent_id = newobj._id;
			}
		});

		updateParentId({ old_parent_id: item._id, new_parent_id: newobj._id });
		setTasks(temp);
		setTaskList(temp);
		// updateSideBar(temp);

		updateParentStatus(temp, newobj.parent_id);

		updateInputRef(temp, newobj._id);
		addTodoApi(newobj);
		const end = performance.now();
		console.log(`addTodo took ${end - start} milliseconds`);
	};

	const addTodoApi = newobj => {
		axios
			.post(`${process.env.REACT_APP_LARAVEL_API_URL}/task`, newobj, {
				// headers: {
				// 	Authorization: `Bearer ${token}`,
				// 	'Access-Control-Allow-Origin': '*'
				// }
			})
			.then(res => {
				if (res.data.success === true) {
				} else {
					console.log(res.data);
				}
			})
			.catch(error => {
				console.error(error);
			});
	};

	const updateBudget = (newItem, taskListCopy) => {
		var todo = newItem;

		while (todo.parent_id !== '') {
			//not a first level task

			var immediateParent = taskListCopy.filter(function (value) {
				return todo.parent_id === value._id;
			})[0];

			var immediateIncompleteChildren = taskListCopy.filter(function (value) {
				return immediateParent._id === value.parent_id && !value.completed;
			});

			if (immediateIncompleteChildren.length > 0) {
				immediateParent.estimate = immediateIncompleteChildren
					.map(value => value.estimate)
					.reduce((prev, next) => prev + next);
			} else {
				immediateParent.estimate = 0;
			}

			//for backend payload structure compatibility
			immediateParent.value = immediateParent.title;
			immediateParent.todo_id = immediateParent._id;

			var index = taskListCopy.findIndex(function (value) {
				return value._id === immediateParent._id;
			});
			taskListCopy[index] = immediateParent;

			updateTaskOnBackend(immediateParent);

			todo = immediateParent;
		}

		setTasks(taskListCopy);
		setTaskList(taskListCopy);
		// updateSideBar(taskListCopy);
	};

	const getParentTitle = () => {
		const parent = tasks.filter(task => task._id === selectedTask?.parent_id);
		return parent[0]?.title;
	};

	const updateBudgetByParent = (tempTasks, parent_id) => {
		if (parent_id !== '') {
			var todo = tempTasks.filter(function (value) {
				return parent_id === value.parent_id;
			})[0];
			while (todo && todo.parent_id !== '') {
				var parent = tempTasks.filter(function (value) {
					return todo.parent_id === value._id; //&& !value.completed
				})[0];

				var childTasks = tempTasks.filter(function (value) {
					return parent._id === value.parent_id;
				});

				if (childTasks.length > 0) {
					parent.estimate = childTasks
						.map(value => value.estimate)
						.reduce((prev, next) => prev + next);
				}
				parent.value = parent.title;
				parent.todo_id = parent._id;

				var index = tempTasks.findIndex(function (value) {
					return value._id === parent._id;
				});
				tempTasks[index] = parent;

				updateTaskOnBackend(parent);
				todo = tempTasks.filter(function (value) {
					return parent.parent_id === value._id;
				})[0];
			}

			setTasks(tempTasks);
			setTaskList(tempTasks);
			// updateSideBar(tempTasks);
		}
	};

	const updateSequence = (item, updatedTaskList = [...tasks]) => {
		const start = performance.now();
		var afterTasks = updatedTaskList
			.filter(value => {
				return value.seq > item.seq;
			})
			.sort((a, b) => a.seq - b.seq);

		var payload = {};
		var seq = item.seq + 2;

		afterTasks.forEach(function (item) {
			payload[item._id] = seq;

			var index = updatedTaskList.findIndex(function (value) {
				return value._id == item._id;
			});
			updatedTaskList[index].seq = seq;
			seq++;
		});

		updateSequenceApi(payload);
		setTasks(updatedTaskList);
		setTaskList(updatedTaskList);
		// updateSideBar(updatedTaskList);
		const end = performance.now();

		console.log(`updateSequence took ${end - start} milliseconds`);
	};

	const updateSequenceAfterOutdent = (temp, oldItem, newItem) => {
		var allChildOfItems = getChildren(oldItem._id, temp);
		var allChildOfParent = getChildren(oldItem.parent_id, temp);

		if (allChildOfItems.length > 0) {
			var tasks = temp.filter(value => {
				return (
					value.seq > Math.max(...allChildOfItems.map(o => o.seq)) &&
					value.seq <= Math.max(...allChildOfParent.map(o => o.seq))
				);
			});
		} else {
			var tasks = temp.filter(value => {
				return (
					value.seq > oldItem.seq && value.seq <= Math.max(...allChildOfParent.map(o => o.seq))
				);
			});
		}
		var alltasks = temp;
		var payload = {};
		tasks.forEach(task => {
			payload[task._id] = task.seq - allChildOfItems.length - 1;

			var index = alltasks.findIndex(function (value) {
				return value._id == task._id;
			});
			alltasks[index].seq = task.seq - allChildOfItems.length - 1;
		});

		payload[newItem._id] = newItem.seq + tasks.length;
		var index = alltasks.findIndex(function (value) {
			return value._id == newItem._id;
		});
		alltasks[index].seq = newItem.seq + tasks.length;

		allChildOfItems.forEach(task => {
			payload[task._id] = task.seq + tasks.length;

			var index = alltasks.findIndex(function (value) {
				return value._id == task._id;
			});
			alltasks[index].seq = task.seq + tasks.length;
		});

		// temp[parentIndex].seq = start_point;
		// payload[temp[parentIndex]._id] = start_point;
		// var alltasks = temp;

		// var seq = start_point + 1;

		// ltasks.forEach(function (childitem) {
		// 	payload[childitem._id] = seq;

		// 	var index = alltasks.findIndex(function (value) {
		// 		return value._id == childitem._id;
		// 	});
		// 	alltasks[index].seq = seq;
		// 	seq++;
		// });

		// var index = alltasks.findIndex(function (value) {
		// 	return value._id == item._id;
		// });
		// alltasks[index].seq = seq;
		updateSequenceApi(payload);
		setTasks(alltasks);
		setTaskList(alltasks);
		// updateSideBar(alltasks);
	};

	const getChildren = (item_id, alltasks) => {
		let children = [];

		let childs = alltasks.filter(value => {
			return value.parent_id === item_id;
		});

		if (childs.length > 0) {
			children = [...children, ...childs];
			for (var i = 0; i < childs.length; i++) {
				children = [...children, ...getChildren(childs[i]._id, alltasks)];
			}
		}
		return children;
	};

	const updateSequenceApi = payload => {
		if (Object.keys(payload).length !== 0) {
			axios
				.post(
					`${process.env.REACT_APP_LARAVEL_API_URL}/task/update-seq`,
					{ data: payload },
					{
						// headers: {
						// 	Authorization: `Bearer ${token}`,
						// 	'Access-Control-Allow-Origin': '*'
						// }
					}
				)
				.then(res => {
					if (res.data.success === true) {
					} else {
						console.log(res.data);
					}
				})
				.catch(error => {
					console.error(error);
				});
		}
	};

	const deleteTodo = itemids => {
		axios
			.post(
				`${process.env.REACT_APP_LARAVEL_API_URL}/task/delete`,
				{ itemids: itemids },
				{
					// headers: {
					// 	Authorization: `Bearer ${token}`,
					// 	'Access-Control-Allow-Origin': '*'
					// }
				}
			)
			.then(res => {
				if (res.data.success === true) {
				} else {
					console.log(res.data);
				}
			})
			.catch(error => {
				console.error(error);
			});
	};

	const removeTask = (tasks, task_id) => {
		let childTasks = tasks.filter(task => task.parent_id === task_id);
		let updatedTasks = tasks.filter(task => task._id !== task_id);

		childTasks.forEach(childTask => {
			updatedTasks = removeTask(updatedTasks, childTask._id);
		});

		return updatedTasks;
	};

	const handleDeleteTodo = todo_id => {
		if (!window.confirm('Are you sure you want to delete the tasks and its subtasks?')) {
			return 0;
		}

		var item = [...tasks].find(r => r._id === todo_id);
		var alltasks = removeTask([...tasks], todo_id);

		var ltasks = alltasks
			.filter(value => {
				return value.seq > item.seq;
			})
			.sort((a, b) => a.seq - b.seq);

		var payload = {};
		var seq = item.seq;

		ltasks.forEach(function (item) {
			payload[item._id] = seq;

			var index = alltasks.findIndex(function (value) {
				return value._id == item._id;
			});
			alltasks[index].seq = seq;
			seq++;
		});

		// if(!addNewTaskIfEmpty(alltasks)){
		updateParentStatus(alltasks, item.parent_id);
		updateSequenceApi(payload);
		setTasks(alltasks);
		setTaskList(alltasks);
		// }
		deleteTodoWithChilds(todo_id);
	};

	const addNewTaskIfEmpty = alltasks => {
		if (alltasks.length === 0) {
			let task_id = generateObjectId();
			let newTask = {
				parent_id: '',
				value: '',
				title: '',
				estimate: 0,
				assginee: 0,
				completed: false,
				description: '',
				seq: 1,
				worksheet_id: activeWorksheet,
				_id: task_id
			};
			addTodoApi(newTask);
			setTasks([newTask]);
			setTaskList([newTask]);

			return true;
		}
		return false;
	};

	const deleteTodoWithChilds = item => {
		toastr.clear();

		axios
			.delete(`${process.env.REACT_APP_LARAVEL_API_URL}/task/${item}`, {
				// headers: {
				// 	Authorization: `Bearer ${token}`,
				// 	'Access-Control-Allow-Origin': '*'
				// }
			})
			.then(res => {
				if (res.data.success === true) {
					setTimeout(
						() => toastr.success(`Tasks deleted successfully. Please try again later`),
						300
					);
				} else {
					console.log(res.data);
					setTimeout(
						() => toastr.error(`Something went wrong while deleting tasks. Please try again later`),
						300
					);
				}
			})
			.catch(error => {
				console.error(error);
				setTimeout(
					() => toastr.error(`Something went wrong while deleting tasks. Please try again later`),
					300
				);
			});
	};

	const changeEstimate = (item, direction, isTaskDetailsPopup = false, estimates = 0) => {
		//fetch child tasks

		changeEstimateUtil(
			item,
			direction,
			isTaskDetailsPopup,
			estimates,
			taskList,
			setTasks,
			selectedTask,
			updateTaskOnBackend,
			updateBudget,
			setSelectedTask,
			setTaskList,
			calculateEstimate
		);

		// changeEstimate logic moved to changeEstimateUtil in utils folder
		// var childTasks = taskList.filter(function (todo) {
		// 	return item._id === todo.parent_id;
		// });

		// console.log(isTaskDetailsPopup );
		// console.log(estimates);

		// //no child tasks
		// if (childTasks.length === 0) {
		// 	let updatedItem = {
		// 		...item,
		// 		todo_id: item._id,
		// 		value: item.title,
		// 		estimate: isTaskDetailsPopup ? estimates : calculateEstimate(direction, item.estimate),
		// 		estimation_by: user._id
		// 	};

		// 	var taskListCopy = [...taskList];
		// 	var index = taskListCopy.findIndex(function (value) {
		// 		return value._id == updatedItem._id;
		// 	});
		// 	taskListCopy[index] = updatedItem;

		// 	updateTaskOnBackend(updatedItem);
		// 	setTasks(taskListCopy);
		// 	setTaskList(taskListCopy);

		// 	updateBudget(updatedItem, taskListCopy);
		// 	if (selectedTask) {
		// 		setSelectedTask({
		// 			...selectedTask,
		// 			todo_id: item._id,
		// 			value: item.title,
		// 			estimate: isTaskDetailsPopup ? estimates : calculateEstimate(direction, item.estimate),
		// 			estimation_by: user._id
		// 		});
		// 	}
		// } else {
		// 	toastr.clear();
		// 	setTimeout(
		// 		() =>
		// 			toastr.warning(
		// 				`Add budget to child entries, the budget of current item will be auto calculated. `
		// 			),
		// 		300
		// 	);
		// }
	};

	const modifyTaskinTaskList = item => {
		//makea copy
		var taskListCopy = [...taskList];

		//lookup the item to modify
		var index = taskListCopy.findIndex(function (value) {
			return value._id == item._id;
		});

		//replace that item
		taskListCopy[index] = item;

		return taskListCopy;
	};

	const setNewCaretPosition = (direction, item) => {
		console.log('this is called');
		if (item.seq === 1 && direction == 'up') {
			var xPosition = 0;
		} else if (item.seq >= [...taskList].length && direction == 'down') {
			var xPosition = item.title.length;
		} else {
			var xPosition = inputRef[item._id].current.selectionStart;
		}

		if (direction == 'up') var newItem = getPrevItem(item);
		else if (direction == 'down') var newItem = getNextItem(item);

		inputRef[newItem._id].current.selectionStart = inputRef[newItem._id].current.selectionEnd =
			xPosition;
		inputRef[newItem._id].current.focus();
	};

	const checkAddKey = (e, item, prevItemId, grandParentId) => {
		const start = performance.now();
		setOpenUser(false);

		if (e.which === 9) {
			//tab key
			e.preventDefault();
		}

		if (e.key === 'Enter') {
			//13 == enter
			e.preventDefault();

			updateSequence(item);

			addTodo({ item });
			setTodo('');
			setBudget(0);
		} else if (e.ctrlKey && e.which === 40) {
			//down arrow key, reduce estimates
			changeEstimate(item, 'down');
			console.log(item);
		} else if (e.ctrlKey && e.which === 38) {
			//top arrow key. increase estimate
			changeEstimate(item, 'up');
		} else if (e.shiftKey && e.key === 'Tab') {
			//outdenting
			e.preventDefault();

			var parent_id = item.parent_id;

			if (grandParentId) {
				var assginee = [...taskList].find(value => value._id === grandParentId).assginee;
			} else {
				var assginee = 0;
			}

			let newItem = {
				...item,
				todo_id: item._id,
				value: item.title,
				parent_id: grandParentId,
				assginee: assginee
			};
			// var temp = [...taskList];

			let updatedTaskList = modifyTaskinTaskList(newItem);

			updateTaskOnBackend(newItem);

			updateBudget(newItem, updatedTaskList);
			updateBudgetByParent(updatedTaskList, parent_id);
			updateParentStatus(updatedTaskList, parent_id);

			focusInput(newItem._id, inputRef);

			updateSequenceAfterOutdent(updatedTaskList, item, newItem);
		} else if (e.key === 'Tab' && !e.shiftKey) {
			//indent
			e.preventDefault();

			var childTasks = [...taskList].filter(value => {
				return value.parent_id === prevItemId;
			});

			if (childTasks.length === 0 && item.estimate === 0) {
				var index = [...taskList].findIndex(function (value) {
					return value._id === prevItemId;
				});
				var estimate = [...taskList][index].estimate;
			} else {
				var estimate = item.estimate;
			}

			var parent_id = item.parent_id;
			let parent = [...taskList].find(value => value._id === prevItemId);
			let newItem = {
				...item,
				todo_id: item._id,
				value: item.title,
				parent_id: prevItemId,
				estimate: estimate,
				assginee: parent.assginee
			};

			let updatedTaskList = modifyTaskinTaskList(newItem);

			updateTaskOnBackend(newItem);

			updateBudgetByParent(updatedTaskList, parent_id);
			updateBudget(newItem, updatedTaskList);
			updateParentStatus(updatedTaskList, newItem.parent_id);

			focusInput(newItem._id, inputRef);
		} else if (e.which === 38 && !e.ctrlKey) {
			//up key

			e.preventDefault();

			setNewCaretPosition('up', item);

			// if (e.shiftKey) {
			// 	setSelectedItems(prev => [...prev, item._id]);
			// }
		} else if (e.which === 40 && !e.ctrlKey && !e.shiftKey) {
			//down key

			e.preventDefault();

			setNewCaretPosition('down', item);
		} else if (e.key === 'Backspace' && !e.ctrlKey && !e.shiftKey && e.key !== 'Tab') {
			//backspace

			var start_position = inputRef[item._id].current.selectionStart;
			var end_position = inputRef[item._id].current.selectionEnd;

			if (start_position !== end_position) {
				// some text selected
				e.preventDefault();

				let newItem = {
					...item,
					todo_id: item._id,
					value: item.title.substr(0, start_position) + item.title.substr(end_position),
					title: item.title.substr(0, start_position) + item.title.substr(end_position)
				};

				let updatedTaskList = modifyTaskinTaskList(newItem);

				updateTaskOnBackend(newItem);
				setTasks(updatedTaskList);
				setTaskList(updatedTaskList);
				// updateSideBar(updatedTaskList);

				focusInput(newItem._id, inputRef, start_position);
			} else if (e.target.value.length === 0) {
				// caret is on a blank link
				//condition for removing the item after pressing backspace
				e.preventDefault();

				var newItem = [...taskList].filter(value => {
					return value._id === getPrevItem(item)._id;
				})[0];

				var taskListCopy = [...taskList].filter(value => {
					return value._id !== item._id;
				});

				taskListCopy = taskListCopy
					.sort((a, b) => a.seq - b.seq)
					.map((value, index) => {
						value.seq = index + 1;
						if (value.parent_id == item._id) {
							value.parent_id = prevItemId;
						}
						return value;
					});

				// if(!addNewTaskIfEmpty(temp)){
				setTasks(taskListCopy);
				setTaskList(taskListCopy);
				// updateSideBar(taskListCopy);
				updateBudgetByParent(taskListCopy, item.parent_id);
				updateParentStatus(taskListCopy, item.parent_id);
				updateBudget(newItem, taskListCopy);
				updateParentId({ old_parent_id: item._id, new_parent_id: newItem._id });

				focusInput(newItem._id, inputRef, newItem.title.length);

				// }

				deleteTodo([item._id]);
			} else if (
				// caret is at the start of a line with some text
				e.target.value.length !== 0 &&
				start_position === 0 &&
				end_position === start_position
			) {
				//condition for attaching the item to prev after pressing backspace

				var newItem = [...taskList].filter(value => {
					return value._id === getPrevItem(item)._id;
				})[0];
				var temp_position = newItem.title.length;

				newItem.title = newItem.title + item.title;
				newItem.value = newItem.title;
				newItem.todo_id = newItem._id;

				var taskListCopy = [...taskList].filter(value => {
					return value._id !== item._id;
				});

				taskListCopy = taskListCopy
					.sort((a, b) => a.seq - b.seq)
					.map((value, index) => {
						value.seq = index + 1;
						if (value.parent_id == item._id) {
							value.parent_id = prevItemId;
						}
						return value;
					});

				// if(!addNewTaskIfEmpty(temp)){
				setTasks(taskListCopy);
				setTaskList(taskListCopy);
				// updateSideBar(taskListCopy);

				updateTaskOnBackend(newItem);
				updateBudgetByParent(taskListCopy, newItem.parent_id);
				updateParentStatus(taskListCopy, newItem._id);
				updateBudget(newItem, taskListCopy);

				focusInput(newItem._id, inputRef, temp_position);

				deleteTodo([item._id]);
			} else if (
				// when assignee name is erased
				item.assginee !== '' &&
				item.assginee !== 0 &&
				item.assginee !== null &&
				item.assginee
			) {
				let assginee = [...worksheetAccessTeam].filter(value => {
					return value._id === item.assginee;
				})[0];

				let newValue = e.target.value;
				if (
					newValue.slice(start_position - assginee.name.length, start_position) === assginee.name
				) {
					e.preventDefault();

					let newItem = {
						...item,
						todo_id: item._id,
						assginee: 0,
						value:
							item.title.substr(0, start_position - assginee.name.length - 1) +
							item.title.substr(start_position),
						title:
							item.title.substr(0, start_position - assginee.name.length - 1) +
							item.title.substr(start_position)
					};

					let updatedTaskList = modifyTaskinTaskList(newItem);

					updateAssigneOfChild(newItem, updatedTaskList);

					updateTaskOnBackend(newItem);
					setTasks(updatedTaskList);
					setTaskList(updatedTaskList);
					// updateSideBar(updatedTaskList);

					focusInput(newItem._id, inputRef, start_position - assginee.name.length - 1);
				}
			}
		} else if (e.ctrlKey && e.which === 37) {
			e.preventDefault();
			if (
				[...taskList].filter(function (childItem) {
					return childItem.parent_id === item._id;
				}).length > 0
			) {
				handleHideChildren(item, true);
			}
		} else if (e.ctrlKey && e.which === 39) {
			e.preventDefault();
			if (
				[...taskList].filter(function (childItem) {
					return childItem.parent_id === item._id;
				}).length > 0
			) {
				handleHideChildren(item, false);
			}
		} else if (e.ctrlKey && e.which === 77) {
			e.preventDefault();
			handleOpenMoveTaskModal(item);
		} else if (e.ctrlKey && e.which === 68) {
			e.preventDefault();
			handleDeleteTodo(item._id);
		} else if ((e.ctrlKey && e.which === 73) || (e.ctrlKey && e.which === 81)) {
			e.preventDefault();
			handleOpenTaskDetailsModal(item);
			setShowDescBox(true);
		} else if (e.ctrlKey && e.which === 69) {
			e.preventDefault();
			handleOpenTaskCommentModal(item, 'escalation');
		} else if (e.ctrlKey && e.which === 65) {
			e.preventDefault();
			handleOpenTaskCommentModal(item, 'approve');
		} else if (e.ctrlKey && e.which === 70) {
			e.preventDefault();
			handleOpenTaskCommentModal(item, 'followup');
		} else if (e.ctrlKey && e.which === 74) {
			e.preventDefault();
			setOpenSpotlightModal(true);
		} else if (e.which === 27) {
			e.preventDefault();
			setOpenSpotlightModal(false);
		} else if (e.ctrlKey && e.which === 67 && user?.role === 'owner') {
			e.preventDefault();
			setOpenTeamsNotificationClear(true);
		}
		const end = performance.now();

		console.log(`checkAddKey took ${end - start} milliseconds`);
	};

	const outdentTask = () => {
		const input = inputRef[selectedTask?._id];

		if (input?.current) {
			var parent_id = selectedTask?.parent_id;

			if (grandParentTask) {
				var assginee = [...taskList].find(value => value._id === grandParentTask).assginee;
			} else {
				var assginee = 0;
			}

			let newItem = {
				...selectedTask,
				todo_id: selectedTask?._id,
				value: selectedTask?.title,
				parent_id: grandParentTask,
				assginee: assginee
			};
			// var temp = [...taskList];

			let updatedTaskList = modifyTaskinTaskList(newItem);

			updateTaskOnBackend(newItem);

			updateBudget(newItem, updatedTaskList);
			updateBudgetByParent(updatedTaskList, parent_id);
			updateParentStatus(updatedTaskList, parent_id);

			focusInput(newItem._id, inputRef);

			updateSequenceAfterOutdent(updatedTaskList, selectedTask, newItem);
		} else {
			toastr.error('Cannot indent this task...');
		}
	};

	const indentTask = () => {
		const input = inputRef[selectedTask?._id];

		if (input?.current) {
			var childTasks = [...taskList].filter(value => {
				return value.parent_id === prevTask;
			});

			if (childTasks.length === 0 && selectedTask?.estimate === 0) {
				var index = [...taskList].findIndex(function (value) {
					return value._id === prevTask;
				});
				var estimate = [...taskList][index].estimate;
			} else {
				var estimate = selectedTask?.estimate;
			}

			var parent_id = selectedTask?.parent_id;
			let parent = [...taskList].find(value => value._id === prevTask);
			let newItem = {
				...selectedTask,
				todo_id: selectedTask?._id,
				value: selectedTask?.title,
				parent_id: prevTask,
				estimate: estimate,
				assginee: parent.assginee
			};

			let updatedTaskList = modifyTaskinTaskList(newItem);

			updateTaskOnBackend(newItem);

			updateBudget(newItem, updatedTaskList);
			updateBudgetByParent(updatedTaskList, parent_id);
			updateParentStatus(updatedTaskList, newItem.parent_id);

			focusInput(newItem._id, inputRef);
		} else {
			toastr.error('Cannot indent this task...');
		}
	};
	const increaseIndent = (e, item, prevItemId) => {
		console.log(item);
		//indent
		e.preventDefault();

		var childTasks = [...taskList].filter(value => {
			return value.parent_id === prevItemId;
		});

		if (childTasks.length === 0 && item.estimate === 0) {
			var index = [...taskList].findIndex(function (value) {
				return value._id === prevItemId;
			});
			var estimate = [...taskList][index].estimate;
		} else {
			var estimate = item.estimate;
		}

		var parent_id = item.parent_id;
		let parent = [...taskList].find(value => value._id === prevItemId);
		let newItem = {
			...item,
			todo_id: item._id,
			value: item.title,
			parent_id: prevItemId,
			estimate: estimate,
			assginee: parent.assginee
		};
		// console.log(newItem)

		let updatedTaskList = modifyTaskinTaskList(newItem);

		updateTaskOnBackend(newItem);

		updateBudgetByParent(updatedTaskList, parent_id);
		updateBudget(newItem, updatedTaskList);
		updateParentStatus(updatedTaskList, newItem.parent_id);

		focusInput(newItem._id, inputRef);
	};
	const decreaseIndent = (e, item, grandParentId) => {
		e.preventDefault();

		var parent_id = item.parent_id;

		if (grandParentId) {
			var assginee = [...taskList].find(value => value._id === grandParentId).assginee;
		} else {
			var assginee = 0;
		}

		let newItem = {
			...item,
			todo_id: item._id,
			value: item.title,
			parent_id: grandParentId,
			assginee: assginee
		};
		// var temp = [...taskList];

		let updatedTaskList = modifyTaskinTaskList(newItem);

		updateTaskOnBackend(newItem);

		updateBudget(newItem, updatedTaskList);
		updateBudgetByParent(updatedTaskList, parent_id);
		updateParentStatus(updatedTaskList, parent_id);

		focusInput(newItem._id, inputRef);

		updateSequenceAfterOutdent(updatedTaskList, item, newItem);
	};

	const getNextItem = item => {
		let seq = item.seq + 1;
		let todos = [...taskList];
		let newSeq;
		loop1: for (var i = seq; i <= todos.length; i++) {
			var currentTodo = todos.filter(value => {
				return value.seq === i;
			})[0];
			let parents = getParentIds(currentTodo);
			loop3: for (var j = 0; j < parents.length; j++) {
				var parent = [...taskList].filter(value => {
					return value._id === parents[j];
				})[0];
				if (parent.hide_children) {
					continue loop1;
				}
			}
			if (hideCompleted && currentTodo.completed) {
				continue;
			}
			if (hideCompleted) {
				loop2: for (var j = 0; j < parents.length; j++) {
					var parent = [...taskList].filter(value => {
						return value._id === parents[j];
					})[0];
					if (parent.completed) {
						continue loop1;
					}
				}
			}
			newSeq = i;
			break;
		}
		// console.log(newSeq);
		if (!newSeq) {
			return item;
		}

		return todos.filter(value => {
			return value.seq === newSeq;
		})[0];
	};

	const getPrevItem = item => {
		let seq = item.seq - 1;
		let todos = [...taskList];
		let newSeq;
		loop1: for (var i = seq; i > 0; i--) {
			var currentTodo = todos.filter(value => {
				return value.seq === i;
			})[0];
			let parents = getParentIds(currentTodo);

			loop3: for (var j = 0; j < parents.length; j++) {
				var parent = [...taskList].filter(value => {
					return value._id === parents[j];
				})[0];
				if (parent.hide_children) {
					continue loop1;
				}
			}
			if (hideCompleted && currentTodo.completed) {
				continue;
			}
			if (hideCompleted) {
				loop2: for (var j = 0; j < parents.length; j++) {
					var parent = [...taskList].filter(value => {
						return value._id === parents[j];
					})[0];
					if (parent.completed) {
						continue loop1;
					}
				}
			}
			newSeq = i;
			break;
		}
		if (!newSeq) {
			return item;
		}

		return todos.filter(value => {
			return value.seq === newSeq;
		})[0];
	};

	const getParentIds = item => {
		let parentids = [];

		let parent_id = item._id;

		while (parent_id != '') {
			parent_id = getParentId(parent_id);
			if (parent_id != '') {
				parentids.push(parent_id);
			}
		}

		return parentids;
	};

	const getParentId = itemid => {
		var item = [...taskList].find(value => {
			return value._id === itemid;
		});
		return item.parent_id;
	};

	const handleHideChildren = (item, status) => {
		// Create a new item object with updated hide_children status
		let newItem = {
			...item,
			todo_id: item._id,
			value: item.title,
			hide_children: status
		};

		// Create a copy of taskList
		var temp = [...taskList];

		// Find the index of the item in the temp array
		var index = temp.findIndex(function (value) {
			return value._id == newItem._id;
		});

		// Update the item at the found index with the new item
		temp[index] = newItem;

		// Update the tasks and taskList states with the updated array
		setTasks(temp);
		setTaskList(temp);

		// Update the item on the backend
		updateTaskOnBackend(newItem);
	};

	const ChangeHandler = e => {
		setTodo(e.target.value);
	};

	const updates = useRef([]);
	const timeoutId = useRef([]);
	const mainContainerRef = useRef(null);
	const listRef = useRef(null);
	const firstCommentRef = useRef([]);

	const scrollToTop = () => {
		if (mainContainerRef.current) {
			mainContainerRef.current.scrollTop = 0;
		}
	};
	// const scrollToBottom = () => {
	// 	const returnLastTask = parent => {
	// 		if (parent.childNodes && parent.childNodes.length) {
	// 			if (parent.childNodes[parent.childNodes.length - 1].classList.contains('taskItem')) {
	// 				return returnLastTask(parent.childNodes[parent.childNodes.length - 1]);
	// 			} else if (
	// 				Array.from(parent.childNodes[parent.childNodes.length - 1].childNodes).find(item =>
	// 					item.classList.contains('taskParent')
	// 				)
	// 			) {
	// 				return returnLastTask(
	// 					Array.from(parent.childNodes[parent.childNodes.length - 1].childNodes).find(item =>
	// 						item.classList.contains('taskParent')
	// 					)
	// 				);
	// 			} else {
	// 				return parent;
	// 			}
	// 		} else {
	// 			return parent;
	// 		}
	// 	};

	// 	if (listRef.current) {
	// 		const lastTask = returnLastTask(listRef.current);
	// 		console.log(lastTask);
	// 		lastTask?.scrollIntoView({ behavior: 'smooth' });
	// 	}
	// };
	const scrollToBottom = () => {
		if (mainContainerRef.current) {
			let scrollYDistance = mainContainerRef.current.scrollHeight;
			mainContainerRef.current.scrollTop = scrollYDistance;
		}
	};

	const scrollToFirstNotification = () => {
		// console.log(firstCommentRef);
		if (firstCommentRef.current.length) {
			firstCommentRef.current = firstCommentRef.current.filter(item => item !== null);
			firstCommentRef.current[firstCommentRef.current.length - 1].scrollIntoView({
				behavior: 'smooth',
				block: 'center',
				inline: 'start'
			});
		}
	};

	useEffect(() => {
		firstCommentRef.current = [];
	}, [activeWorksheet]);

	const updateTaskOnBackend = useDebouncedCallback(
		newItem => {
			updateTaskApi(newItem);
		},
		1000,
		updates,
		timeoutId
	);

	//update task
	const updateTaskApi = newItem => {
		let item = [...taskList].find(r => r._id === newItem._id);
		toastr.clear();
		const body = {
			...newItem,
			hide_children: newItem.hide_children !== null ? newItem.hide_children : false
		};
		axios
			.put(`${process.env.REACT_APP_LARAVEL_API_URL}/task/${newItem.todo_id}`, body, {
				// headers: {
				// 	Authorization: `Bearer ${token}`,
				// 	'Access-Control-Allow-Origin': '*'
				// }
			})
			.then(res => {
				if (res.data.success === true) {
					if (newItem.description !== null && item.description !== newItem.description) {
						setTimeout(() => toastr.success(`Description updated successfully. `), 300);
					}
					setUpdatingTaskDataStatus({
						status: UPDATING_TASK_DATA_STATUS.COMPLETE,
						taskId: item._id
					});
					setTimeout(() => {
						setUpdatingTaskDataStatus({
							status: UPDATING_TASK_DATA_STATUS.IDLE,
							taskId: null
						});
					}, 1000);
				} else {
					setTimeout(
						() =>
							toastr.error(
								`Something went wrong while updating description. Please try again later`
							),
						300
					);
					setUpdatingTaskDataStatus({
						status: UPDATING_TASK_DATA_STATUS.IDLE,
						taskId: null
					});
				}
			})
			.catch(error => {
				console.error(error);
				setUpdatingTaskDataStatus({
					status: UPDATING_TASK_DATA_STATUS.IDLE,
					taskId: null
				});
				// setTimeout(
				// 	() =>
				// 		toastr.error(
				// 			`Something went wrong while updating description. Please try again later`
				// 		),
				// 	300
				// );
			});
	};

	//edit task title
	const EditHandler = useCallback(
		(e, item) => {
			const newValue = e.target.value;

			setUpdatingTaskDataStatus({
				status: UPDATING_TASK_DATA_STATUS.LOADING,
				taskId: item._id
			});

			setTaskList(prev => {
				var temp = [...prev];
				var existingItem = temp.find(function (value) {
					return value._id === item._id;
				});
				var index = temp.findIndex(function (value) {
					return value._id === item._id;
				});

				let newItem = {
					...existingItem,
					todo_id: item._id,
					value: newValue,
					title: newValue
				};

				temp[index] = newItem;

				updateTaskOnBackend(newItem);

				if (index === 0) {
					setWorksheetList(prev => {
						var worksheet_temp = [...prev];
						var worksheet_index = worksheet_temp.findIndex(function (value) {
							return value._id === activeWorksheet;
						});

						if (worksheet_temp[worksheet_index]) {
							worksheet_temp[worksheet_index].title_first = newValue;
						}
						return worksheet_temp;
					});
				}

				setTasks(temp);

				return temp;
			});
		},
		[activeWorksheet, setTaskList, setWorksheetList, updateTaskOnBackend]
	);

	//changing completion status
	const changeCompletion = item => {
		let newItem = {
			...item,
			todo_id: item._id,
			completed: !item.completed,
			value: item.title
		};
		updateTaskOnBackend(newItem);

		var temp = [...taskList];
		var index = temp.findIndex(function (value) {
			return value._id == newItem._id;
		});
		temp[index] = newItem;

		updateBudget(newItem, temp);

		setTasks(temp);
		setTaskList(temp);
		updateParentStatus(temp, item.parent_id);
		// updateSideBar(temp);
	};

	useEffect(() => {
		let total = 0;
		let completed = 0;
		if (taskList?.length !== 0) {
			taskList?.map(item => {
				let childCount = taskList.filter(function (childItem) {
					return childItem.parent_id === item._id;
				}).length;

				if ((item.completed || item.last_comment_type === 'submission') && childCount === 0) {
					completed += item.estimate;
				}
				if (childCount === 0) {
					total += item.estimate;
				}
			});
		}
		setTotalHours(total);
		setCompletedHours(completed);
	}, [taskList]);

	//setting up current task on task click
	const currentTask = item => {
		setActiveTask(item);
	};

	const setAssginee = (item, user) => {
		var parentids = getParentIds(item);

		for (var j = 0; j < parentids.length; j++) {
			var parent = [...taskList].find(value => {
				return value._id === parentids[j];
			});
			if (parent.assginee) {
				let parent_user = [...users].find(value => value._id === parent.assginee);

				setOpenUser(false);
				toastr.clear();
				setTimeout(
					() =>
						toastr.warning(
							`You cannot assign this task to ${user?.name}, its parent is already assigned to ${parent_user.name}.`
						),
					500
				);
				return;
			}
		}

		var title = item.title;

		// inputRef[newItem._id].current.selectionStart = inputRef[newItem._id].current.selectionEnd = position;
		// inputRef[newItem._id].current.focus();

		const assgineeId = user?._id || 0;

		let newItem = {
			...item,
			todo_id: item._id,
			value: title,
			title: title,
			assginee: assgineeId
		};

		setTaskList(prev => {
			var temp = [...prev];
			var index = temp.findIndex(function (value) {
				return value._id === newItem._id;
			});
			temp[index] = newItem;

			updateAssigneOfChild(newItem, temp);

			setTasks(temp);
			updateTaskOnBackend(newItem);
			return temp;
		});

		setTimeout(() => {
			focusInput(item._id, inputRef, inputRef[item._id]?.current?.value.length);
		}, 100);

		// updateSideBar(temp);
	};

	const updateAssigneOfChild = (item, temp) => {
		var child_tasks = temp.filter(function (value) {
			return value.parent_id == item._id;
		});

		if (child_tasks.length > 0) {
			child_tasks.forEach(element => {
				var index = temp.findIndex(function (value) {
					return value._id == element._id;
				});
				temp[index].assginee = item.assginee;
				temp[index].todo_id = element._id;
				temp[index].value = element.title;
				updateAssigneOfChild(element, temp);
				// updateTask(temp[index]);
			});
		}
		setTasks(temp);
		setTaskList(temp);
	};

	const handleWorksheetTitleChange = e => {
		var worksheet_temp = [...worksheetList];
		var worksheet_index = worksheet_temp.findIndex(function (value) {
			return value._id == activeWorksheet;
		});

		worksheet_temp[worksheet_index].title = e.target.value;
		setWorksheetTitle(e.target.value);
		setWorksheetList(worksheet_temp);

		axios
			.put(`${process.env.REACT_APP_LARAVEL_API_URL}/worksheet/${activeWorksheet}`, {
				title: e.target.value
			})

			.then(res => {
				if (res.data.success === true) {
				} else {
					console.log(res.data);
				}
			})
			.catch(error => {
				console.error(error);
			});
	};

	const handleRevokeAccess = (e, user_id) => {
		e.preventDefault();

		setWorksheetAccessTeam(prev => {
			return prev.filter(user => {
				if (user._id === user_id) {
					return false;
				}
				return true;
			});
		});

		axios
			.post(
				`${process.env.REACT_APP_LARAVEL_API_URL}/worksheet/${activeWorksheet}/access/revoke`,
				{
					user_ids: [user_id]
				},
				{
					// headers: {
					// 	Authorization: `Bearer ${token}`,
					// 	'Access-Control-Allow-Origin': '*'
					// }
				}
			)
			.then(res => {
				if (res.data.success === true) {
					toastr.clear();
					setTimeout(() => toastr.success(`Worksheet access revoked successfully `), 300);
				} else {
					console.log(res.data);
					toastr.clear();
					setTimeout(() => toastr.error(`Something went wrong while revoking access. `), 300);
				}
			})
			.catch(error => {
				console.error(error);
			});
	};

	const handleAddPeople = e => {
		e.preventDefault();
		let users = addPeopleValues.map(user => {
			let l = [...team].filter(u => {
				if (user.value === u._id) {
					return true;
				}
				return false;
			})[0];
			return l;
		});

		users = users.filter(_user => !!_user);
		setWorksheetAccessTeam(prev => [...prev, ...users]);

		axios
			.post(
				`${process.env.REACT_APP_LARAVEL_API_URL}/worksheet/${activeWorksheet}/access/allow`,
				{
					user_ids: addPeopleValues.map(user => user.value)
				},
				{
					// headers: {
					// 	Authorization: `Bearer ${token}`,
					// 	'Access-Control-Allow-Origin': '*'
					// }
				}
			)
			.then(res => {
				if (res.data.success === true) {
					setAddPeopleValues([]);

					toastr.clear();
					setTimeout(() => toastr.success(`Worksheet access added successfully `), 300);
				} else {
					toastr.clear();
					setTimeout(() => toastr.success(`Something went wrong while giving access.`), 300);
					console.log(res.data);
				}
			})
			.catch(error => {
				console.error(error);
			});
	};

	useEffect(() => {
		if (titleRef.current) {
			titleRef.current.rows = 1;

			const rowHeight = 25;
			const trows = Math.floor(titleRef.current?.scrollHeight / rowHeight);
			if (window.innerWidth <= 600) {
				titleRef.current.rows = trows;
			}
			setHeaderHeightSet(true);
		}
		// }
	}, [titleRef.current?.value, activeWorksheet]);

	const addComment = comment => {
		let tasks = [...taskList];
		tasks.forEach((value, index) => {
			if (value._id === comment.todo_id) {
				if (tasks[index].last_comment_type === 'submission') {
					if (comment.type === 'reopen' || comment.type === 'approve') {
						tasks[index].last_comment_type = comment.type;
					}
				} else {
					tasks[index].last_comment_type = comment.type;
				}

				tasks[index].comments_count = (value.comments_count || 0) + 1;

				if (comment.type === 'approve') {
					tasks[index].completed = true;
					updateParentStatus(tasks, value.parent_id);
				}

				if (comment.type === 'reopen') {
					tasks[index].completed = false;
					updateParentStatus(tasks, value.parent_id);
				}
			}
		});
		setTasks(tasks);
		setTaskList(tasks);

		const url = `${process.env.REACT_APP_LARAVEL_API_URL}/comment`;
		axios
			.post(url, comment)
			.then(res => {
				if (res.data.success) {
					toastr.clear();
					setTimeout(
						() =>
							toastr.success(
								`${comment.type[0].toUpperCase()}${comment.type
									.replace('_', ' ')
									.substring(1)} added successfully `
							),
						300
					);
				} else {
					getTodoList(activeWorksheet);
					toastr.clear();
					setTimeout(
						() => toastr.warning(`Something went wrong while commenting. Please try again `),
						300
					);
				}
			})
			.catch(e => {
				toastr.clear();
				setTimeout(
					() => toastr.warning(`Something went wrong while commenting. Please try again `),
					300
				);
			});
	};

	const moveTask = () => {
		axios
			.post(`${process.env.REACT_APP_LARAVEL_API_URL}/task/${selectedTask._id}/move`, {
				worksheet_id: moveToWorksheet._id
			})
			.then(res => {
				if (res.data.success) {
					getTodoList(activeWorksheet);
				} else {
					alert('Something went wrong while commenting. Please try again');
				}
			})
			.finally(() => setOpenMoveTaskModal(false));
	};

	const reopenParentTasks = (tasks, child_id) => {
		const childTask = tasks.find(task => task._id === child_id);

		if (childTask.parent_id) {
			const parentTask = tasks.find(task => task._id === childTask.parent_id);
			setTaskList(prev => {
				return prev.map(r => {
					if (r._id === parentTask._id) {
						r.completed = false;
					}
					return r;
				});
			});
			setTasks(prev => {
				return prev.map(r => {
					if (r._id === parentTask._id) {
						r.completed = false;
					}
					return r;
				});
			});
			reopenParentTasks(tasks, parentTask._id);
		}
	};

	const updateParentStatus = (tasks, parentId) => {
		// if (parentId) {
		// 	const children = tasks.filter((item) => item.parent_id === parentId);
		// 	const allChildrenCompleted = children.every((child) => child.completed);
		// 	const parent = tasks.find((item) => item._id === parentId);
		// 	if (parent) {
		// 		if(children.length > 0){
		// 			parent.completed = allChildrenCompleted;
		// 		}else{
		// 			parent.completed = parent.last_comment_type === 'approve';
		// 		}
		// 		parent.todo_id = parent._id;
		// 		parent.value = parent.title;
		// 		updateTaskOnBackend(parent);
		// 		setTaskList((prevState) =>
		// 			prevState.map((item) => (item._id === parentId ? parent : item))
		// 		);
		// 		setTasks((prevState) =>
		// 			prevState.map((item) => (item._id === parentId ? parent : item))
		// 		);
		// 		updateParentStatus(tasks, parent.parent_id);
		// 	}
		// }
	};

	useEffect(() => {
		dispatch(fetchPresetMessages());
		dispatch(fetchPresetTasks());
	}, [dispatch]);

	useEffect(() => {
		// Logic for toggling the visibility of the arrow icons
		let x = 0;

		//  Event listener on scroll for list items container. This is for changing the eopacity of the arrow icons at the top, middle and bottom scroll positions.
		const onScroll = e => {
			if (listRef.current.scrollTop > 20) {
				setUpArrowCLickable(true);
				if (
					listRef.current?.scrollHeight +
						headerRef.current?.clientHeight -
						window.innerHeight -
						10 <=
					listRef.current?.scrollTop
				) {
					setDownArrowClickable(false);
				}
				if (
					listRef.current?.scrollHeight +
						headerRef.current?.clientHeight -
						window.innerHeight -
						10 >
					listRef.current?.scrollTop
				) {
					setDownArrowClickable(true);
				}
			} else {
				setUpArrowCLickable(false);
			}
		};
		if (listRef.current) {
			// Checking if list container has scrollable content. If it does, then setting visiitlity of arrow icons to true.
			let intervalID = setInterval(function () {
				if (
					listRef?.current?.scrollHeight >
					window.innerHeight - headerRef?.current?.clientHeight
				) {
					setArrowsVisible(true);
					window.clearInterval(intervalID);
				} else {
					setArrowsVisible(false);
				}
				if (dataFetched.current === true) {
					window.clearInterval(intervalID);
				}
			}, 1000);

			listRef.current?.addEventListener('scroll', onScroll);
		}

		return () => {
			listRef.current?.removeEventListener('scroll', onScroll);
		};

		// Triggering this useeffect after header height is set, and on active worksheet change
	}, [headerHeightSet, activeWorksheet, dataFetched.current]);

	const searchTasks = (tasks, searchTerm) => {
		if (searchTerm !== '') {
			let results = [];
			searchTerm = searchTerm.toLowerCase();

			const search = task => {
				if (task.title.toLowerCase().includes(searchTerm)) {
					results.push(task._id);
					let parent = tasks.find(t => t._id === task.parent_id);
					while (parent) {
						results.push(parent._id);
						parent = tasks.find(t => t._id === parent.parent_id);
					}
				} else if (task?.description?.toLowerCase().includes(searchTerm)) {
					results.push(task._id);
					let parent = tasks.find(t => t._id === task.parent_id);
					while (parent) {
						results.push(parent._id);
						parent = tasks.find(t => t._id === parent.parent_id);
					}
				}
			};

			tasks.forEach(search);
			results = [...new Set(results)];
			setTaskList(tasks.filter(task => results.includes(task._id)));
		} else {
			setTaskList(tasks);
		}
	};

	useEffect(() => {
		searchTasks([...tasks], searchInput);
	}, [searchInput]);

	const updateTaskFromTaskDetailsModal = task => {
		setTaskList(prev => {
			return prev.map(r => {
				if (r._id === task._id) {
					return task;
				}
				return r;
			});
		});
		setTasks(prev => {
			return prev.map(r => {
				if (r._id === task._id) {
					return task;
				}
				return r;
			});
		});
	};

	const getNotificationCount = () => {
		const url = `${process.env.REACT_APP_LARAVEL_API_URL}/notification/count-comments/unread`;
		axios
			.get(url)
			.then(data => {
				setCommentCounts(data.data.data);
			})
			.catch(e => console.log(e));
	};

	const updateSearchedTasks = (searchedTasks, searchQueryLength) => {
		setSearchedTasks(searchedTasks);
		setSearchQueryLength(searchQueryLength);
	};

	useEffect(() => {
		getNotificationCount();
	}, [openTaskDetailsModal]);

	useEffect(() => {
		setStateSidebar(false);
	}, [activeWorksheet]);

	const useStyles = makeStyles({
		formControl: {
			marginRight: 0,
			paddingBottom: 0,
			fontSize: '13px'
		},
		root: {
			color: 'white',
			padding: '0px 9px',
			paddingBottom: '0px !important',
			'&$checked': {
				color: 'white'
			}
		},
		checked: {},
		label: {
			fontSize: '12px !important',
			lineHeight: '17px'
		},
		backDrop: {
			color: '#fff',
			backgroundColor: 'none',
			position: 'absolute',
			top: 0,
			left: 0,
			zIndex: 100,
			opacity: 0.5,
			height: '100%',
			width: '100%'
		}
	});
	const classes = useStyles();
	const tdpHeaderScroll = useRef(0);
	const [headerVisible, setHeaderVisible] = useState('0px');
	let timeout;

	const handleHeaderScroll = e => {
		const container = e.target;
		clearTimeout(timeout);

		timeout = setTimeout(() => {
			const scrollTop = container.scrollTop;

			if (scrollTop === 0) {
				setHeaderVisible('0px');
			} else if (scrollTop < tdpHeaderScroll.current) {
				setHeaderVisible('0px');
			} else {
				setHeaderVisible('-50px');
			}

			tdpHeaderScroll.current = scrollTop;
		}, 100);
	};
	const handleAddNewGroup = () => {
		if (newGroupName === '') {
			alert('Group name cannot be empty');
			return;
		}
		const data = {
			client_name: newGroupName
		};

		const apiUrl = `${process.env.REACT_APP_LARAVEL_API_URL}/client`;

		axios
			.post(apiUrl, data)
			.then(response => {
				console.log(response);
				if (response.status >= 200 && response.status < 300) {
					toastr.success('New Group Added succesfully');
					setWorksheetGroups(prev => [...prev, { client_name: newGroupName }]);
				}
			})
			.catch(e => {
				console.log(e);
			});
		setNewGroupName('');
		setIsOpenNewGroup(false);
	};
	const contains = (arr, el) => {
		let hasElement = false;
		arr?.forEach(item => {
			if (item?._id === el?._id) hasElement = true;
		});
		return hasElement;
	};
	const [currentTab, setCurrentTab] = useState('mainTasks');

	const tabs = {
		mainTasks: 'mainTasks',
		activity: 'activity',
		completion: 'completion'
	};
	const changeTabs = tabName => {
		setCurrentTab(tabName);
	};

	useEffect(() => {
		let recentlyVisitedWorksheets =
			JSON.parse(localStorage.getItem('recently_visited_worksheets')) || [];
		if (activeWorksheetData && !contains(recentlyVisitedWorksheets, activeWorksheetData)) {
			cacheWorksheetInRecentlyVisitedList(activeWorksheetData);
		}
	}, [activeWorksheetData]);

	// ================ scrollDown and scrollUp and scroll New comment ================

	useEffect(() => {
		function handleUpDown(event) {
			if (event.target.tagName.toLowerCase() === 'input' || openTaskDetailsModal) {
				return;
			}
			// event.preventDefault();
			if ((event.metaKey || event.shiftKey) && event.code === 'KeyZ') {
				scrollToBottom();
			} else if ((event.metaKey || event.shiftKey) && event.code === 'KeyX') {
				scrollToTop();
			} else if ((event.metaKey || event.shiftKey) && event.code === 'KeyC') {
				document.getElementById('bubblechat')?.click();
			}
		}
		document.addEventListener('keydown', handleUpDown);

		return () => {
			document.removeEventListener('keydown', handleUpDown);
		};
	}, [listRef, openTaskDetailsModal]);

	// All jsx here

	return (
		<Profiler id='ChoppingInterface' onRender={onRenderCallback}>
			<Helmet>
				<title>{`${
					commentCounts?.worksheets?.[activeWorksheet]
						? '[' + commentCounts?.worksheets?.[activeWorksheet] + ']'
						: ''
				} ${worksheetTitle}`}</title>
			</Helmet>
			<div className={`body_container worksheet_body`}>
				<div className={isWorksheetSidebarHidden ? 'd-none' : 'worksheet_left_sidebar'}>
					<WorksheetSidebar
						worksheetListTemp={worksheetList}
						activeWorksheet={activeWorksheet}
						sidebarActiveStatus={stateSidebar}
						setSidebarActive={setStateSidebar}
						notificationCount={notificationCount}
						handleWorksheetChange={handleWorksheetChange}
						createNewWorksheet={createNewWorksheet}
						updateSearchedTasks={updateSearchedTasks}
						setSearchedTasksLoading={setSearchedTasksLoading}
						setShouldScrollToNotification={setShouldScrollToNotification}
						scrollToFirstNotification={scrollToFirstNotification}
						metadataByWorksheetId={metadataByWorksheetId}
						changeTabs={changeTabs}
					/>
				</div>
				<div
					className={isWorksheetSidebarHidden ? 'worksheet_active' : 'worksheet_content'}
					style={currentTab === tabs.activity ? { minWidth: '79%' } : {}}
				>
					{/* <List */}
					{/* // sx={{ width: '100%', maxWidth: 360 }} */}
					{/* // component='nav' */}
					{/* // aria-labelledby='nested-list-subheader' */}
					{/* // > */}
					{/* <ListItemButton onClick={handleClick1}> */}
					{/* <ListItemIcon> */}
					{/* <InboxIcon /> */}
					{/* </ListItemIcon> */}
					{/* <ListItemText primary='Inbox' /> */}
					{/* {open ? <ExpandLess /> : <ExpandMore />} */}
					{/* </ListItemButton> */}
					{/* <Collapse in={open} timeout='auto' unmountOnExit> */}
					{/* <List component='div' disablePadding> */}
					{/* <ListItemButton sx={{ pl: 4 }}> */}
					{/* <ListItemText primary='Starred' /> */}
					{/* </ListItemButton> */}
					{/* </List> */}
					{/* </Collapse> */}
					{/* </List> */}
					<Backdrop className={classes.backDrop} open={loading || searchedTasksLoading}>
						<CircularProgress color='inherit' />
					</Backdrop>
					<div className={styles.progressbarContainer} style={{ color: 'white' }}>
						<LinearProgress
							variant='determinate'
							value={(100 * completedHours) / (totalHours === 0 ? 1 : totalHours)}
							color='inherit'
							sx={{
								height: '2px',
								background: '#565656'
							}}
							className={styles.progressbar}
						/>
					</div>
					<div
						style={
							currentTab === tabs.mainTasks ? { width: window.innerWidth < 600 ? '200%' : '' } : {}
						}
					>
						<div
							className={`${styles.listingsBody} `}
							ref={mainContainerRef}
							style={{
								position: 'relative',
								scrollBehavior: 'smooth',
								whiteSpace: currentTab === tabs.mainTasks ? 'nowrap' : 'wrap'
							}}
						>
							{/* <div> */}
							{/*================ right - upbar (nav) | start ====================== */}
							<CommonHeader
								position={window.innerWidth < 700 ? 'fixed' : 'sticky'}
								marginBottom={currentTab === tabs.mainTasks ? '32px' : '0px'}
								activeScreen={'worksheet'}
							>
								{/* <div> */}
								{/*================ right - upbar (nav) | start ====================== */}
								{searchQueryLength === 0 && (
									<div className={``} ref={headerRef}>
										<div
											style={{
												display: 'flex',
												justifyContent: 'flex-end',
												// alignItems: 'center',
												width: '100%'
											}}
										>
											{/* <div style={{ display: 'flex', width: '100%' }} className={styles.worksheetTitleContainer}> */}
											{activeWorksheet && (
												<div style={{ flexGrow: '1', display: 'flex' }}>
													<textarea
														ref={titleRef}
														type='text'
														placeholder='Worksheet Title'
														style={{
															background: 'transparent',
															color: 'white',
															border: 0,
															// fontFamily: 'Poppins',
															// fontSize: '14px',
															width: '90%',
															opacity: '100%',
															marginBottom: '0px',
															resize: 'none',
															outline: 'none'
														}}
														// rows={titleRows}
														className='sheet-title'
														value={worksheetTitle}
														// rows={window.innerWidth < 575 ? 2 : 1}
														onClick={() => {
															if (window.innerWidth < 600) setStateSidebar(true);
														}}
														readOnly={window.innerWidth < 600}
														onChange={e => handleWorksheetTitleChange(e)}
													></textarea>
													<div
														style={{ position: 'static' }}
														className={styles.worksheetDrawerOpener}
													>
														<ArrowDropDownIcon
															style={{ fontSize: '2em' }}
															onClick={() => setStateSidebar(true)}
														/>
													</div>
												</div>
											)}
											<div className={`px-0 d-flex justify-content-end`} style={{ height: '25px' }}>
												<div
													className='d-flex'
													style={{
														alignItems: 'center',
														justifyContent: 'center'
													}}
												>
													<SettingsIcon
														id='long-button'
														onClick={e => handleSettingsIconClick(e)}
														sx={{ cursor: 'pointer', fontSize: '20px' }}
													/>
													{/**<div 
											 * className={`col- px-0 mx-4 d-flex justify-content-end ${styles.listingsHeading} ${styles.footerEstimate}`}
							                   >
							                   	<span>
							                   		{((100 * completedHours) / (totalHours === 0 ? 1 : totalHours)).toFixed()}%{' '}
							                   	</span>{' '}
							                   	&nbsp; Completed
						                      </div>**/}

													{/**<Popupforhelp />**/}
												</div>
												<OpenInFullIcon
													style={{ marginLeft: '10px' }}
													className={styles.openicon}
													onClick={onToggleWorksheetSidebar}
												/>
											</div>
											{/* </div> */}
										</div>

										{/* ---- upper menue items for worksheet ---- */}
										<div
											className='header-tabs  align-items-center'
											style={{ width: '100%', marginTop: '20px' }}
										>
											<div className='header-left-contents'>
												{/* <span className={`px-3 ${styles.listingsHeading} text-normal`}>
												<span>{totalHours - completedHours}</span> hours of work
											</span> */}
												<span
													onClick={() => changeTabs(tabs.mainTasks)}
													className={`${currentTab === tabs.mainTasks && 'tab-active'}`}
												>
													Tasks
												</span>
												<span
													onClick={() => changeTabs(tabs.activity)}
													className={`${currentTab === tabs.activity && 'tab-active'}`}
													style={{ position: 'relative' }}
												>
													{/* <div
													style={{
														width: '13px',
														height: '13px',
														background: 'red',
														borderRadius: '100%',
														position: 'absolute',
														top: '0px',
														right: '-3px',
														color: 'white',
														display: 'flex',
														alignItems: 'center',
														justifyContent: 'center',
														fontSize: '10px',
														fontWeight: 'bold'
													}}
												>
													{commentCounts?.worksheets?.[activeWorksheet] ?? 0}
												</div> */}
													{/* <Link
													className='text-normal text-muted'
													to={`/chopping-interface/${activeWorksheet}/activity`}
													style={{ fontSize: '12px' }}
												>
													Activity
												</Link> */}
													{/* <button
														className='text-normal text-muted activity-button'
														style={{ fontSize: '12px', background: 'none', marginBottom: '0px', }}
														onClick={() => changeTabs(tabs.activity)}

													>
														Activity

													</button> */}
													Activity
												</span>
												<span
													className={`${currentTab === tabs.completion && 'tab-active'}`}
													onClick={() => changeTabs(tabs.completion)}
												>
													Completion
												</span>
												{currentTab === tabs.mainTasks && (
													<>
														<span
															className={`px-2 d-inline d-sm-none`}
															style={{ opacity: `${fadeListType === 'pending' && '1'}` }}
														>
															<span
																className='text-normal'
																// to={`/chopping-interface/${activeWorksheet}/completion/today`}
																style={{
																	fontSize: '12px',
																	cursor: 'pointer',
																	color:
																		fadeListType === 'pending'
																			? 'white'
																			: 'rgba(255, 255, 255, 0.6)',
																	opacity: 1
																}}
																onClick={() => {
																	setFadeListType(p => (p === 'pending' ? '' : 'pending'));
																}}
															>
																Pending
															</span>
														</span>
														<span className={`px-2 d-inline d-sm-none`}>
															<span
																className='text-normal'
																// to={`/chopping-interface/${activeWorksheet}/completion/today`}
																style={{
																	fontSize: '12px',
																	cursor: 'pointer',
																	opacity: 1,
																	color:
																		fadeListType === 'slowdown'
																			? 'white'
																			: 'rgba(255, 255, 255, 0.6)'
																}}
																onClick={() => {
																	setFadeListType(p => (p === 'slowdown' ? '' : 'slowdown'));
																}}
															>
																Slow
															</span>
														</span>
														{/* {window.innerWidth > 575 && ( */}
														{/* <> */}
														<FormControlLabel
															value='start'
															className={
																classes.formControl +
																' d-none d-sm-inline-flex py-0' +
																` ${fadeListType === 'pending' && 'tab-active active-labels'}`
															}
															control={
																<Checkbox
																	disableRipple
																	checked={fadeListType === 'pending' ? true : false}
																	onChange={() => {
																		setFadeListType(p => (p === 'pending' ? '' : 'pending'));
																	}}
																	classes={{
																		root: classes.root,
																		checked: classes.checked
																	}}
																/>
															}
															label='Pending'
															labelPlacement='end'
															classes={{ label: classes.label }}
														/>
														<FormControlLabel
															value='start'
															className={
																classes.formControl +
																' ml-1 ' +
																'd-none d-sm-inline-flex' +
																` ${fadeListType === 'slowdown' && 'tab-active active-labels'}`
															}
															control={
																<Checkbox
																	disableRipple
																	checked={fadeListType === 'slowdown' ? true : false}
																	onChange={() => {
																		setFadeListType(p => (p === 'slowdown' ? '' : 'slowdown'));
																	}}
																	classes={{
																		root: classes.root,
																		checked: classes.checked
																	}}
																/>
															}
															label='Slow'
															labelPlacement='end'
															classes={{ label: classes.label }}
														/>
														{/* </> */}
														{/* )} */}
														{/*<FormControlLabel
								                value='start'
								                className={classes.formControl}
								                control={
								                	<Checkbox
								                		disableRipple
								                		checked={fadeListType === 'review' ? true : false}
								                		onChange={() => {setFadeListType((p) => p === 'review' ? '' : 'review'); }}
								                		classes={{
								                			root: classes.root,
								                			checked: classes.checked
								                		}}
								                	/>
								                }
								                label='Review'
								                labelPlacement='start'
								                classes={{ label: classes.label }}/> */}

														{!showSearchBar && window.innerWidth > 600 && (
															<span
																className='d-inline-flex align-items-center cursor-pointer'
																style={{
																	fontSize: '12px'
																}}
																onClick={() => setShowSearchBar(true)}
															>
																<SearchIcon
																	style={{
																		cursor: 'pointer',
																		margin: '0 10px',
																		position: 'relative'
																	}}
																/>
																Search
															</span>
														)}
														{showSearchBar && window.innerWidth > 600 && (
															<>
																<CloseIcon
																	style={{
																		cursor: 'pointer',
																		color: 'red',
																		marginLeft: '10px',
																		position: 'relative',
																		marginBottom: '16px'
																	}}
																	onClick={() => {
																		setShowSearchBar(false);
																		setSearchInput('');
																	}}
																/>
																<input
																	type='text'
																	placeholder='Search'
																	style={{
																		background: 'transparent',
																		color: 'white',
																		border: 0,
																		opacity: '100%',
																		marginBottom: '0px',
																		width: 'fit-content',
																		marginLeft: '10px',
																		marginBottom: '16px'
																	}}
																	className='text-normal'
																	value={searchInput}
																	onChange={e => setSearchInput(e.target.value)}
																/>
															</>
														)}
														<span
															className='d-inline-flex align-items-center cursor-pointer'
															style={{
																color: '#fff',
																fontSize: '12px'
															}}
															onClick={() => {
																refreshWorksheetData();
															}}
														>
															<IoMdRefresh size={14} style={{}} />
														</span>
														<span className={`px-2 d-inline`}>
															<span
																className='text-normal'
																// to={`/chopping-interface/${activeWorksheet}/completion/today`}
																style={{
																	fontSize: '12px',
																	cursor: 'pointer',
																	opacity: '1',
																	color:
																		fadeListType === 'my-tasks'
																			? 'white'
																			: 'rgba(255, 255, 255, 0.6)'
																}}
																onClick={() => {
																	setFadeListType(p => (p === 'my-tasks' ? '' : 'my-tasks'));
																}}
															>
																My Task
															</span>
														</span>
													</>
												)}
											</div>
											{/* {(currentTab === tabs.activity || currentTab === tabs.completion) && <>

											<div style={{ marginBottom: '20px', display: 'flex', }}>
												<span className={`text-normal text-muted`}>

													<button className='text-muted activity-button' onClick={() => changeTabs(tabs.mainTasks)}>
														<span style={{ color: '#a78bfa' }}>{totalHours}</span> hours of work
													</button>
												</span>
												<span className={`px-3`}>
													<button
														className='activity-button text-normal text-muted'
														style={{ background: 'none', marginBottom: '0px', }}
														onClick={() => changeTabs(tabs.activity)}

													>
														Activity

													</button>
												</span>
												<span className={`px-3`}>

													<button
														className='text-normal text-muted activity-button'
														style={{ background: 'none', marginBottom: '0px', }}
														onClick={() => changeTabs(tabs.completion)}

													>
														Completion

													</button>
												</span>
											</div>
										</>} */}

											{currentTab === tabs.mainTasks && (
												<>
													<div className='d-flex ml-4'>
														{isFetchingTasksWithNewComments ? (
															<div style={{ flexShrink: '0' }}>
																<ClipLoader size={20} color='#fff' />
															</div>
														) : (
															<>
																{taskList.filter(
																	item =>
																		tasksWithNewComments?.[item._id] && item.comments_count > 0
																).length > 0 && (
																	<>
																		<span
																			id='bubblechat'
																			className='text-muted d-flex items-center'
																			onClick={scrollToFirstNotification}
																			style={{ cursor: 'pointer', color: 'white', opacity: '1' }}
																		>
																			<ChatBubbleOutlineIcon />
																		</span>
																		<span
																			className='ml-1 pr-2 pr-sm-3 d-flex items-center'
																			style={{
																				fontSize: '10px',
																				color: '#ffffff',
																				opacity: '1'
																			}}
																		>
																			{
																				Object.values(tasksWithNewComments).filter(
																					hasNotification => !!hasNotification
																				).length
																			}
																		</span>
																	</>
																)}
															</>
														)}

														<span
															id='scrltop'
															className='text-normal text-muted px-2 px-sm-3 mb-0 d-flex items-center'
															onClick={scrollToTop}
															style={{
																cursor:
																	arrowsVisible && upArrowCLickable ? 'pointer' : 'not-allowed',
																color: arrowsVisible && upArrowCLickable ? 'white' : 'gray',
																visibility: arrowsVisible ? 'visible' : 'hidden',
																opacity: '1'
															}}
														>
															<ArrowUpwardIcon />
														</span>
														<span
															id='scrlbottom'
															className='text-normal text-muted pl-2 pl-sm-3 mb-0 d-flex items-center'
															onClick={scrollToBottom}
															style={{
																cursor:
																	arrowsVisible && downArrowClickable ? 'pointer' : 'not-allowed',
																color: arrowsVisible && downArrowClickable ? 'white' : 'gray',
																visibility: arrowsVisible ? 'visible' : 'hidden',
																opacity: '1'
															}}
														>
															<ArrowDownwardIcon />
														</span>
													</div>
												</>
											)}
										</div>

										{/* ============================== upper menue items for worksheet end =================== */}

										{/* <div className={`col-6 px-0 d-flex justify-content-end ${styles.listingsHeading}`}>
									<span>{((100 * completedHours) / (totalHours + completedHours)).toFixed()}% </span>{' '}
									&nbsp; Completed */}
										{/* totalHours === 0 ? 1 : totalHours */}

										{/* <div className={styles.worksheetDrawerOpener} style={{marginBottom: '25px'}}>
									<div onClick={() => setStateSidebar(true)}>
										{worksheetTitle.slice(0, 10) + '...'} &#9662;
									</div>
								</div> */}
										{/* </div> */}
									</div>
								)}
							</CommonHeader>
							{/*================ right - upbar (nav) | End ====================== */}
							{/* <div>
								<LinearProgress
									variant='determinate'
									value={(100 * completedHours) / (totalHours + completedHours)}
									sx={{
										color: 'secondary'
									}}
								/>
							</div> */}
							{/* </div> */}
							{/* ===================== right worksheet task list start ================ */}
							{currentTab === tabs.mainTasks && (
								<>
									{headerHeightSet ? (
										<div
											id='lists-container'
											className={styles.mainWorksheetContent}
											style={{
												zIndex: '10',
												overflow: 'visible',
												marginTop: isMobile ? `${headerRef.current?.clientHeight + 30}px` : '0px'
											}}
											ref={listRef}
										>
											{searchQueryLength ? (
												searchedTasks.length ? (
													searchedTasks.map((searchedTask, stIndex) => (
														<div>
															<div
																style={{
																	color: 'white',
																	fontSize: '16px',
																	marginBottom: 10
																}}
															>
																{searchedTask.worksheetTitle}
															</div>

															<TaskListContext.Provider
																key={stIndex}
																value={{
																	taskList: searchedTask.tasks,
																	EditHandler,
																	updatingTaskDataStatus
																}}
															>
																{searchedTask.tasks
																	.filter(function (item) {
																		return (
																			item.parent_id === 0 ||
																			item.parent_id === '' ||
																			item.parent_id === null
																		);
																	})
																	.sort((a, b) => a.seq - b.seq)
																	.map((item, index) => {
																		var prevItem = searchedTask.tasks.filter(function (item) {
																			return (
																				item.parent_id === 0 ||
																				item.parent_id === '' ||
																				item.parent_id === null
																			);
																		})[index === 0 ? 0 : index - 1];
																		if (selectedTask?._id === item._id) {
																			// handleUpdateSelectedTask({task: item, prevItemId: index == 0 ? '' : prevItem._id, prevItemParentId: index == 0 ? '' : prevItem.parent_id})
																		}
																		return (
																			<ListItem
																				key={item._id}
																				item={item}
																				index={index}
																				changeCompletion={changeCompletion}
																				inputRef={inputRef}
																				firstCommentRef={firstCommentRef}
																				setShouldScrollToComment={setShouldScrollToComment}
																				currentTask={currentTask}
																				increaseIndent={increaseIndent}
																				decreaseIndent={decreaseIndent}
																				changeEstimate={changeEstimate}
																				setBottomNavVisible={setBottomNavVisible}
																				isBottomNavVisible={isBottomNavVisible}
																				// EditHandler={EditHandler}
																				checkAddKey={checkAddKey}
																				activeTask={activeTask}
																				worksheetAccessTeam={worksheetAccessTeam}
																				openUser={openUser}
																				setWorksheetAccessTeam={setWorksheetAccessTeam}
																				updateTask={updateTaskOnBackend}
																				users={users}
																				taskList={searchedTasks.tasks}
																				prevItemId={index == 0 ? '' : prevItem._id}
																				prevItemParentId={index == 0 ? '' : prevItem.parent_id}
																				setAssginee={setAssginee}
																				selectedItems={selectedItems}
																				showEstimates={showEstimates}
																				hideCompleted={hideCompleted}
																				fadeListType={fadeListType}
																				setOpenUser={setOpenUser}
																				handleHideChildren={handleHideChildren}
																				completedHours={completedHours}
																				handleOpenTaskModal={handleOpenTaskModal}
																				handleOpenTaskDetailsModal={handleOpenTaskDetailsModal}
																				handleOpenTaskCommentModal={handleOpenTaskCommentModal}
																				handleOpenTaskDrawer={handleOpenTaskDrawer}
																				handleCloseTaskDrawer={handleCloseTaskDrawer}
																				useLongPress={useLongPress}
																				// setSelectedTask={setSelectedTask}
																				searchInput={searchInput}
																				handleDeleteTodo={handleDeleteTodo}
																				activeWorksheetData={activeWorksheetData}
																				presetTasks={presetTasks}
																				handleUpdateTaskWithPresetTask={
																					handleUpdateTaskWithPresetTask
																				}
																				selectedTask={selectedTask}
																				handleUpdateSelectedTask={handleUpdateSelectedTask}
																				userPopupBelow={userPopupBelow}
																				fetchingUsers={fetchingUsers}
																				tasksWithNewComments={tasksWithNewComments}
																			/>
																		);
																	})}
															</TaskListContext.Provider>
														</div>
													))
												) : (
													!searchedTasksLoading && (
														<div
															style={{
																color: 'white',
																fontSize: 24,
																display: 'flex',
																justifyContent: 'center',
																alignItems: 'center',
																height: 'calc(100vh - 30px)'
															}}
														>
															No Task / Description matched the Search Query
														</div>
													)
												)
											) : (
												<TaskListContext.Provider
													value={{ taskList: taskList, EditHandler, updatingTaskDataStatus }}
												>
													{taskList?.length > 0 &&
														taskList
															.filter(function (item) {
																return (
																	item.parent_id === 0 ||
																	item.parent_id === '' ||
																	item.parent_id === null
																);
															})
															.sort((a, b) => a.seq - b.seq)
															.map((item, index) => {
																var prevItem = taskList.filter(function (item) {
																	return (
																		item.parent_id === 0 ||
																		item.parent_id === '' ||
																		item.parent_id === null
																	);
																})[index === 0 ? 0 : index - 1];
																if (selectedTask?._id === item._id) {
																	// handleUpdateSelectedTask({task: item, prevItemId: index == 0 ? '' : prevItem._id, prevItemParentId: index == 0 ? '' : prevItem.parent_id})
																}
																return (
																	<ListItem
																		key={item._id}
																		item={item}
																		index={index}
																		changeCompletion={changeCompletion}
																		inputRef={inputRef}
																		firstCommentRef={firstCommentRef}
																		setShouldScrollToComment={setShouldScrollToComment}
																		currentTask={currentTask}
																		increaseIndent={increaseIndent}
																		decreaseIndent={decreaseIndent}
																		changeEstimate={changeEstimate}
																		setBottomNavVisible={setBottomNavVisible}
																		isBottomNavVisible={isBottomNavVisible}
																		// EditHandler={EditHandler}
																		checkAddKey={checkAddKey}
																		activeTask={activeTask}
																		worksheetAccessTeam={worksheetAccessTeam}
																		openUser={openUser}
																		setWorksheetAccessTeam={setWorksheetAccessTeam}
																		updateTask={updateTaskOnBackend}
																		users={users}
																		taskList={taskList}
																		prevItemId={index == 0 ? '' : prevItem._id}
																		prevItemParentId={index == 0 ? '' : prevItem.parent_id}
																		setAssginee={setAssginee}
																		selectedItems={selectedItems}
																		showEstimates={showEstimates}
																		hideCompleted={hideCompleted}
																		fadeListType={fadeListType}
																		setOpenUser={setOpenUser}
																		handleHideChildren={handleHideChildren}
																		completedHours={completedHours}
																		handleOpenTaskModal={handleOpenTaskModal}
																		handleOpenTaskDetailsModal={handleOpenTaskDetailsModal}
																		handleOpenTaskCommentModal={handleOpenTaskCommentModal}
																		handleOpenTaskDrawer={handleOpenTaskDrawer}
																		handleCloseTaskDrawer={handleCloseTaskDrawer}
																		useLongPress={useLongPress}
																		// setSelectedTask={setSelectedTask}
																		searchInput={searchInput}
																		handleDeleteTodo={handleDeleteTodo}
																		activeWorksheetData={activeWorksheetData}
																		presetTasks={presetTasks}
																		handleUpdateTaskWithPresetTask={handleUpdateTaskWithPresetTask}
																		selectedTask={selectedTask}
																		handleUpdateSelectedTask={handleUpdateSelectedTask}
																		userPopupBelow={userPopupBelow}
																		fetchingUsers={fetchingUsers}
																		tasksWithNewComments={tasksWithNewComments}
																	/>
																);
															})}
												</TaskListContext.Provider>
											)}
										</div>
									) : (
										''
									)}{' '}
								</>
							)}

							{currentTab === tabs.activity && (
								<>
									<WorksheetActivity
										notificationCount={notificationCount}
										worksheet_id={activeWorksheet}
										unreadId={unreadId}
									/>
								</>
							)}
							{currentTab === tabs.completion && (
								<>
									<WorksheetCompletionList
										notificationCount={notificationCount}
										worksheet_id={activeWorksheet}
									/>
								</>
							)}

							{/* ===================== right worksheet task list start ================ */}
						</div>
					</div>

					{/* footer start */}

					<div
						className={styles.listingsFooter}
						style={{ height: '0px', padding: '0px', display: 'none' }} //TODO: Remove display none when needed
					>
						<div
							style={{ display: 'flex', alignItems: 'center', gap: '25px', marginRight: '25px' }}
							className='flex-fill'
						>
							{/**<div className={`    ${styles.listingsHeading} ${styles.footerEstimate}`}>
								<span>{totalHours - completedHours}</span> hours of work
							</div>**/}
							{/* setting icon menu  and its  items*/}
							<Menu
								id='long-menu'
								MenuListProps={{
									'aria-labelledby': 'long-button'
								}}
								anchorEl={anchorEl}
								open={open}
								onClose={handleSettingsMenuClose}
								PaperProps={{
									style: {}
								}}
								// anchorOrigin={{
								// 	vertical: 'bottom',
								// 	horizontal: 'center',
								// }}
								transformOrigin={{
									vertical: 'bottom',
									horizontal: 'center'
								}}
							>
								<MenuItem
									key={'1'}
									onClick={e => {
										handleSettingsMenuClose();
										setShowEstimates(prev => !prev);
									}}
								>
									{showEstimates ? 'Hide estimates' : 'Show estimates'}
								</MenuItem>
								{/* <MenuItem key={"2"} onClick={e => {
									handleSettingsMenuClose()
									setHideCompleted(prev => !prev);
								}}>
									{hideCompleted ? 'Show completed' : 'Hide completed'}
								</MenuItem> */}
								{(user?._id === activeWorksheetData?.user_id ||
									user?.role === 'owner' ||
									user?.role === 'team_lead') && (
									<MenuItem
										key={'3'}
										onClick={e => {
											handleSettingsMenuClose();
											e.preventDefault();
											setPeopleModal(true);
										}}
									>
										Set up People
									</MenuItem>
								)}

								<MenuItem
									key={'4'}
									onClick={e => {
										handleSettingsMenuClose();
									}}
									component='a'
									href={`/chopping-interface/${activeWorksheet}/activity`}
								>
									<span style={{ color: 'black' }}>Activity</span>
								</MenuItem>

								<MenuItem key={'5'} onClick={handleOpenGroups}>
									<span style={{ color: 'black' }}>Add to group</span>
								</MenuItem>

								{user?._id === activeWorksheetData?.user_id && (
									<MenuItem
										onClick={e => {
											//	e.stopPropagation();
											//e.preventDefault();
											handleSettingsMenuClose();
											deleteWorksheet(activeWorksheet);
										}}
									>
										Delete Worksheet
									</MenuItem>
								)}
							</Menu>
							<Menu
								id='long-menu'
								MenuListProps={{
									'aria-labelledby': 'long-button'
								}}
								anchorEl={groupAnchor}
								open={groupMenu}
								onClose={handleCloseGroupMenu}
								PaperProps={{
									style: {}
								}}
								transformOrigin={{
									vertical: 'bottom',
									horizontal: 'center'
								}}
							>
								<Backdrop className={`backdrop`} open={loadingGroups}>
									<CircularProgress color='inherit' style={{ width: '20px', height: '20px' }} />
								</Backdrop>
								{worksheetGroups?.map(group => {
									return (
										<MenuItem onClick={() => addToGroup(group.client_name)}>
											{group.client_name}
										</MenuItem>
									);
								})}
								<MenuItem
									onClick={() => {
										setIsOpenNewGroup(true);
										handleCloseGroupMenu();
									}}
								>
									Add A New Group
								</MenuItem>
							</Menu>

							<Modal
								open={isOpenNewGroup}
								onClose={() => {
									setIsOpenNewGroup(false);
								}}
								aria-labelledby='modal-modal-title'
								aria-describedby='modal-modal-description'
								className='modal_root'
							>
								<Box className='modal_container fade_in_bottom'>
									<Box className='modal_header d-flex'>
										<div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
											<p>Add A New Group</p>
										</div>
										<div style={{ display: 'flex', justifyContent: 'flex-end' }}>
											<div onClick={() => setIsOpenNewGroup(false)} className={`fa_cross_icon`}>
												<FaTimes size={10} />
											</div>
										</div>
									</Box>
									<Box className='modal_body'>
										<div className='group-elements'>
											<input
												className='group-input'
												placeholder='New Group Name'
												value={newGroupName}
												onChange={event => {
													setNewGroupName(event.target.value);
												}}
											/>

											<div>
												<LoadingButton
													onClick={() => {
														handleAddNewGroup();
													}}
													loading={false}
													loadingIndicator='Uploading…'
													variant='contained'
												>
													Add
												</LoadingButton>
											</div>
										</div>
									</Box>
								</Box>
							</Modal>

							{hardTasks !== 0 && hardTasks !== null && (
								<div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
									Hard Tasks
									{/* <div className={`${styles.taskCount} ${styles.taskCountHard}`}>{hardTasks}</div> */}
								</div>
							)}

							{mediumTasks !== 0 && mediumTasks !== null && (
								<div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
									Medium Tasks
									{/* <div className={`${styles.taskCount} ${styles.taskCountMedium}`}>
											{mediumTasks}
										</div> */}
								</div>
							)}

							{easyTasks !== 0 && easyTasks !== null && (
								<div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
									Easy Tasks
									{/* <div className={`${styles.taskCount} ${styles.taskCountEasy}`}>{easyTasks}</div> */}
								</div>
							)}
							{/* </div> */}

							{/* {completedToday !== null && (
							<div>
								<span style={{ color: '#A78BFA' }}>{completedToday}</span> Hours of work done today
							</div>
							
							
							)} */}
						</div>
					</div>
					{/* <ChoppingSidebar users={assgineeList} taksList={taskList} /> */}
				</div>
				{isMobile ? (
					<Box
						width='60px'
						height='60px'
						style={{
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'center',
							position: 'fixed',
							bottom: '80px',
							right: '10px',
							borderRadius: '100%',
							zIndex: 999,
							background: '#333333',
							padding: '10px'
						}}
						onClick={autoCreateTaskAtTheBottom}
					>
						<AiOutlinePlus size={40} />
					</Box>
				) : null}
				<Modal
					open={openTaskModal}
					onClose={handleCloseTaskModal}
					aria-labelledby='modal-modal-title'
					aria-describedby='modal-modal-description'
					className='modal_root'
				>
					<Box className={`modal_container fade_in_bottom scroll-hide`}>
						<Box className={`modal_header`}>Description</Box>
						<Box className={`modal_body`} style={{ backgroundColor: 'white', color: 'black' }}>
							<Wysiwyg
								value={selectedTask?.description}
								onChange={html => setTaskDesc(html)}
								onSubmit={handleDescriptionSubmit}
								setLoading={setBtnLoading}
								task={selectedTask}
							/>
							<LoadingButton
								onClick={() => {
									handleDescriptionSubmit();
								}}
								loading={btnLoading}
								loadingIndicator='Uploading…'
								variant='contained'
							>
								Save
							</LoadingButton>
							{/* <button onClick={() => { handleDescriptionSubmit() }}>Save</button> */}
						</Box>
					</Box>
				</Modal>
				<Modal
					open={peopleModal}
					onClose={() => {
						setPeopleModal(false);
					}}
					aria-labelledby='modal-modal-title'
					aria-describedby='modal-modal-description'
					className='modal_root'
				>
					<Box className={`modal_container fade_in_bottom people-modal`}>
						<Box className={`modal_header people-modal-header`}>
							Set up People
							<div className={`fa_cross_icon`}>
								<FaTimes
									size={10}
									onClick={() => {
										setPeopleModal(false);
									}}
								/>
							</div>
						</Box>
						<Box className={`modal_body`}>
							<h3 style={{ color: 'white' }}>Add New People</h3>
							<div>
								<Select
									isMulti
									name='new_people'
									options={addPeopleOption}
									className='basic-multi-select'
									classNamePrefix='select'
									styles={{
										menuLists: styles => ({
											...styles,
											color: 'black'
										}),
										option: (styles, { isFocused, isSelected }) => ({
											...styles,
											color: 'black',
											zIndex: '99999'
										})
									}}
									onChange={e => {
										setAddPeopleValues(e);
										if (e.map(r => r.label).includes('Add Team Member')) {
											navigate(`/team`);
										}
									}}
									value={addPeopleValues || ''}
								/>
							</div>
							<div className='py-4'>
								<Link
									to='/'
									onClick={e => {
										handleAddPeople(e);
									}}
									style={{ color: 'white', padding: '5px 10px', border: '1px solid white' }}
								>
									{' '}
									Add
								</Link>
							</div>

							<h3 style={{ color: 'white' }}>Exisiting People</h3>
							<div className='mb-4'>
								{worksheetAccessTeam.map((user, index) => {
									if (user) {
										return (
											<div className={styles.modal_user_list} key={index}>
												<div className='d-flex align-items-center'>
													<CustomAvatar
														avatar={user.avatar}
														alt='profile'
														name={user.name}
														style={{
															width: '40px',
															height: '40px',
															fontSize: '10px',
															borderRadius: '50%'
														}}
													/>
												</div>
												<div className='d-flex align-items-center'>
													<h4 style={{ color: 'white' }}>{user.name}</h4>
												</div>
												<div className='d-flex align-items-center'>
													{user._id !== activeWorksheetData.user_id && (
														<Link
															to={`/`}
															onClick={e => {
																handleRevokeAccess(e, user._id);
															}}
															style={{ color: 'white' }}
														>
															Revoke Access
														</Link>
													)}
												</div>
											</div>
										);
									}
									return null;
								})}
							</div>
						</Box>
					</Box>
				</Modal>
				{/* <Modal
					open={openTaskDetailsModal}
					onClose={handleCloseTaskDetailsModal}
					aria-labelledby='modal-modal-title'
					aria-describedby='modal-modal-description'
				>
					<Box className={`modal_container`}>
						<div
							style={{
								display: 'flex',
								gap: '20px',
								justifyContent: 'flex-end',
								position:'absolute',
								width:'100%',
								transition: 'transform 0.1s ease-in-out',
								transform: `translateY(${headerVisible})`,
								top: `0px`,
								background: '#5a5a5a',
								paddingTop: '10px',
								paddingBottom: '10px',
								marginTop: '-10px',
								paddingRight: '14px',
								height: '50px',
								zIndex: 100000
							}}
						>
							{unreadsPresent && (
								<button
									title='Save For Later'
									className={styles.tdpHeaderBtn}
									onClick={saveForLater}
									style={{ color: 'white', width: '50px' }}
								>
									<FaClock size={16} />
								</button>
							)}
							{unreadsPresent && (
								<button
									title='Close as Unread'
									className={styles.tdpHeaderBtn}
									onClick={handleCloseAsUnread}
									style={{ color: 'white', width: '50px' }}
								>
									<IoMdMailUnread size={17} />
								</button>
							)}
							<button title='Close' className={styles.tdpHeaderBtn}>
								<div className={`fa_cross_icon`}>
									<FaTimes size={10} onClick={handleCloseTaskDetailsModal} />
								</div>
							</button>
						</div>
						<Box className={`modal_body pt-0`} onScroll={handleHeaderScroll}>
							<TaskDetails
								activeWorksheet={activeWorksheet}
								item_id={selectedTask?._id}
								updateTaskOnChoppingInterface={updateTaskFromTaskDetailsModal}
								shouldScrollToComment={shouldScrollToComment}
								parentTitle={getParentTitle()}
								showDescBox={showDescBox}
								hyperlinkedComment={hyperlinkedComment}
								changeEstimate={changeEstimate}
							/>
						</Box>
					</Box>
				</Modal> */}
				<TaskDetailsPopup
					open={openTaskDetailsModal}
					handleClose={handleCloseTaskDetailsModal}
					activeWorksheet={worksheetList.find(item => item._id === activeWorksheet)}
					item_id={selectedTask?._id}
					selectedTask={prefetchedTasksMap[selectedTask?._id]} // Don't use selectedTask as a props value
					updateTaskOnChoppingInterface={updateTaskFromTaskDetailsModal}
					shouldScrollToComment={shouldScrollToComment}
					parentTitle={getParentTitle()}
					showDescBox={showDescBox}
					hyperlinkedComment={hyperlinkedComment}
					changeEstimate={changeEstimate}
					headerVisible={headerVisible}
					handleHeaderScroll={handleHeaderScroll}
					handleCloseAsUnread={handleCloseAsUnread}
					saveForLater={saveForLater}
					unreadsPresent={unreadsPresent}
					updatePrefetchedTask={(taskId, data) => {
						setPrefetchedTasksMap(prev => ({
							...prev,
							[taskId]: {
								...prev[taskId],
								...data
							}
						}));
					}}
					openedTask={selectedTask}
				/>
				<Modal
					open={openTaskCommentModal}
					onClose={handleCloseTaskCommentModal}
					aria-labelledby='modal-modal-title'
					aria-describedby='modal-modal-description'
					className='modal_root'
				>
					<Box className={`modal_container fade_in_bottom`}>
						<Box className={`modal_header`}>{commentType.replace('_', ' ')}</Box>
						<Box className={`modal_body`}>
							<Wysiwyg
								onChange={html => setTaskComment(html)}
								specialCommentShow={true}
								showCustomMessage={true}
								customMessageType={commentType}
								onSubmit={handleCommentSubmit}
								btnLoading={btnLoading}
								setLoading={setBtnLoading}
								task={selectedTask}
							/>

							{/* <button onClick={() => { handleCommentSubmit() }}>Save</button> */}
						</Box>
					</Box>
				</Modal>
				<Modal
					open={openTeamsNotificationClear}
					onClose={() => setOpenTeamsNotificationClear(false)}
					className='modal_root'
				>
					<Box
						className={`modal_container d-flex justify-content-center align-items-center fade_in_bottom ${styles.teamsNotification}`}
					>
						<div className={`mb-0 d-inline ${styles.clearTeamsButton}`}>
							Clear Team Notifications
						</div>
					</Box>
				</Modal>
				<Modal
					open={openMoveTaskModal}
					onClose={() => setOpenMoveTaskModal(false)}
					aria-labelledby='modal-modal-title'
					aria-describedby='modal-modal-description'
					className='modal_root'
				>
					<Box className={`modal_container fade_in_bottom`}>
						<Box className={`modal_header`}>Move Task</Box>
						<Box className={`modal_body`}>
							<Typography>Select Worksheet</Typography>
							<Select
								options={worksheetList}
								getOptionValue={option => option._id}
								getOptionLabel={option => (option.title ? option.title : option.title_first)}
								onChange={(newValue, actionMeta) => setMoveToWorksheet(newValue)}
								styles={{
									option: (baseStyles, state) => ({
										...baseStyles,
										color: 'black'
									})
								}}
							/>
							<button onClick={moveTask}>Move</button>
						</Box>
					</Box>
				</Modal>

				<GlobalSearch open={openSpotlightModal} setOpenSpotlightModal={setOpenSpotlightModal} />
			</div>
		</Profiler>
	);
};

const keyCodes = ['Enter', 'Tab'];
const specialKeyCodes = [38, 40, 37, 39];

let refIndex = -1;

function ListItem({
	item,
	index,
	changeCompletion,
	inputRef,
	firstCommentRef,
	setShouldScrollToComment,
	currentTask,
	increaseIndent,
	decreaseIndent,
	changeEstimate,
	isBottomNavVisible,
	setBottomNavVisible,
	// EditHandler,
	checkAddKey,
	activeTask,
	worksheetAccessTeam,
	openUser,
	setWorksheetAccessTeam,
	fetchingUsers,
	updateTask,
	users,
	// taskList,
	prevItemId,
	prevItemParentId,
	setAssginee,
	selectedItems,
	showEstimates,
	fadeListType,
	hideCompleted,
	setOpenUser,
	handleHideChildren,
	depth = 0,
	completedHours,
	handleOpenTaskModal,
	handleOpenTaskDetailsModal,
	handleOpenTaskCommentModal,
	handleOpenTaskDrawer,
	useLongPress,
	// setSelectedTask,
	searchInput,
	handleDeleteTodo,
	activeWorksheetData,
	presetTasks,
	handleUpdateTaskWithPresetTask,
	selectedTask,
	handleUpdateSelectedTask,
	appliedOpacity = false,
	userPopupBelow,
	handleCloseTaskDrawer,
	tasksWithNewComments
}) {
	const { taskList, EditHandler, updatingTaskDataStatus } = useContext(TaskListContext);
	const longpressEvent = useLongPress(handleOpenTaskDrawer, 500, {
		item,
		prevItemId,
		prevItemParentId
	});

	const isTaskNotAssigned = (() => {
		// Return false task is completed
		if (item.completed) return false;

		let shouldMarkRed = false;

		let childTasks = taskList.filter(function (child) {
			return child.parent_id === item._id;
		});

		const isLeafTask = !childTasks.length;

		if (isLeafTask && !item.assginee) {
			shouldMarkRed = true;
		}

		return shouldMarkRed;
	})();

	const filteredTodo = taskList
		.filter(function (childItem) {
			return childItem.parent_id === item._id;
		})
		.sort((a, b) => a.seq - b.seq);

	const [isHovered, setIsHovered] = useState(false);

	const getColor = () => {
		// if (item.assginee === item.estimation_by) {
		// 	return '#5d8bff';
		// }
		if (item.estimate <= 2 || filteredTodo.length > 0) {
			return '#5FB924';
		} else if (item.estimate > 2 && item.estimate < 5) {
			return '#FCBA03';
		} else if (item.estimate >= 5) {
			return '#FF0000';
		}
	};

	const getOpacity = () => {
		// if (!hideCompleted || (hideCompleted && !(item.completed || item.last_comment_type === 'submission'))) {
		// 	return 1;
		// } else
		const time1 = moment(item.last_commented_at);
		const time2 = moment(item.completed_on);
		var now = moment();
		if (fadeListType === '') {
			return 1;
		} else if (
			fadeListType === 'pending' &&
			!(item.completed || item.last_comment_type === 'submission')
		) {
			return 1;
		} else if (
			fadeListType === 'review' &&
			!item.completed &&
			item.last_comment_type !== 'comment' &&
			item.last_comment_type !== 'approve' &&
			item.last_comment_type === 'submission'
		) {
			return 1;
		} else if (
			fadeListType === 'slowdown' &&
			!(item.completed || item.last_comment_type === 'submission') &&
			now.diff(time1, 'hours') > 72
		) {
			return 1;
		} else if (fadeListType === 'my-tasks' && item.assginee === user._id) {
			return 1;
		} else {
			return 0.25;
		}
	};

	const getAllLeafTasksEstimates = () => {
		let leafEstimates = [];

		const checkLeaf = task => {
			let isLeaf = true;
			[...taskList].forEach(otherTask => {
				if (otherTask.parent_id === task._id) {
					isLeaf = false;
					checkLeaf(otherTask);
				}
			});
			if (isLeaf) {
				leafEstimates.push(task.estimate);
			}
		};

		[...taskList].forEach(task => {
			if (task.parent_id === item._id) {
				checkLeaf(task);
			}
		});
		return leafEstimates;
	};

	const [title, setTitle] = useState(item.title ? item.title : '');

	const debouncedEditHandler = useCallback(debounce(EditHandler, 500), []);

	const handleValueChange = e => {
		setTitle(e.target.value);
		// handletaskListChange(item._id, e.target.value);
		// if(!(keyCodes.includes(e.key) && specialKeyCodes.includes(e.which) && e.ctrlKey && e.shiftKey && e.key === 'Backspace')){
		debouncedEditHandler(e, item);
		// }
	};

	useEffect(() => {
		setTitle(item.title);
	}, [item.title]);

	const setHoverTrue = () => {
		if (window.innerWidth > 575) {
			setIsHovered(true);
		}
	};
	const [taskActionOpen, setTaskActionOpen] = useState(false);
	const [position, setPosition] = useState(0);
	const [isKeyboardVisible, setIsKeyboardVisible] = useState(false);
	const footerRef = useRef();
	useEffect(() => {
		const handleClickOutside = event => {
			if (inputRef && inputRef[item._id]) {
				if (
					inputRef[item._id].current &&
					!inputRef[item._id].current.contains(event.target) &&
					footerRef.current &&
					!footerRef.current.contains(event.target)
				) {
					setTimeout(() => {
						setTaskActionOpen(false);
					}, 200);

					setTimeout(() => {
						setBottomNavVisible(true);
					}, 100);
				}
			}
		};

		document.addEventListener('click', handleClickOutside);

		return () => {
			document.removeEventListener('click', handleClickOutside);
		};
	}, [inputRef]);

	const [testHeight, setTestHeight] = useState();
	// const handleInputFocus = (e) => {
	// 	const keyboardHeight = window.innerHeight - window.visualViewport?.offsetTop - window.visualViewport?.height;
	// 	// 	const keyboardHeight = windowHeight - viewportHeight;
	// 	// setIsKeyboardVisible(keyboardHeight > 0);
	// 	setTestHeight(keyboardHeight);
	// 	setPosition(keyboardHeight);
	// 	setTaskActionOpen(true);
	// 	setTimeout(() => setBottomNavVisible(false), 200);
	// }
	const handleInputFocus = () => {
		setTaskActionOpen(true);
		setTimeout(() => setBottomNavVisible(false), 200);
	};

	useEffect(() => {
		const handleResize = () => {
			setTimeout(() => {
				const keyboardHeight =
					window.innerHeight - window.visualViewport?.offsetTop - window.visualViewport?.height;
				setTestHeight(keyboardHeight);
				setPosition(keyboardHeight);
			}, 200);
			//   if (keyboardHeight > 0) {
			//   }
		};

		window.addEventListener('resize', handleResize);

		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, []);

	const handleCloseAssigneDrawer = () => {
		setOpenUser(false);
	};
	const parentMainRef = useRef();

	return (
		<div
			className={`${styles.animargin}  ${filteredTodo.length === 0 ? styles.taskMainTree : ''}`}
			style={{
				marginTop: depth === 0 ? '4px' : '0px' // Margin only foro parent task container
				// alignItems: depth > 0 && !filteredTodo.length ? 'center' : 'inherit' // Align center only for parent task container and if no child task
			}}
		>
			{/* {showArrow && filteredTodo.length > 0 && !hideChilds.includes(item._id) && (<AiOutlineDown className='hideChildsArrow' onClick={(e) => {setHideChilds((prev) => [...prev, item._id])}}/>)} */}

			{filteredTodo.length > 0 && (
				<div
					className='d-flex justify-content-center'
					style={{
						width: '20px',
						cursor: 'pointer',
						marginLeft: '-16px',
						// marginBottom: '5px',
						zIndex: 8,
						paddingTop: '9px',
						paddingLeft: '7px'
					}}
				>
					<AiOutlineRight
						style={{
							transform: item.hide_children ? undefined : 'rotate(90deg)',
							transition: 'transform 0.3s ease-in-out'
						}}
						onClick={e => {
							e.stopPropagation();
							handleHideChildren(item, !item.hide_children);
						}}
					/>
				</div>
			)}

			{/* checkbox */}
			{/* ============ tdp ====================== */}
			<div style={{ width: '20px', position: 'relative', opacity: getOpacity() }}>
				<div
					style={{
						background:
							filteredTodo.length > 0 && isHovered ? 'rgba(0, 0, 0, 0.50)' : 'transparent',
						height: `${parentMainRef?.current?.clientHeight}px`,
						borderTopLeftRadius: '4px',
						borderBottomLeftRadius: '4px'
					}}
					onClick={e => {
						if (item.completed === false) {
							handleOpenTaskCommentModal(item, 'submission');
						} else {
							handleOpenTaskCommentModal(item, 'reopen');
						}
					}}
					className={`d-flex justify-content-center align-items-center ${
						filteredTodo.length > 0 && styles.parentTreeIcon
					}`}
				>
					{/* tdp tdp tdp only =============  */}
					{/* <TaskIcon
						item={item}
						onClick={e => {
							if (item.completed === false) {
								handleOpenTaskCommentModal(item, 'submission');
							} else {
								handleOpenTaskCommentModal(item, 'reopen');
							}
						}}
						style={{ cursor: 'pointer' }}
					/> */}
					<TaskTreeIcon item={item} />
				</div>
				{filteredTodo.length > 0 && !item.hide_children && (
					<div
						className={`d-flex justify-content-center align-items-center ${styles.verticalLinesContainer}`}
						onClick={e => {
							handleHideChildren(item, true);
						}}
					>
						{filteredTodo.length > 0 && !item.hide_children && (
							<div className={styles.verticalLines}></div>
						)}
					</div>
				)}
			</div>

			<div
				// className={styles.rowContainer + ' taskItem'}
				className={styles.taskParent}
				style={{ width: 'calc(100%)' }}
			>
				<div
					style={{
						display: 'flex',
						alignItems: 'flex-start',
						gap: '5px',
						// padding: '5px 2px 5px 10px',
						paddingLeft: '5px',
						// paddingRight: window.innerWidth <= 575 ? '10px' : '0px',
						background:
							filteredTodo.length > 0 && isHovered ? 'rgba(0, 0, 0, 0.50)' : 'transparent',
						opacity: getOpacity(),
						borderTopRightRadius: '4px',
						borderBottomRightRadius: '4px'
					}}
					ref={parentMainRef}
					className={`unselectable-text ${filteredTodo.length > 0 && styles.parentTreeTask}`}
					onMouseEnter={setHoverTrue}
					onMouseLeave={() => {
						setIsHovered(false);
					}}
					{...longpressEvent}
				>
					{/* estimate */}
					{showEstimates && (
						<div className='d-flex align-items-end mt-1'>
							{item.estimate !== 0 ? (
								<div
									className='d-flex align-items-end unselectable-text'
									style={{
										// border: `1px solid ${
										// 	item.estimate <= 2 || filteredTodo.length > 0
										// 		? '#5FB924'
										// 		: item.estimate > 2 && item.estimate < 5
										// 		? '#FCBA03'
										// 		: '#FF0000'
										// }`,

										// textAlign: 'center',
										borderRadius: '12px',
										color: getColor(),
										fontSize: '12px',
										lineHeight: '13px',
										padding: '1px 0',
										fontFamily: 'Roboto Mono',
										// padding: '5px 2px 5px 10px',
										fontStyle: 'normal',
										paddingLeft: filteredTodo.length > 0 ? '10px' : ''
									}}
								>
									{tasksWithNewComments?.[item._id] ? (
										<span
											ref={el => (firstCommentRef.current[refIndex++] = el)}
											style={{ visibility: 'hidden' }}
										></span>
									) : (
										''
									)}
									<p className='m-0 p-0'>{item.estimate.toFixed(2)}</p>
								</div>
							) : (
								// : item.estimate !== 0 && item.completed ? (
								// 	<div
								// 		className='d-flex align-items-end'
								// 		style={{
								// 			// background: '#5FB924',

								// 			// textAlign: 'center',
								// 			// borderRadius: '12px',
								// 			fontSize: '12px',
								// 			lineHeight: '13px',
								// 			// border: '1px solid #5FB924',
								// 			padding: '1px 0',
								// 			color: '#5FB924',
								// 			fontFamily: 'Roboto Mono',
								// 			fontStyle: 'normal'
								// 		}}
								// 	>
								// 		<p className='m-0 p-0'>{item.estimate.toFixed(2)}</p>
								// 	</div>
								// )
								<div
									className='d-flex align-items-end'
									style={{
										// background: '#5FB924',

										// textAlign: 'center',
										// borderRadius: '12px',
										fontSize: '12px',
										lineHeight: '13px',
										// border: '1px solid #5FB924',
										padding: '1px 0',
										opacity: '60%',
										color: 'white',
										// backgroundColor:   item?.assginee === 0 ? 'red' :  "#181A1E",
										fontFamily: 'Roboto Mono',
										fontStyle: 'normal',
										paddingLeft: filteredTodo.length > 0 ? '10px' : ''
									}}
								>
									{tasksWithNewComments?.[item._id] ? (
										<span
											ref={el => (firstCommentRef.current[refIndex++] = el)}
											style={{ visibility: 'hidden' }}
										></span>
									) : (
										''
									)}
									<p className='m-0 p-0'>0.00</p>
								</div>
							)}
						</div>
					)}
					{isMobile && taskActionOpen ? (
						<div
							ref={footerRef}
							className={styles.taskActionFooter}
							style={{
								width: window.innerWidth,
								bottom: `${position}px`,
								position: 'fixed',
								right: 0,
								left: 0,
								zIndex: 9999,
								margin: 0
							}}
							onMouseDown={e => {
								e.preventDefault();
							}}
							onTouchStart={e => {
								e.preventDefault();
							}}
						>
							{/* {testHeight} */}
							<FormatIndentDecreaseIcon
								fontSize='large'
								onClick={e => {
									let temp = { ...item };
									temp.title = title;
									decreaseIndent(e, temp, prevItemParentId);
								}}
							/>
							<Info
								onClick={e => {
									handleOpenTaskDetailsModal(item);
									inputRef[item._id].current.blur();
								}}
							/>
							{item.comments_count > 0 && (
								<>
									{tasksWithNewComments?.[item._id] ? (
										<ChatBubbleIcon
											fontSize='large'
											style={{ color: '#5FB924', cursor: 'pointer' }}
											onClick={e => {
												setShouldScrollToComment(true);
												handleOpenTaskDetailsModal(item);
											}}
										/>
									) : (
										<ChatBubbleOutlineIcon
											fontSize='large'
											style={{ cursor: 'pointer' }}
											onClick={e => {
												setShouldScrollToComment(true);
												handleOpenTaskDetailsModal(item);
											}}
										/>
									)}{' '}
								</>
							)}
							<Remove
								fontSize='large'
								onClick={() => {
									setTimeout(() => {
										changeEstimate(item, 'down');
									}, 200);
								}}
							/>
							<Add
								fontSize='large'
								onClick={() => {
									setTimeout(() => {
										changeEstimate(item, 'up');
									}, 200);
								}}
							/>

							<FormatIndentIncreaseIcon
								fontSize='large'
								onTouchStart={e => {
									e.preventDefault();
								}}
								onMouseDown={e => {
									e.preventDefault();
								}}
								onClick={e => {
									let temp = { ...item };
									temp.title = title;
									increaseIndent(e, temp, prevItemId);
								}}
							/>
							<Helmet
								style={[
									{
										cssText: `
													.PrivateSwipeArea-anchorBottom-17 {
														top:0
														}
													`
									}
								]}
							/>
						</div>
					) : (
						<></>
					)}
					<div style={{ position: 'relative', width: '100%' }}>
						<TaskTitleInput
							ref={inputRef[item._id]}
							item={item}
							prevItemId={prevItemId}
							prevItemParentId={prevItemParentId}
							title={title}
							handleValueChange={handleValueChange}
							handleInputFocus={handleInputFocus}
							checkAddKey={checkAddKey}
							selectedItems={selectedItems}
							getAllLeafTasksEstimates={getAllLeafTasksEstimates}
							searchInput={searchInput}
							usersWithAccessToWorksheet={worksheetAccessTeam}
							fetchingUsers={fetchingUsers}
							setAssginee={setAssginee}
							isTaskNotAssigned={isTaskNotAssigned}
						/>
						{/* {activeTask?._id === item._id && worksheetAccessTeam && openUser && (
								<>
									{isMobile ? (
										<>
											<SwipeableDrawer
												anchor='bottom'
												open={openUser}
												onClose={handleCloseAssigneDrawer}
												PaperProps={{
													sx: {
														backgroundColor: '#181a1e'
													}
												}}
											>
												<Box
													p={1}
													sx={{
														width: '100%',
														minHeight: '15vh',
														backgroundColor: '#181a1e'
													}}
												>
													{worksheetAccessTeam.map((value, index) => {
														return (
															<div key={index}>
																<div
																	style={{
																		display: 'flex',
																		alignItems: 'center',
																		cursor: 'pointer',
																		zIndex: '10000'
																	}}
																	onClick={() => {
																		let temp = item;
																		item.title = title;
																		setAssginee(temp, value);
																		setOpenUser(false);
																	}}
																>
																	<img
																		src={value.avatar}
																		style={{
																			width: '20px',
																			height: '20px',
																			borderRadius: '100%',
																			marginRight: '10px'
																		}}
																	/>
																	<div style={{ color: '#CECECE' }}>{value.name}</div>
																</div>
																{index !== worksheetAccessTeam.length - 1 && (
																	<hr style={{ border: '0.75px solid #292929' }} />
																)}
															</div>
														);
													})}
												</Box>
											</SwipeableDrawer>
										</>
									) : (
										<div
											style={{
												position: 'absolute',
												left: '0',
												top: userPopupBelow ? '25px' : '-150px',
												width: '250px',
												height: '150px',
												overflowY: 'scroll',
												padding: '10px',
												background: 'rgba(0, 0, 0, 0.85)',
												backdropFilter: 'blur(1px)',
												borderRadius: '4px',
												display: 'flex',
												flexDirection: 'column',
												gap: '10px',
												zIndex: '10000'
											}}
										>
											{worksheetAccessTeam.map((value, index) => {
												return (
													<div key={index}>
														<div
															style={{
																display: 'flex',
																alignItems: 'center',
																cursor: 'pointer',
																zIndex: '10000'
															}}
															onClick={() => {
																let temp = item;
																item.title = title;
																setAssginee(temp, value);
																setOpenUser(false);
															}}
														>
															<img
																src={value.avatar}
																style={{
																	width: '20px',
																	height: '20px',
																	borderRadius: '100%',
																	marginRight: '10px'
																}}
															/>
															<div style={{ color: '#CECECE' }}>{value.name}</div>
														</div>
														{index !== worksheetAccessTeam.length - 1 && (
															<hr style={{ border: '0.75px solid #292929' }} />
														)}
													</div>
												);
											})}
										</div>
									)}
								</>
							)} */}
					</div>

					<div className='d-none d-sm-flex align-items-center mt-1' style={{ maxWidth: '40px' }}>
						{item.comments_count > 0 && (
							<>
								{tasksWithNewComments?.[item._id] ? (
									<ChatBubbleIcon
										style={{ color: '#5FB924', cursor: 'pointer' }}
										onClick={e => {
											setShouldScrollToComment(true);
											handleOpenTaskDetailsModal(item);
										}}
									/>
								) : (
									<ChatBubbleOutlineIcon
										style={{ cursor: 'pointer' }}
										onClick={e => {
											setShouldScrollToComment(true);
											handleOpenTaskDetailsModal(item);
										}}
									/>
								)}{' '}
								<p
									className='text-small my-0 mx-1 p-0'
									style={{
										fontSize: '10px',
										color: tasksWithNewComments?.[item._id] ? '#5FB924' : '#ffffff'
									}}
								>
									{item.comments_count}
								</p>
							</>
						)}
					</div>

					{updatingTaskDataStatus.taskId === item._id ? (
						<div className='d-sm-flex align-items-center mt-1' style={{ maxWidth: '100px' }}>
							{updatingTaskDataStatus.status === UPDATING_TASK_DATA_STATUS.LOADING ? (
								<ClipLoader size={16} color='#fff' />
							) : updatingTaskDataStatus.status === UPDATING_TASK_DATA_STATUS.COMPLETE ? (
								<span className='d-flex align-items-center'>
									<CheckIcon
										style={{
											fontSize: '18px',
											color: '#00ff04'
										}}
									/>
								</span>
							) : null}
						</div>
					) : (
						<div className='d-none d-sm-flex align-items-center mt-1' style={{ width: '18px' }}>
							{
								(isHovered || item.description) && (
									// <NavLink to={`/chopping-interface/task/${item._id}`} className="d-flex align-items-center">
									<Info
										onClick={e => {
											handleOpenTaskDetailsModal(item);
										}}
										style={{ cursor: 'pointer' }}
									/>
								)
								// </NavLink>
							}
						</div>
					)}

					<div
						className='d-none d-sm-inline'
						style={{ width: '22px', marginLeft: '-5px', marginRight: '-2px' }}
					>
						{isHovered ? (
							<CustomMenu
								item={item}
								handleOpenTaskModal={handleOpenTaskModal}
								handleOpenTaskCommentModal={handleOpenTaskCommentModal}
								handleDeleteTodo={handleDeleteTodo}
								searchInput={searchInput}
								activeWorksheetData={activeWorksheetData}
								presetTasks={presetTasks}
								handleUpdateTaskWithPresetTask={handleUpdateTaskWithPresetTask}
								handleCloseTaskDrawer={handleCloseTaskDrawer}
							/>
						) : null}
					</div>
				</div>

				{!item.hide_children && (
					<div className={filteredTodo.length ? styles.childrenTaskContainer : null}>
						{filteredTodo.map((childItem, childIndex) => {
							var childPrevItem = taskList.filter(function (childItem) {
								return childItem.parent_id === item._id;
							})[childIndex === 0 ? 0 : childIndex - 1];

							if (selectedTask?._id === item._id) {
								// handleUpdateSelectedTask({task: item, prevItemId: childIndex == 0 ? childItem.parent_id : childPrevItem._id, prevItemParentId: childIndex == 0 ? item.parent_id : item.parent_id})
							}

							return (
								<ListItem
									key={childItem._id}
									item={childItem}
									index={childIndex}
									changeCompletion={changeCompletion}
									inputRef={inputRef}
									firstCommentRef={firstCommentRef}
									setShouldScrollToComment={setShouldScrollToComment}
									currentTask={currentTask}
									increaseIndent={increaseIndent}
									decreaseIndent={decreaseIndent}
									changeEstimate={changeEstimate}
									setBottomNavVisible={setBottomNavVisible}
									isBottomNavVisible={isBottomNavVisible}
									// EditHandler={EditHandler}
									checkAddKey={checkAddKey}
									activeTask={activeTask}
									worksheetAccessTeam={worksheetAccessTeam}
									openUser={openUser}
									setWorksheetAccessTeam={setWorksheetAccessTeam}
									updateTask={updateTask}
									users={users}
									// taskList={taskList}
									depth={depth + 1}
									prevItemId={childIndex == 0 ? childItem.parent_id : childPrevItem._id}
									prevItemParentId={childIndex == 0 ? item.parent_id : item.parent_id}
									setAssginee={setAssginee}
									selectedItems={selectedItems}
									showEstimates={showEstimates}
									hideCompleted={hideCompleted}
									fadeListType={fadeListType}
									setOpenUser={setOpenUser}
									handleHideChildren={handleHideChildren}
									handleOpenTaskModal={handleOpenTaskModal}
									handleOpenTaskDetailsModal={handleOpenTaskDetailsModal}
									handleOpenTaskCommentModal={handleOpenTaskCommentModal}
									handleOpenTaskDrawer={handleOpenTaskDrawer}
									handleCloseTaskDrawer={handleCloseTaskDrawer}
									useLongPress={useLongPress}
									searchInput={searchInput}
									handleDeleteTodo={handleDeleteTodo}
									activeWorksheetData={activeWorksheetData}
									presetTasks={presetTasks}
									handleUpdateTaskWithPresetTask={handleUpdateTaskWithPresetTask}
									selectedTask={selectedTask}
									handleUpdateSelectedTask={handleUpdateSelectedTask}
									appliedOpacity={getOpacity() < 1 ? true : appliedOpacity}
									userPopupBelow={userPopupBelow}
									fetchingUsers={fetchingUsers}
									tasksWithNewComments={tasksWithNewComments}
								/>
							);
						})}
					</div>
				)}
			</div>
		</div>
	);
}

const useCustomMenuStyles = makeStyles(theme => ({
	root: {
		marginTop: '-50px',
		overflow: 'auto',
		'& .MuiMenu-list': {
			color: 'white'
		},
		'& .MuiListItemText-root': {
			margin: '0px',
			padding: '0px'
		},
		'& .MuiListItemIcon-root': {
			minWidth: '18px !important',
			marginRight: '1rem',
			padding: '0px'
		},
		'& .MuiPaper-root': {
			backgroundColor: 'black !important',
			width: '250px'
		},
		shortcut: {
			color: 'white',
			paddingLeft: '2rem'
		}
	}
}));

const CustomMenu = ({
	item,
	handleOpenTaskModal,
	handleOpenTaskCommentModal,
	handleDeleteTodo,
	searchInput,
	activeWorksheetData,
	presetTasks,
	handleUpdateTaskWithPresetTask,
	largeSized,
	handleCloseTaskDrawer
}) => {
	const [anchorEl, setAnchorEl] = useState(null);
	const classes = useCustomMenuStyles();
	const open = Boolean(anchorEl);

	const handleClick = event => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};

	// action menu
	const [openPriortize, setOpenPriortize] = useState(false);
	const handlePriortizeClick = () => {
		setOpenPriortize(!openPriortize);
	};

	const [openPresetTasks, setOpenPresetTasks] = useState(false);

	const leaderMenu = user.role === 'team_lead' || user.role === 'owner';
	const [commentButtons] = useCommentButtons();

	return (
		<>
			{/* actions menu trigger button*/}

			<Button
				id='basic-button'
				aria-controls={open ? 'basic-menu' : undefined}
				aria-haspopup='true'
				aria-expanded={open ? 'true' : undefined}
				onClick={handleClick}
				style={{
					padding: 0,
					margin: 0,
					minWidth: 'auto',
					height: '15px'
				}}
			>
				<ThreeDotsVertical
					style={{
						color: 'white',
						height: '18px',
						width: 'auto'
					}}
				/>
			</Button>

			{/* actions menu */}

			{anchorEl ? (
				<Menu
					id='basic-menu'
					anchorEl={anchorEl}
					open={!!open}
					// open={true}
					onClose={handleClose}
					MenuListProps={{
						'aria-labelledby': 'basic-button'
					}}
					className={`${classes.root}`}
				>
					{leaderMenu &&
						commentButtons
							.filter(r => r.for_leader)
							.map((button, index) => {
								return (
									((button.is_completed && item.completed) || !button.is_completed) && (
										<MenuItem
											onClick={e => {
												handleOpenTaskCommentModal(item, button.key);
											}}
											key={index}
										>
											<ListItemIcon>{button.icon}</ListItemIcon>
											<ListItemText>{button.label}</ListItemText>
											<Typography variant='body2' className={`${classes.shortcut}`}>
												{button.shortcut}
											</Typography>
										</MenuItem>
									)
								);
							})}

					{commentButtons
						.filter(r => !r.for_leader)
						.map((button, index) => {
							return (
								<MenuItem
									onClick={e => {
										handleOpenTaskCommentModal(item, button.key);
									}}
									key={index}
								>
									<ListItemIcon>{button.icon}</ListItemIcon>
									<ListItemText>{button.label}</ListItemText>
									<Typography variant='body2' className={`${classes.shortcut}`}>
										{button.shortcut}
									</Typography>
								</MenuItem>
							);
						})}

					<ListItemButton
						onClick={() => {
							setOpenPresetTasks(prev => !prev);
						}}
					>
						<ListItemIcon>
							<CircleIcon sx={{ color: 'red' }} className='mx-2' />
						</ListItemIcon>
						<ListItemText primary='Preset Tasks' />
						{openPresetTasks ? <ExpandLess /> : <ExpandMore />}
					</ListItemButton>

					<Collapse in={openPresetTasks}>
						<List component='div'>
							{presetTasks &&
								presetTasks.map(task => {
									return (
										<ListItemButton
											sx={{ pl: 2 }}
											onClick={() => {
												handleClose();
												handleUpdateTaskWithPresetTask(item, task);
												handleCloseTaskDrawer();
											}}
											key={task._id}
										>
											<ListItemIcon>
												<CircleIcon sx={{ color: 'red' }} />
											</ListItemIcon>
											<ListItemText primary={task.title} />
										</ListItemButton>
									);
								})}
						</List>
					</Collapse>

					<MenuItem
						onClick={e => {
							handleOpenTaskModal(item);
							handleCloseTaskDrawer();
						}}
					>
						<ListItemIcon>
							<RemoveRedEyeIcon fontSize='small' style={{ color: 'white' }} className='mx-2' />
						</ListItemIcon>
						<ListItemText className='mx-2'>Description</ListItemText>
					</MenuItem>

					<Divider sx={{ background: '#303030' }} variant='middle' />

					<MenuItem>
						<ListItemIcon>
							<RightArrowCircle className='mx-2' />
						</ListItemIcon>
						<ListItemText>Move</ListItemText>
						<Typography variant='body2' className={`${classes.shortcut}`}>
							⌘ M
						</Typography>
					</MenuItem>

					{user._id === activeWorksheetData.user_id && searchInput === '' && (
						<MenuItem
							onClick={e => {
								handleClose();
								handleDeleteTodo(item._id);
								handleCloseTaskDrawer();
							}}
						>
							<ListItemIcon>
								<img src={deleteIcon} alt='re open icon' className='mx-2' />
							</ListItemIcon>
							<ListItemText>Delete</ListItemText>
							<Typography variant='body2' className={`${classes.shortcut}`}>
								⌘ D
							</Typography>
						</MenuItem>
					)}

					{/* 

				{
					item.completed && (
						<NestedMenuItem
							label="Reopen"
							// parentMenuOpen={!!open}
							parentMenuOpen={true}

						>
							<MenuItem >Message 1</MenuItem>
							<MenuItem >Message 2</MenuItem>
							<MenuItem >Message 3</MenuItem>
							<MenuItem >Message 4</MenuItem>
						</NestedMenuItem>
					)
				}
				{
					(item.estimate === 0 || item.estimate === 0.00) && (
						<MenuItem>
							<ListItemIcon>
								<Looks3RoundedIcon fontSize="small" />
							</ListItemIcon>
							<ListItemText>Breakdown</ListItemText>
						</MenuItem>
					)
				}

				{/* <MenuItem onClick={e => { handleOpenTaskCommentModal(item, 'submission'); }}>
					<ListItemIcon>
						<CheckCircleIcon fontSize="small" style={{ color: 'blue' }} />
					</ListItemIcon>
					<ListItemText >Submission</ListItemText>
				</MenuItem> 

				{commentButtons.filter(r => !r.for_leader).map((button, index) => {
					return (
						<MenuItem onClick={e => { handleOpenTaskCommentModal(item, button.key); }} key={index}>
							<ListItemIcon fontSize="small">
								{button.icon}
							</ListItemIcon>
							<ListItemText >{button.label}</ListItemText>
						</MenuItem>
					)
				})}
				<Divider />
				{commentButtons.filter(r => r.for_leader).map((button, index) => {
					return (
						<MenuItem onClick={e => { handleOpenTaskCommentModal(item, button.key); }} key={index}>
							<ListItemIcon fontSize="small">
								{button.icon}
							</ListItemIcon>
							<ListItemText >{button.label}</ListItemText>
						</MenuItem>
					)
				})}
				{
					leaderMenu &&
					<MenuItem>
						<ListItemIcon>
							<CircleRoundedIcon fontSize="small" style={{ color: 'red' }} />
						</ListItemIcon>
						<ListItemText>Prioritize</ListItemText>
					</MenuItem>
				}
				{
					leaderMenu &&
					<MenuItem>
						<ListItemIcon>
							<AccountCircleRoundedIcon fontSize="small" />
						</ListItemIcon>
						<ListItemText>Client Inputs</ListItemText>
					</MenuItem>
				}
				<Divider />
				{
					leaderMenu &&
					<MenuItem>
						<ListItemIcon>
							<DeleteIcon fontSize="small" />
						</ListItemIcon>
						<ListItemText>Delete</ListItemText>
					</MenuItem>
				}

				<MenuItem onClick={e => { handleOpenTaskModal(item); }}>
					<ListItemIcon>
						<RemoveRedEyeIcon fontSize="small" />
					</ListItemIcon>
					<ListItemText >Description</ListItemText>
				</MenuItem>

				<MenuItem>
					<ListItemIcon>
						<RemoveRedEyeIcon fontSize="small" />
					</ListItemIcon>
					<ListItemText><NavLink to={`/chopping-interface/task/${item._id}`} className="d-flex align-items-center">View Details</NavLink></ListItemText>
				</MenuItem>
				{
					leaderMenu &&
					<MenuItem>
						<ListItemIcon>
							<ArrowCircleRightIcon fontSize="small" />
						</ListItemIcon>
						<ListItemText>Move</ListItemText>
					</MenuItem>
				}
				{
					leaderMenu &&
					<MenuItem>
						<ListItemIcon>
							<AddCircleOutlineIcon fontSize="small" />
						</ListItemIcon>
						<ListItemText style={{}}>Save as Recipe</ListItemText>
					</MenuItem>
				} */}
				</Menu>
			) : null}
		</>
	);
};

const useLongPress = (callback, ms, item) => {
	const [startLongPress, setStartLongPress] = useState(false);

	useEffect(() => {
		let timerId;
		if (startLongPress) {
			timerId = setTimeout(function () {
				return callback(item);
			}, ms);
		} else {
			clearTimeout(timerId);
		}

		return () => {
			clearTimeout(timerId);
		};
	}, [callback, ms, startLongPress]);

	const start = useCallback(() => {
		setStartLongPress(true);
	}, []);
	const stop = useCallback(() => {
		setStartLongPress(false);
	}, []);

	return window.innerWidth <= 575
		? {
				onMouseDown: start,
				onMouseUp: stop,
				onMouseLeave: stop,
				onTouchStart: start,
				onTouchEnd: stop
		  }
		: null;
};

export default ChoppingInterface;

