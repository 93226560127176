import * as React from "react";

const useAuth = () => {
    const token = localStorage.getItem('access_token');
    const status = token && token !== 'undefined' ? true : false;
    const [isUserLoggedIn, setIsUserLoggedIn] = React.useState(status);
    const [accessToken, setAccessToken] = React.useState(token);

    return {
        isUserLoggedIn,
        accessToken
    }

}

export default useAuth;