import React from 'react';
import { BlueTick, DownArrowOrange, UpArrowOrange, UserIcon, GreenCheck } from "assets/icons";
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';
import { AiFillWechat, AiOutlineWarning } from "react-icons/ai";
import { FaHandshake } from 'react-icons/fa';

const CommentIcon = ({ type, ...otherProps  }) => {
  switch (type) {
    case 'escalation':
        return <UpArrowOrange {...otherProps }/>;
    case 'de-escalation':
        return <DownArrowOrange {...otherProps }/>;
    case 'followup':
        return <DownArrowOrange {...otherProps }/>;
    case 'approve':
        return <GreenCheck {...otherProps }/>;
    case 'client_conversation':
        return <UserIcon {...otherProps }/>;
    case 'submission':
        return <BlueTick {...otherProps }/>;
    case 'comment':
        return  <ChatBubbleOutlineIcon {...otherProps }/>;
    case 'discussion':
        return  <AiFillWechat {...otherProps }/>;
    case 'dos_and_donts':
        return <AiOutlineWarning {...otherProps }/>;
    case 'involve':
            return <FaHandshake {...otherProps }/>;
    default:
      return (
        <div className={`fa_uncheck_icon ${otherProps?.className}`} style={otherProps?.styles} {...Object.fromEntries(
            Object.entries(otherProps).filter(([key]) => key !== 'className')
        )}>
           
        </div>
      );
  }
};

export default CommentIcon;
